import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as SharedStyle from '../../../shared-style';
import {
  BG_COLOR_1,
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  SHADE_LIGHT_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_3,
  TEXT_COLOR_NEUTRAL_1,
  BG_COLOR_0,
  STATUS_WARNING_COLOR,
  STATUS_NEGATIVE_COLOR,
  STATUS_NEGATIVE_LIGHT_COLOR,
  STATUS_WARNING_LIGHT_COLOR,
  TEXT_COLOR_NEUTRAL_2
} from '../../../constants';

export const PanelWrapper = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  margin-left: 85px;
  width: 310px;
  top: 370px;
  background-color: ${SharedStyle.TOOLBAR_COLOR.focus};
`;

export const Title = styled.span`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  padding: 15px 10px;
  user-select: none;
`;

export const Head = styled.p`
  display: inline-block;
  float: right;
  padding-right: 12px;
  font-size: 80%;
`;

export const Close = styled.a`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  /* color: this.state.closeover ? black : white; */
  background-color: ${SharedStyle.MATERIAL_COLORS['500'].red};
  cursor: pointer;
  display: inline-block;
  text-align: center;
`;

export const hintText = styled.p`
  position: fixed;
  top: 65px;
  background-color: #222222;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
  z-index: 11;
  color: white;
  font-weight: 500;
  left: calc(50% - 136px);
`;

export const Direction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  // position: fixed;
  cursor: pointer;
  // background-color: #fff;
  color: white;
  border-radius: 32px;
  margin-right: 15px;
  right: 260px;
  bottom: 25px;
  :hover {
    border-radius: 40px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.39), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  :active {
    box-shadow: none !important;
  }
`;

export const TakePictureDialog = styled(Dialog)`
  .never-show-check {
    color: white;
  }
  .never-show-check > .MuiTypography-body1 {
    font-size: 0.7rem;
  }
  .never-show-check .MuiSvgIcon-root {
    width: 0.7em;
    height: 0.7em;
  }
  .never-show-check .MuiCheckbox-colorPrimary.Mui-checked {
    color: white;
  }
  .t_modal {
    z-index: 0 !important;
  }
  .MuiDialogTitle-root {
    background-color: #41403f !important;
  }
  .MuiDialogContent-root {
    background-color: #41403f !important;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
  }
  .MuiDialog-root p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* margin-top: 0; */
    /* margin-bottom: 0.5em; */
    color: rgba(255, 255, 255, 0.85);
    /* font-weight: 500; */
  }
  .MuiButton-textPrimary {
    color: white !important;
  }
  .MuiDialog-paperWidthSm {
    min-width: 90vw !important;
    height: 95vh !important;
  }
`;

export const ToolbarWrapper = styled.div`
  background-color: white;
  display: flex;
  z-index: 1;
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
    0px 6px 10px 0px rgba(156, 154, 154, 0.39),
    0px 1px 18px 0px rgba(156, 154, 154, 0.12);
  border-radius: 10px;
  padding: 25px 10px;
  left: 15px;
  top: 90px;
  user-select: none;
  max-height: calc(100vh - 110px);
`;

export const SubToolbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  min-width: 250px;
`;

export const ToolbarButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 115px;
  height: 115px;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${BG_COLOR_HOVER};
    border-radius: 10px;
  }
`;

export const ToolbarButtonImage = styled.img`
  width: 35px;
`;

export const ToolbarButtonImageWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
`;

export const ToolbarButtonTitle = styled.span`
  color: ${SECONDARY_PURPLE_COLOR};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

export const SubCategoryTitle = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 21px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  padding: 10px 30px;
  padding-left: 0px;
`;

export const SubCategoryItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
  overflow-y: overlay;
`;

export const ContentCheckImage = styled.img`
  position: absolute;
  padding: 20px;
  width: 70px;
  color: ${SHADE_LIGHT_PURPLE_COLOR};
  border: 3px solid ${SHADE_LIGHT_PURPLE_COLOR};
`;

export const SubCategoryItem = styled.div`
  display: flex;
  min-width: 200px;
  padding: 15px;
  margin-bottom: 5px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-right: 15px;
  img#check {
    display: none;
  }
  :hover {
    background-color: ${BG_COLOR_HOVER};
    img#check {
      display: block;
    }
  }
`;

export const SubCategoryItemImage = styled.img`
  background-color: ${SECONDARY_PURPLE_COLOR};
  height: 30px;
  width: 30px;
  -webkit-mask-image: url(${props => props.maskImage + '?nocache=2025'});
  -webkit-mask-size: calc(100% - 2px) calc(100% - 2px);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 1px 1px;
`;

export const SubCategoryItemLabel = styled.span`
  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  line-height: 22px;
  text-align: center;
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${SECONDARY_PURPLE_COLOR};
`;

export const Div = styled.div`
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 200px);
  z-index: 200;
  background: #2b2b2ddf;
  align-items: space-around;
  justify-content: center;
  color: white;
  width: 350px;
  height: 140px;
`;

export const IconButton = styled.button`
  color: ${BG_COLOR_1};
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`;

export const ButtonTitle1 = styled.span`
  position: absolute;
  padding-left: 20px;
  margin-top: 3px;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonTitle2 = styled.span`
  position: absolute;
  padding-left: 20px;
  margin-top: 3px;
  font-size: 12px;
  cursor: pointer;
`;

export const RightButtonImage = styled.img`
  height: 18px;
  width: 18px;
  cursor: pointer;
  user-select: none;
`;

export const RightButton = styled.div`
  background-color: ${BG_COLOR_1};
  height: 50px;
  display: flex;
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
    0px 6px 10px 0px rgba(156, 154, 154, 0.39),
    0px 1px 18px 0px rgba(156, 154, 154, 0.12);
  border-radius: 10px;
  padding: 0px 20px;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: relative;
`;

export const NumberInputTitle = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: ${TEXT_COLOR_NEUTRAL_0};
  margin-right: auto;
  user-select: none;
  width: 180px;
`;

export const CeilingHeight = styled.input`
  padding: 15px 15px;
  padding-right: 100px;
  color: #000f33;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  width: 150px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  border: 2px solid #8791ab;
  border-radius: 5px;
  outline: 0;
  :focus {
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
  :hover {
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
`;

export const MeasurementUnitSelect = styled.div`
  cursor: pointer;
  width: 150px;
  padding: 15px 15px;
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  border-radius: 5px;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
`;

export const SelectArrow = styled.img`
  position: absolute;
  right: 15px;
  width: 15px;
  height: 9px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image: url(${props => props.maskImage + '?nocache=2025'});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`;

export const MeasurementUnitOption = styled.div`
  padding: 12px 20px;
  font-size: 13px;
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_0};
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
`;

export const ItemCustomImageWrapper = styled.div`
  display: none;
  z-index: 10;
  position: absolute;
`;

export const ItemCustomImage = styled.img`
  background-color: ${BG_COLOR_0};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 3px;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${BG_COLOR_HOVER};
    div#warning_box {
      display: block;
    }
  }
`;
export const WarningItem = styled.div`
  opacity: 0.8;
  position: absolute;
  border-radius: 6px;
  z-index: 11;
  background-color: #ff7400;
  width: max-content;
  align-items: center;
  padding: 10px;
  display: none;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.39), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  top: -50px;
  left: 5px;
  color: white;
`;

export const DoorStyleSubToolBarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  height: 100%;
`;

export const DoorstyleCategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  img#check {
    display: none;
  }
  :hover {
    background-color: ${BG_COLOR_HOVER};
    img#check {
      display: block;
    }
  }
`;

export const ReviewQuoteTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewQuoteThead = styled.div`
  background-color: ${BG_COLOR_0};
  display: flex;
  align-items: center;
`;

export const ReviewQuoteTh = styled.div`
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  line-height: 13px;
  text-align: center;
  font-weight: 600;
  margin: 5px;
  line-height: normal;
  color: ${TEXT_COLOR_NEUTRAL_3};
`;

export const ReviewQuoteItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const DistcountedPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: ${props =>
    props.colorInfo === 2
      ? BG_COLOR_1
      : props.colorInfo === 0
      ? STATUS_NEGATIVE_LIGHT_COLOR
      : STATUS_WARNING_LIGHT_COLOR};
  :hover {
    border: 2px solid
      ${props =>
        props.colorInfo === 2
          ? BG_COLOR_1
          : props.colorInfo === 0
          ? STATUS_NEGATIVE_COLOR
          : STATUS_WARNING_COLOR};
  }
`;

export const Button = styled.button`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 10px 25px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-size: 13px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 600;
  line-height: 18px;
  outline: 0;
  background-color: ${BG_COLOR_1};
  text-align: center;
  border: 1px solid ${BG_COLOR_1};
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  :hover {
    background-color: ${BG_COLOR_0};
  }
  :active {
    background-color: ${BG_COLOR_0};
  }
  // :focus{
  //   box-shadow: 0px 0px 8px 2px ${SECONDARY_PURPLE_COLOR};
  // }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const ToggleLabel = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: ${TEXT_COLOR_NEUTRAL_2};
`;

export const testJsonItemWrapper = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  color: ${SECONDARY_PURPLE_COLOR};
  :hover {
    background-color: ${BG_COLOR_HOVER};
    cursor: pointer;
  }
`;

import React from 'react';
import * as S from './styles';
import './styles.css';
import { withStyles } from '@material-ui/core/styles';
import { emailValidation } from '../../../../utils/email-validator';
import {
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  DEFAULT_FONT_FAMILY,
  TEXT_COLOR_NEUTRAL_2,
  BG_COLOR_1
} from '../../../../constants';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackBar from '../../../atoms/Snackbar/index';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
    width: 428px;
  }
`;

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const DialogContents = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;
class SubmitForQuotePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailfocusOn: false,
      phone: '',
      phonefocusOn: false,
      isCheck_other: false,
      notes: '',
      notesFocusOn: false,

      check: '',
      error: false,

      // // For Door Styles
      // selectedDoorCategory: '',
      // selectedDoorColor: 0

      //For question
      question_general: false,
      question_help_designer: false,
      question_help_contractor: false,
      question_add_to_cart: false,
      question_designer_check_cart: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      phoneCall: false,
      phoneSMS: false
    };
  }

  setEmailAndPhone() {
    const email = sessionStorage.getItem('email');
    const phone = sessionStorage.getItem('phone');
    if (email !== null && email !== 'temp_xxx@yyy.com') {
      this.setState({ email });
    }
    if (phone !== null) {
      this.setState({ phone });
    }
  }

  componentDidMount() {
    this.setEmailAndPhone();
  }

  /**
   * Get door information
   * @param {{success:boolean,data}} categoryData Category data from server
   * @param {number} doorColorId Door color id
   * @returns {[selectedDoorCategory,selectedDoorStyle,selectedDoorColor,selectedDoorColorItem]} Door tree information
   */
  getDoorInfo(categoryData, doorColorId) {
    if (categoryData.success) {
      let {
        data: { doorStyles: doorStyleProp }
      } = categoryData;

      // According to door style id
      // Find out the name of door category & door style
      let styleItem = null;
      let colorItem = null;
      let categoryItem = doorStyleProp.items.find(category => {
        let tempStyleItem = category.items.find(style => {
          let item = style.items.find(it => it.id === doorColorId);
          if (item) {
            colorItem = item;
            return true;
          } else {
            return false;
          }
        });
        if (tempStyleItem) {
          styleItem = tempStyleItem;
        }
        return !!tempStyleItem;
      });

      if (!categoryItem) {
        return null;
      }

      return [categoryItem.name, styleItem.name, doorColorId, colorItem];
    }
    return null;
  }

  componentWillReceiveProps(newProps) {
    // When visible
    if (newProps.visible && !this.props.visible) {
      this.setEmailAndPhone();
      this.setState({ error: false });
    }

    // Check for curDoorStyle change
    if (
      this.props.curDoorStyle !== newProps.curDoorStyle &&
      newProps.curDoorStyle !== null
    ) {
      if (newProps.curDoorStyle !== undefined) {
        let doorColorId =
          newProps.curDoorStyle.id !== undefined
            ? newProps.curDoorStyle.id
            : newProps.curDoorStyle.get('id');
        let { categoryData } = this.props;
        let info = this.getDoorInfo(categoryData, doorColorId);
        if (info)
          this.setState({
            selectedDoorCategory: info[0],
            selectedDoorColor: info[2]
          });
      }
    }
  }

  _submit() {
    const {
      email,
      phone,
      check,
      selectedDoorCategory,
      selectedDoorColor,
      notes,
      question_general,
      question_help_designer,
      question_help_contractor,
      question_add_to_cart,
      question_designer_check_cart,
      isCheck_other,
      phoneCall,
      phoneSMS
    } = this.state;
    let info = this.getDoorInfo(this.props.categoryData, selectedDoorColor);
    let colorItem = info[3];
    let selectedDoorStyle = info[1];

    if (check === '') {
      this.setState({
        snackBarMessage: 'Please select a preferred contact method.',
        isSnackBarOpen: true
      });
      return;
    }

    if (
      !question_general &&
      !question_help_designer &&
      !question_help_contractor &&
      !question_add_to_cart &&
      !question_designer_check_cart &&
      !isCheck_other
    ) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }

    if (
      (email === '' && check === 'email') ||
      (phone === '' && check === 'phone') ||
      (!emailValidation(email) && check === 'email')
    ) {
      let msg;
      if (email === '' && check === 'email') msg = 'Type your email address';
      else if (phone === '' && check === 'phone')
        msg = 'Type your phone number';
      else if (!emailValidation(email) && check === 'email')
        msg = 'Type your valid email address';
      this.setState({ snackBarMessage: msg, isSnackBarOpen: true });
      return;
    }

    let contact_preference = 'Email';
    if (check === 'phone') {
      if (phoneCall) {
        contact_preference = 'Call';
      } else if (phoneSMS) {
        contact_preference = 'Text/SMS';
      }
    }

    if (this)
      this.props.submitForQuote({
        email: check === 'email' ? email : '',
        phone: check === 'phone' ? phone : '',
        check,
        contact_preference,
        firstName: sessionStorage.getItem('firstName'),
        lastName: sessionStorage.getItem('lastName'),
        products: JSON.parse(sessionStorage.getItem('usedObjects')),
        selectedDoorCategory,
        selectedDoorColor: `${colorItem.name}/${colorItem.thumbnail}`,
        selectedDoorStyle,
        notes,
        question_general,
        question_help_designer,
        question_help_contractor,
        question_add_to_cart,
        question_designer_check_cart
      });
    this.closePanel();
  }
  closePanel() {
    this.props.handleSubmitForQuote(false);
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({
      phone: e.target.validity.valid ? e.target.value : this.state.phone
    });
  }

  handleCheckChange(event) {
    this.setState({ [event.target.value]: event.target.checked });
  }

  render() {
    let { visible } = this.props;
    let {
      email,
      emailfocusOn,
      phone,
      isCheck_other,
      phonefocusOn,
      notes,
      check,
      error,
      question_general,
      question_help_designer,
      question_help_contractor,
      question_add_to_cart,
      question_designer_check_cart,
      notesFocusOn,
      phoneSMS,
      phoneCall
    } = this.state;

    const updatePhoneSectionOnFocusHandler = () => {
      this.setState(prevState => {
        let newPhoneCall = prevState.phoneCall;
        let newPhoneSMS = prevState.phoneSMS;
        if (!prevState.phoneCall && !prevState.phoneSMS) {
          newPhoneCall = true;
          newPhoneSMS = false;
        }

        return {
          phonefocusOn: true,
          check: 'phone',
          phoneCall: newPhoneCall,
          phoneSMS: newPhoneSMS
        };
      });
    };

    const updatePhoneReceiveMethodHandler = (callClicked = true) => {
      this.setState(prevState => {
        let newPhoneCall = prevState.phoneCall;
        let newPhoneSMS = prevState.phoneSMS;

        if (callClicked) {
          newPhoneCall = !newPhoneCall;
          newPhoneSMS = !newPhoneCall;
        } else {
          newPhoneSMS = !newPhoneSMS;
          newPhoneCall = !newPhoneSMS;
        }

        return {
          phoneCall: newPhoneCall,
          phoneSMS: newPhoneSMS,
          check: 'phone'
        };
      });
    };

    // Get door concerned data

    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <SnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() =>
            this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
          }
        />
        <S.DialogTitle>Designer Review</S.DialogTitle>
        <S.DialogContent
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: 14,
            lineHeight: '21px'
          }}
        >
          Your project will be sent to the Design Department for review.{' '}
          <span style={{ fontWeight: error && 600 }}>
            What is the best way to reach you for the next steps?
          </span>
        </S.DialogContent>
        <S.DialogContentSelection>
          <S.StyledInputWrapper>
            <S.StyledCheck
              style={{
                color: check === 'email' && SECONDARY_PURPLE_COLOR,
                backgroundColor:
                  check === 'email' ? SECONDARY_PURPLE_COLOR : BG_COLOR_1
              }}
              onClick={() =>
                this.setState({
                  check: 'email',
                  phoneCall: false,
                  phoneSMS: false
                })
              }
            />
            <S.StyledInput
              style={{ cursor: check !== 'email' && 'pointer' }}
              value={email}
              // onClick={() => this.setState({emailCheck: true})}
              onChange={e => this.setState({ email: e.target.value })}
              onFocus={e =>
                this.setState({
                  emailfocusOn: true,
                  check: 'email',
                  phoneCall: false,
                  phoneSMS: false
                })
              }
              onBlur={e => this.setState({ emailfocusOn: false })}
              required
              // disabled={!emailCheck}
            />
            {!emailfocusOn && !!!email.length && (
              <S.StyledInputLabel
                style={{
                  cursor: check !== 'email' && 'no-drop',
                  pointerEvents: 'none'
                }}
              >
                Email Address
              </S.StyledInputLabel>
            )}
            {(emailfocusOn || !!email.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                Email Address
              </S.StyledInputHeadLabel>
            )}
            {emailfocusOn && !!!email.length && (
              <S.StyledInputLabel>Enter email address</S.StyledInputLabel>
            )}
            {!!email.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ email: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledCheck
              style={{
                color: check === 'phone' && SECONDARY_PURPLE_COLOR,
                backgroundColor:
                  check === 'phone' ? SECONDARY_PURPLE_COLOR : BG_COLOR_1
              }}
              onClick={updatePhoneSectionOnFocusHandler}
            />
            <S.StyledInput
              style={{ cursor: check !== 'phone' && 'pointer' }}
              value={phone}
              onChange={e =>
                this.setState({
                  phone: e.target.validity.valid ? e.target.value : phone
                })
              }
              onFocus={updatePhoneSectionOnFocusHandler}
              onBlur={e => this.setState({ phonefocusOn: false })}
              pattern={'[0-9]*'}
              maxLength={50}
              required
              // disabled={!phoneCheck}
            />
            {!phonefocusOn && !!!phone.length && (
              <S.StyledInputLabel
                style={{
                  cursor: check !== 'phone' && 'no-drop',
                  pointerEvents: 'none'
                }}
              >
                Phone
              </S.StyledInputLabel>
            )}
            {(phonefocusOn || !!phone.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !phonefocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                Phone
              </S.StyledInputHeadLabel>
            )}
            {phonefocusOn && !!!phone.length && (
              <S.StyledInputLabel>Enter phone number</S.StyledInputLabel>
            )}
            {!!phone.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ phone: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.StyledPhoneMethod style={{ paddingLeft: '30px' }}>
            <span style={{ paddingRight: '10px' }}>Method: </span>
            <FormControlLabel
              style={{ alignItems: 'flex-start' }}
              margin-left="10px"
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset', margin: '0 5px' }}
                  checked={phoneCall}
                  onChange={() => updatePhoneReceiveMethodHandler(true)}
                  value={phoneCall}
                />
              }
              label={<S.DialogContents> Call </S.DialogContents>}
            />
            <span style={{ paddingRight: '18px' }}> or </span>
            <FormControlLabel
              style={{ alignItems: 'flex-start' }}
              margin-left="10px"
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset', margin: '0 5px' }}
                  checked={phoneSMS}
                  onChange={() => updatePhoneReceiveMethodHandler(false)}
                  value={phoneSMS}
                />
              }
              label={<S.DialogContents> Text/SMS </S.DialogContents>}
            />
          </S.StyledPhoneMethod>

          <S.DialogContents style={{ color: error && 'red' }}>
            * Please select from the below options (at least one box must be
            selected)
          </S.DialogContents>
          <FormControlLabel
            style={{ alignItems: 'flex-start', padding: '4px 0 4px 0' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question_general}
                onChange={() =>
                  this.setState({
                    question_general: !question_general,
                    error: false
                  })
                }
                value={question_general}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I have general questions about my design or quote
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start', padding: '4px 0 4px 0' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question_help_designer}
                onChange={() =>
                  this.setState({
                    question_help_designer: !question_help_designer,
                    error: false
                  })
                }
                value={question_help_designer}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I would like to work with a designer to help with building my
                layout or quote
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start', padding: '4px 0 4px 0' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question_help_contractor}
                onChange={() =>
                  this.setState({
                    question_help_contractor: !question_help_contractor,
                    error: false
                  })
                }
                value={question_help_contractor}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I'm a contractor and need help ordering for my customer
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start', padding: '4px 0 4px 0' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question_add_to_cart}
                onChange={() =>
                  this.setState({
                    question_add_to_cart: !question_add_to_cart,
                    error: false
                  })
                }
                value={question_add_to_cart}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I want to add my design to the shopping cart.
              </S.DialogContents>
            }
          />
          {question_add_to_cart && (
            <FormControlLabel
              style={{ alignItems: 'flex-start', padding: '4px 0 4px 32px' }}
              control={
                <StyledMuiCheckbox
                  style={{
                    padding: 'unset',
                    margin: '0 10px',
                    color: error && 'red'
                  }}
                  checked={question_designer_check_cart}
                  onChange={() =>
                    this.setState({
                      question_designer_check_cart:
                        !question_designer_check_cart,
                      error: false
                    })
                  }
                  value={question_designer_check_cart}
                />
              }
              label={
                <S.DialogContents style={{ color: error && 'red' }}>
                  I would like a designer to check my cart for accuracy before
                  placing my order.
                  <span style={{ fontWeight: 600 }}>
                    (Strongly Recommended!)
                  </span>
                </S.DialogContents>
              }
            />
          )}
          <FormControlLabel
            style={{ alignItems: 'flex-start', padding: '4px 0 4px 0' }}
            margin-left="10px"
            margin-top="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={isCheck_other}
                onChange={() =>
                  this.setState({ isCheck_other: !isCheck_other, error: false })
                }
                value={isCheck_other}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                Other
              </S.DialogContents>
            }
          />
          <div
            style={{
              width: '100%',
              position: 'relative',
              marginTop: 15,
              display:
                !question_general &&
                !question_help_designer &&
                !question_help_contractor &&
                !question_add_to_cart &&
                !isCheck_other &&
                'none'
            }}
          >
            <S.DialogTextArea
              value={notes}
              maxLength={200}
              onFocus={e => this.setState({ notesFocusOn: true })}
              onBlur={e => this.setState({ notesFocusOn: false })}
              placeholder={
                !notesFocusOn
                  ? 'Please include any notes you would like us to know when receiving your request'
                  : 'Input'
              }
              onChange={e => this.setState({ notes: e.target.value })}
            />
            {(notesFocusOn || !!notes.length) && (
              <S.StyledInputHeadLabel
                style={{
                  color: !notesFocusOn && TEXT_COLOR_NEUTRAL_2,
                  left: 7
                }}
              >
                Notes (optional)
              </S.StyledInputHeadLabel>
            )}
            <span
              style={{
                fontFamily: DEFAULT_FONT_FAMILY,
                fontSize: 14,
                color: TEXT_COLOR_NEUTRAL_1,
                float: 'right'
              }}
            >
              {notes.length}/200 characters
            </span>
          </div>
        </S.DialogContentSelection>
        <S.DialogAction>
          <S.Button onClick={() => this.closePanel()}>Cancel</S.Button>
          <S.Button
            style={{ marginRight: 0 }}
            onClick={this._submit.bind(this)}
          >
            Submit
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
    );
  }
}

export default SubmitForQuotePopup;

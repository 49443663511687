'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as Three from 'three';
import {
  parseData,
  updateScene,
  visibleTransformBox,
  getDistances,
  fVLine,
  deleteSpecifiedMeshObjects,
  createBacksplash,
  checkCabinetOverlap,
  showItemButtons
} from './scene-creator';
import { disposeScene } from './three-memory-cleaner';
import OrbitControls from './libs/orbit-controls';
import { disposeObject } from './three-memory-cleaner';
import diff from 'immutablediff';
import * as SharedStyle from '../../shared-style';
import {
  MODE_DRAWING_ITEM_3D,
  MODE_IDLE_3D,
  OBJTYPE_MESH,
  UNIT_CENTIMETER,
  MODE_DRAWING_HOLE_3D,
  MODE_DRAGGING_HOLE_3D,
  SECONDARY_PURPLE_COLOR,
  MODE_DRAGGING_ITEM_3D,
  MODE_ROTATING_ITEM_3D
} from '../../constants';
import { isUndefined } from 'util';
import {
  verticesDistance,
  distancePointFromLineSegment
} from '../../utils/geometry';
import convert from 'convert-units';
import { GeometryUtils } from '../../utils/export';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import CameraControls from 'camera-controls';
CameraControls.install({ THREE: Three });
import { returnReplaceableDeepSearchType } from '../viewer2d/utils';
import {
  getAllMeshes,
  vectorIntersectWithMesh
} from '../../utils/objects-utils';

export default class Scene3DViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showflag: true,
      isLoadingCabinet: props.state.scene.isLoadingCabinet
    };
    this.lastMousePosition = {};
    this.width = props.width;
    this.height = props.height;
    this.renderingID = 0;

    this.renderer =
      window.__threeRenderer ||
      new Three.WebGLRenderer({
        preserveDrawingBuffer: true,
        alpha: true,
        antialias: true
      });

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMapSoft = true;
    window.__threeRenderer = this.renderer;
    this.renderer.domElement.style.display = 'none';
  }

  componentDidMount(nextProps) {
    let scene3D, camera, orbitController, pivot, cameraControls, clock;
    let spotLight1, spotLightTarget;

    const actions = {
      areaActions: this.context.areaActions,
      holesActions: this.context.holesActions,
      itemsActions: this.context.itemsActions,
      sceneActions: this.context.sceneActions,
      linesActions: this.context.linesActions,
      projectActions: this.context.projectActions,
      catalog: this.context.catalog
    };

    const self = this;
    const { state } = this.props;
    const { mode, scene } = state;
    function setupLight(scene, inbBox) {
      const shadowMapSize = 2048;
      const shadowCameraSize =
        Math.max(
          Math.abs(inbBox.min.x - inbBox.max.x),
          Math.abs(inbBox.min.y - inbBox.max.y),
          Math.abs(inbBox.min.z - inbBox.max.z)
        ) / 2;
      const shadowCameraFar = shadowCameraSize * 10;
      const bboxCenter = new Three.Vector3(
        (inbBox.min.x + inbBox.max.x) / 2,
        inbBox.min.y,
        (inbBox.min.z + inbBox.max.z) / 2
      );
      function addDirLight(inColor, inIntensity, inPosition) {
        const dirLight = new Three.DirectionalLight(inColor, inIntensity);
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.x = shadowMapSize;
        dirLight.shadow.mapSize.y = shadowMapSize;
        dirLight.shadow.camera.near = 0;
        dirLight.shadow.camera.far = shadowCameraFar;

        dirLight.shadow.camera.top = shadowCameraSize * 1.5;
        dirLight.shadow.camera.bottom = -shadowCameraSize * 1.5;
        dirLight.shadow.camera.left = -shadowCameraSize * 1.5;
        dirLight.shadow.camera.right = shadowCameraSize * 1.5;
        dirLight.position.copy(inPosition);

        const targetObject = new Three.Object3D();
        targetObject.position.copy(bboxCenter);
        scene.add(targetObject);

        dirLight.target = targetObject;
        dirLight.target.updateMatrixWorld();

        scene.add(dirLight);
      }
      function addSpotLight(
        inColor,
        inIntensity,
        inPosition,
        inTarget,
        inDistance
      ) {
        const spotLight = new Three.SpotLight();
        spotLight.intensity = inIntensity;
        spotLight.color.setHex(inColor);
        spotLight.position.copy(inPosition);
        spotLight.angle = 1.3;
        spotLight.distance = inDistance;
        spotLight.penumbra = 1.8;
        spotLight.decay = 0.01;

        spotLight.castShadow = true;
        spotLight.shadow.intensity = 2;
        spotLight.shadow.mapSize.width = 4096;
        spotLight.shadow.mapSize.height = 4096;

        const targetObject = new Three.Object3D();
        targetObject.position.copy(
          new Three.Vector3(inTarget.x, 0, inTarget.z)
        );
        scene.add(targetObject);

        spotLight.target = targetObject;
        spotLight.target.updateMatrixWorld();
        scene.add(spotLight);
      }

      const dirLightPos = new Three.Vector3(
        inbBox.max.x,
        inbBox.max.y + 1.8 * Math.abs(inbBox.max.y - inbBox.min.y),
        inbBox.min.z - 0.5 * Math.abs(inbBox.max.z - inbBox.min.z)
      );

      addDirLight('white', 1.5, dirLightPos);

      const ceiling = scene3D.getObjectByName('ceil');
      const ceilBBox = new Three.Box3().setFromObject(ceiling);

      const spot1 = new Three.Vector3(
        ceilBBox.min.x + Math.abs(ceilBBox.min.x - ceilBBox.max.x) / 4,
        ceilBBox.max.y - 0.5,
        ceilBBox.min.z + Math.abs(ceilBBox.min.z - inbBox.max.z) / 4
      );
      const spot2 = new Three.Vector3(
        ceilBBox.min.x + Math.abs(ceilBBox.min.x - ceilBBox.max.x) / 4,
        ceilBBox.max.y - 0.5,
        ceilBBox.max.z - Math.abs(ceilBBox.min.z - ceilBBox.max.z) / 4
      );

      const spot3 = new Three.Vector3(
        ceilBBox.max.x - Math.abs(ceilBBox.min.x - ceilBBox.max.x) / 4,
        ceilBBox.max.y - 0.5,
        ceilBBox.min.z + Math.abs(ceilBBox.min.z - ceilBBox.max.z) / 4
      );
      const spot4 = new Three.Vector3(
        ceilBBox.max.x - Math.abs(ceilBBox.min.x - ceilBBox.max.x) / 4,
        ceilBBox.max.y - 0.5,
        ceilBBox.max.z - Math.abs(ceilBBox.min.z - ceilBBox.max.z) / 4
      );

      const spotlightDis = 1.5 * Math.abs(inbBox.min.y - inbBox.max.y);

      // check if spotlight is inside the room
      vectorIntersectWithMesh(spot1, scene3D.getObjectByName('floor')) &&
        addSpotLight('0xffffff', 0.8, spot1, spot1, spotlightDis);
      vectorIntersectWithMesh(spot2, scene3D.getObjectByName('floor')) &&
        addSpotLight('0xffffff', 0.8, spot2, spot2, spotlightDis);
      vectorIntersectWithMesh(spot3, scene3D.getObjectByName('floor')) &&
        addSpotLight('0xffffff', 0.8, spot3, spot3, spotlightDis);
      vectorIntersectWithMesh(spot4, scene3D.getObjectByName('floor')) &&
        addSpotLight('0xffffff', 0.8, spot4, spot4, spotlightDis);
    }

    // Load data
    this.setState({ isLoading: true });
    const { promise, planData } = parseData(
      scene,
      actions,
      this.context.catalog,
      camera,
      this.renderer,
      state.mode
    );
    promise.then(() => {
      setTimeout(() => {
        const bbox = new Three.Box3().setFromObject(planData.plan);
        cameraControls.fitToBox(bbox);
        self.setState({ isLoading: false });
        self.renderer.domElement.style.display = 'block';
        setupLight(scene3D, planData.boundingBox);
      }, 500);
    });

    const area = scene.getIn(['layers', scene.selectedLayer, 'areas']);
    const layer = scene.getIn(['layers', scene.selectedLayer]);
    const areas = [],
      lights = [];

    let snapBox = null;

    // Check for data
    let msg = '';
    scene.layers.forEach(layer => {
      if (layer.id === scene.selectedLayer || layer.visible) {
        layer.items.forEach(item => {
          if (item.doorStyle === null) {
            if (!msg.includes(item.name + "'s doorStyle is null."))
              msg += item.name + "'s doorStyle is null.\n";
          }
        });
      }
    });
    if (msg !== '') {
      confirm(msg);
    }

    init();
    render();

    // area lightning(wall lightning)/////

    area.forEach(data => {
      areas.push(data);
    });
    for (let i = 0; i < areas.length; i++) {
      let aVertices = [];
      let lines = [];
      let height = 100;
      areas[i].vertices.forEach(data => {
        aVertices.push(data);
      });
      layer.lines.forEach(data => {
        lines.push(data);
      });
      for (let i = 0; i < lines.length; i++) {
        let data = lines[i];
        let realVec = [];
        data.vertices.forEach(vec => {
          realVec.push(vec);
        });
        if (aVertices.includes(realVec[0]) && aVertices.includes(realVec[1])) {
          height = convert(layer.ceilHeight)
            .from(layer.unit)
            .to(UNIT_CENTIMETER);
          // height = data.properties.getIn(['height', 'length']);
          break;
        }
      }
      let vertices = [];
      areas[i].vertices.forEach(datas => {
        let vertex = scene.getIn([
          'layers',
          scene.selectedLayer,
          'vertices',
          datas
        ]);
        vertices.push(vertex);
      });
      vertices.push(vertices[0]);
      let fLen = vertices.length - 1;
      for (let i = 0; i < fLen; i++) {
        let sX = vertices[i].x;
        let sY = vertices[i].y;
        let eX = vertices[i + 1].x;
        let eY = vertices[i + 1].y;
        let len = Math.sqrt((eX - sX) * (eX - sX) + (eY - sY) * (eY - sY));
        for (let cLen = 200; cLen < len; cLen += 200) {
          let cX = sX + ((eX - sX) * cLen) / len;
          let cY = sY + ((eY - sY) * cLen) / len;
          let endLen = Math.sqrt((eX - cX) * (eX - cX) + (eY - cY) * (eY - cY));
          if (endLen <= 100) continue;
          let vec2 = new Three.Vector2(cX - sX, cY - sY);
          let angle = vec2.angle() + Math.PI / 2;
          cX = Math.cos(angle) * 30 + cX;
          cY = Math.sin(angle) * 30 + cY;
          let spotLight = new Three.SpotLight(0xeeeeee, 1.7);
          spotLight.angle = 0.76;
          spotLight.castShadow = true;
          spotLight.penumbra = 1;
          spotLight.decay = 1.7;
          spotLight.distance = height - 20;
          let target = new Three.Object3D();
          spotLight.target = target;
          lights.push({
            light: spotLight,
            target: target,
            x: cX,
            y: cY,
            height: height
          });
        }
      }
    }
    // //////////////////////////

    // OBJECT PICKING
    let toIntersect = [planData.plan];
    let mouse = new Three.Vector2();
    let gridPlane = planData.grid;
    let raycaster = new Three.Raycaster();
    let selectedObject = {};
    let currentObject = null;
    let isSelected = false;
    let bRotate = false;
    let bMove = false;
    let bMoveUP = false;
    /** Transformation matrix of grid */
    const gridMatrix = new Three.Matrix4();
    let rayDirection = new Three.Vector3();
    /** World position of grid plane */
    let gridPlanOrigin = new Three.Vector3();
    let Point = new Three.Vector2();
    let sPoint = new Three.Vector2();

    // SNAP FUNCTION VARIABLE
    let snapFlag = false;
    let snapAnimI = 0;
    let snapDelta = 6;
    let t_i = 0;
    let targetPoint = new Three.Vector3();
    let targetRot = 0;
    let targetUVec = new Three.Vector3();
    let targetCRotation = 0;
    let targetObj = null;
    let targetNumber = 0;
    let wallSlide = false;

    let pinFlag = false;
    let sFlag = false; //for all object move
    let endPoint = {};
    let allItemRect;
    let allItemSnap;
    let allLines;
    let allLineRects;
    let allLineSnap;
    let allRect;
    let allArea;

    // end snap function variable///////////////////////
    let backsplashVisible = false;
    let holeItems = GeometryUtils.getHoleItems(layer);

    let removeSnapBox = () => {
      if (snapBox != null) {
        planData.plan.remove(snapBox);
        disposeObject(snapBox);
        snapBox = null;
        targetObj = null;
        snapFlag = false;
      }
    };

    const camToGrid = new Three.Vector3();
    let camPos = camera.position;

    const mapCursorPosition = (e, altitude) => {
      camToGrid.subVectors(gridPlanOrigin, camPos);

      let camD = camToGrid.y + (altitude ? altitude : 0);
      let rayD = rayDirection.y;

      const intersectPt = rayDirection.multiplyScalar(camD / rayD).add(camPos);
      intersectPt.y = gridPlanOrigin.y;
      intersectPt.applyMatrix4(gridMatrix);

      return { x: intersectPt.x, y: -intersectPt.z };
    };

    /* for Snap Functions*/

    let pointLineDistance = function (point, line) {
      // return distance from point to line and directional point of line
      let pX = point.x;
      let pY = point.y;
      let l1x = line[0].x;
      let l1y = line[0].y;
      let l2x = line[1].x;
      let l2y = line[1].y;
      let pLine = new Three.Vector2(l1x - pX, l1y - pY);
      let Line = new Three.Vector2(l1x - l2x, l1y - l2y);
      let pAngle = pLine.angle();
      let lAngle = Line.angle();
      let pDistance = pLine.length();
      let oDistance = Line.length();
      let directDistance = Math.sin(pAngle - lAngle) * pDistance;
      let lineDistance = Math.cos(pAngle - lAngle) * pDistance;
      let dX = l1x + ((l2x - l1x) * lineDistance) / oDistance;
      let dY = l1y + ((l2y - l1y) * lineDistance) / oDistance;
      let dPoint = { x: dX, y: dY };
      return { distance: directDistance, point: dPoint };
    };
    let getInterSect = function (shape1, shape2) {
      // return result of intersect of two shape
      let count = 0;
      for (let i = 0; i < shape1.length - 1; i++) {
        let sl1 = { x: shape1[i].x, y: shape1[i].y };
        let sl2 = { x: shape1[i + 1].x, y: shape1[i + 1].y };
        for (let j = 0; j < shape2.length - 1; j++) {
          let el1 = { x: shape2[j].x, y: shape2[j].y };
          let el2 = { x: shape2[j + 1].x, y: shape2[j + 1].y };
          let flag = GeometryUtils.getLineInterSect(
            sl1.x,
            sl1.y,
            sl2.x,
            sl2.y,
            el1.x,
            el1.y,
            el2.x,
            el2.y
          );
          if (flag) {
            count++;
            if (count > 1) return true;
          }
        }
      }
      return false;
    };

    this.getRectPoints = function (width, height, pos, rot) {
      // return 4 points from it's position, width, height, and rotation info
      let rX = width / 2;
      let rY = height / 2;
      let vertices = [];
      let cRot = rot;
      let pX = pos.x + Math.cos(cRot) * rX + Math.cos(cRot + Math.PI / 2) * rY;
      let pY = pos.y + Math.sin(cRot) * rX + Math.sin(cRot + Math.PI / 2) * rY;
      vertices.push({ x: Math.floor(pX + 0.5), y: Math.floor(pY + 0.5) });
      rX = -rX;
      pX = pos.x + Math.cos(cRot) * rX + Math.cos(cRot + Math.PI / 2) * rY;
      pY = pos.y + Math.sin(cRot) * rX + Math.sin(cRot + Math.PI / 2) * rY;
      vertices.push({ x: Math.floor(pX + 0.5), y: Math.floor(pY + 0.5) });
      rY = -rY;
      pX = pos.x + Math.cos(cRot) * rX + Math.cos(cRot + Math.PI / 2) * rY;
      pY = pos.y + Math.sin(cRot) * rX + Math.sin(cRot + Math.PI / 2) * rY;
      vertices.push({ x: Math.floor(pX + 0.5), y: Math.floor(pY + 0.5) });
      rX = -rX;
      pX = pos.x + Math.cos(cRot) * rX + Math.cos(cRot + Math.PI / 2) * rY;
      pY = pos.y + Math.sin(cRot) * rX + Math.sin(cRot + Math.PI / 2) * rY;
      vertices.push({ x: Math.floor(pX + 0.5), y: Math.floor(pY + 0.5) });
      vertices.push(vertices[0]);
      vertices.push(vertices[2]);
      return vertices;
    };

    const prepareSnap = layer => {
      allLines = GeometryUtils.getAllLines(layer);
      allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
      allItemRect = GeometryUtils.getAllItems(
        this.props.state.scene,
        actions.catalog,
        allLineRects
      );
      allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
      allLineSnap = GeometryUtils.getAllLineSnap(allLineRects, allItemRect.cur);
      allRect = allItemRect.others.concat(allLineRects);
      allItemSnap = GeometryUtils.validateSnaps(allItemSnap, allRect);
      allLineSnap = GeometryUtils.validateSnaps(allLineSnap, allRect);
      allArea = GeometryUtils.getAllArea(layer);
    };

    let prepareSnapSpec = layer => {
      allLines = GeometryUtils.getAllLines(layer);
      allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
      allItemRect = GeometryUtils.getAllItemSpecified(
        this.props.state.scene,
        actions.catalog,
        'Wall'
      );
      // allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
    };

    // prepareSnapSpec(layer);
    let lineRect = layer => {
      let areainfo = [];
      layer.areas.forEach(area => {
        let sz = area.vertices.size;
        for (var i = 0; i < sz; i++) {
          areainfo.push(area.vertices.get(i));
        }
      });
      let rect = [];
      areainfo.forEach(area => {
        let vert = layer.vertices.get(area);
        rect.push(vert.x, vert.y);
      });
      return rect;
    };

    this.collisionCheck = function (obj, pos, rot, tObj, item = null, catalog) {
      //collision check from one object to every other object excpet target object
      let layer = this.props.state.scene.getIn([
        'layers',
        obj.userData.layerId
      ]);
      let layoutpos = 'utype';
      if (item !== null) {
        let catid = item.type;
        let cat = catalog.elements[catid];
        layoutpos = cat.info.layoutpos;
      }
      let oPos = new Three.Vector2(pos.clone().x, pos.clone().y);
      let sBounding = obj.children[0].userData;
      let width = sBounding.max.x - sBounding.min.x;
      let depth = sBounding.max.z - sBounding.min.z;
      let oVertices = this.getRectPoints(
        width,
        depth,
        oPos.clone(),
        ((rot % 360) / 180) * Math.PI
      );
      let datas = [];
      layer.items.forEach(data => {
        datas.push(data);
      });
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        if (data.id == obj.userData.itemId || data.id == tObj.userData.itemId)
          continue;
        let target =
          planData.sceneGraph.layers[obj.userData.layerId].items[data.id];
        if (target === undefined) {
          console.log(
            data.id +
              ' does not exist in viewer3d/viewer3d.js collisionCheck function'
          );
          return false;
        }
        let item = layer.items.getIn([data.id]);
        let ocatid = item.type;
        let ocat = catalog.elements[ocatid];
        let olayoutpos = ocat.info.layoutpos;
        if (
          !(
            (layoutpos === 'Base' && olayoutpos === 'Wall') ||
            (layoutpos === 'Wall' && olayoutpos === 'Base')
          )
        ) {
          let tRot = item.rotation;
          let tPos = new Three.Vector2(item.x, item.y);
          let tBounding = target.children[0].userData;
          let twidth = tBounding.max.x - tBounding.min.x;
          let tdepth = tBounding.max.z - tBounding.min.z;
          let tVertices = this.getRectPoints(
            twidth,
            tdepth,
            tPos.clone(),
            ((tRot % 360) / 180) * Math.PI
          );
          if (getInterSect(oVertices, tVertices)) {
            return false;
          }
        }
      }
      datas = [];
      layer.lines.forEach(data => {
        datas.push(data);
      });
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        if (data.id == obj.userData.itemId || data.id == tObj.userData.itemId)
          continue;
        let item = layer.lines.getIn([data.id]);
        // let llayoutpos = catalog.elements[item.type].info.layoutpos;
        let vertices = [];
        item.vertices.forEach(data => {
          let vertex = layer.vertices.get(data);
          vertices.push({ x: vertex.x, y: vertex.y });
        });
        let vec = new Three.Vector2(
          vertices[1].x - vertices[0].x,
          vertices[1].y - vertices[0].y
        );
        let tRot = vec.angle();
        let tPos = new Three.Vector2(
          (vertices[0].x + vertices[1].x) / 2,
          (vertices[0].y + vertices[1].y) / 2
        );
        let tdepth = item.properties.getIn(['thickness', 'length']);
        let twidth = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
        let tVertices = this.getRectPoints(twidth, tdepth, tPos.clone(), tRot);
        if (getInterSect(oVertices, tVertices)) {
          return false;
        }
      }
      return true;
    };

    this.collisionHoleCheck = function (
      obj,
      pos,
      rot,
      tObj,
      item = null,
      catalog
    ) {
      let layer = this.props.state.scene.getIn([
        'layers',
        obj.userData.layerId
      ]);
      let currentItem;
      if (item !== null) {
        let catid = item.type;
        let cat = catalog.elements[catid];
        currentItem = {
          selectedItem: item,
          cat
        };
      }
      let oPos = new Three.Vector2(pos.clone().x, pos.clone().y);
      let sBounding = obj.children[0].userData;
      let width = sBounding.max.x - sBounding.min.x;
      let depth = sBounding.max.z - sBounding.min.z;
      let oVertices = this.getRectPoints(
        width,
        depth,
        oPos.clone(),
        ((rot % 360) / 180) * Math.PI
      );

      let datas = [];
      layer.items.forEach(data => {
        datas.push(data);
      });
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        if (data.id == obj.userData.itemId || data.id == tObj.userData.itemId)
          continue;
        let target =
          planData.sceneGraph.layers[obj.userData.layerId].items[data.id];
        if (target === undefined) {
          console.log(
            data.id +
              ' does not exist in viewer3d/viewer3d.js collisionCheck function'
          );
          return false;
        }
        let item = layer.items.getIn([data.id]);
        let ocatid = item.type;
        let ocat = catalog.elements[ocatid];
        if (!ocat)
          ocat = catalog.elements[returnReplaceableDeepSearchType(ocatid)];
        let otherItem = {
          item,
          cat: ocat
        };

        if (
          GeometryUtils.needSnap(currentItem, otherItem) &&
          otherItem.cat.type != 'cabinet'
        ) {
          let tRot = item.rotation;
          let tPos = new Three.Vector2(item.x, item.y);
          let tBounding = target.children[0].userData;
          let twidth = tBounding.max.x - tBounding.min.x;
          let tdepth = tBounding.max.z - tBounding.min.z;
          let tVertices = this.getRectPoints(
            twidth,
            tdepth,
            tPos.clone(),
            ((tRot % 360) / 180) * Math.PI
          );
          if (getInterSect(oVertices, tVertices)) {
            return false;
          }
        }
      }

      let holes = [];
      layer.lines.forEach(line => {
        line.holes.forEach(holeID => {
          let hole = layer.holes.get(holeID);
          holes.push(hole);
        });
      });
      let i = 0;
      for (; i < holes.length; i++) {
        let tPos = new Three.Vector2(holes[i].x, holes[i].y);
        let twidth = holes[i].properties.getIn(['width', 'length']);
        let theight = holes[i].properties.getIn(['thickness', 'length']);
        let trot = holes[i].rotation;
        let tVertices = this.getRectPoints(twidth, theight, tPos.clone(), trot);
        if (getInterSect(oVertices, tVertices)) {
          return false;
        }
      }
      return true;
    };

    this.collisionSlide = function (
      item3D,
      originPos,
      layer,
      sVertices,
      tPos,
      item
    ) {
      let items = [];

      let cur_category = '';
      let catalog = actions.catalog;
      if (layer.selected.items.size > 0) {
        let selectedItem = layer.getIn(['items', layer.selected.items.get(0)]);
        let catid = selectedItem.type;
        let cat = catalog.elements[catid];
        if (cat === undefined || cat === null)
          cat = catalog.getIn(['elements', catid]);
        cur_category = cat.obj.category;
      }

      layer.items.forEach(data => {
        if (data.id == selectedObject.itemID) {
          return;
        }

        items.push(data.toJS());
      });
      let oPos = new Three.Vector2(originPos.x, -originPos.z);

      // sort from distance
      for (let i = 0; i < items.length - 1; i++) {
        for (let j = i + 1; j < items.length; j++) {
          if (
            verticesDistance(oPos, new Three.Vector2(items[i].x, items[i].y)) >
            verticesDistance(oPos, new Three.Vector2(items[j].x, items[j].y))
          ) {
            let exchange = items[j];
            items[j] = items[i];
            items[i] = exchange;
          }
        }
      }
      let i = 0;

      for (; i < items.length; i++) {
        if (!items[i]) return;
        let target =
          planData.sceneGraph.layers[selectedObject.layerID].items[items[i].id];
        if (target === undefined) {
          return false;
        }
        let targetData = layer.items.getIn([items[i].id]);
        let tRot = targetData.rotation;
        let tPos = new Three.Vector2(targetData.x, targetData.y);
        let tBounding = target.children[0].userData;
        let twidth = tBounding.max.x - tBounding.min.x;
        let tdepth = tBounding.max.z - tBounding.min.z;
        let tVertices = this.getRectPoints(
          twidth,
          tdepth,
          tPos.clone(),
          ((tRot % 360) / 180) * Math.PI
        );
        if (getInterSect(sVertices, tVertices)) {
          break;
        }
      }
      if (items.length == 0 || !items[i]) return;
      let target =
        planData.sceneGraph.layers[selectedObject.layerID].items[items[i].id];
      let targetData = layer.items.getIn([items[i].id]);
      let targetPos = new Three.Vector2(targetData.x, targetData.y);
      let tRot = targetData.rotation;
      let tBounding = target.children[0].userData;
      let twidth = tBounding.max.x - tBounding.min.x;
      let tdepth = tBounding.max.z - tBounding.min.z;
      let tVertices = this.getRectPoints(
        twidth,
        tdepth,
        targetPos.clone(),
        ((tRot % 360) / 180) * Math.PI
      );
      // ////////////////////////
      let vArray = [];
      let dteArray = [];
      let lineArray = [];
      let vdistanceArray = [];
      let cVecArray = [];
      let inFlag = false;
      for (let i = 0; i < 4; i++) {
        let v1 = tVertices[i];
        let v2 = tVertices[i + 1];
        let data = pointLineDistance({ x: tPos.x, y: tPos.y }, [
          { x: v1.x, y: v1.y },
          { x: v2.x, y: v2.y }
        ]);
        dteArray.push(data.distance);
        vArray.push(data.point);
        lineArray.push([v1, v2]);
      }
      // if tPos in target object
      let tPosDistance =
        Math.abs(pointLineDistance(tPos, lineArray[0]).distance) +
        Math.abs(pointLineDistance(tPos, lineArray[2]).distance);
      let realDistance = new Three.Vector2(
        lineArray[1][0].x - lineArray[1][1].x,
        lineArray[1][0].y - lineArray[1][1].y
      ).length();
      let tPosDistance1 =
        Math.abs(pointLineDistance(tPos, lineArray[1]).distance) +
        Math.abs(pointLineDistance(tPos, lineArray[3]).distance);
      let realDistance1 = new Three.Vector2(
        lineArray[0][0].x - lineArray[0][1].x,
        lineArray[0][0].y - lineArray[0][1].y
      ).length();
      if (
        Math.abs(Math.abs(tPosDistance) - Math.abs(realDistance)) < 0.01 &&
        Math.abs(Math.abs(tPosDistance1) - Math.abs(realDistance1)) < 0.01
      )
        inFlag = true;
      // ////////////////////
      let key = 0;
      // sort distance from origin point
      for (let j = 0; j < dteArray.length - 1; j++) {
        for (let k = j + 1; k < dteArray.length; k++) {
          if (Math.abs(dteArray[j]) > Math.abs(dteArray[k])) {
            let temp = dteArray[k];
            dteArray[j] = dteArray[k];
            dteArray[k] = temp;
            let temp1 = vArray[k];
            vArray[j] = vArray[k];
            vArray[k] = temp1;
            let temp2 = lineArray[k];
            lineArray[j] = lineArray[k];
            lineArray[k] = temp2;
          }
        }
      }
      // //////////////////////////////
      for (let i = 0; i < 4; i++) {
        let data = pointLineDistance(sVertices[i], lineArray[key]);
        vdistanceArray.push(data.distance);
        cVecArray.push({
          x: data.point.x - sVertices[i].x,
          y: data.point.y - sVertices[i].y
        });
      }

      for (let j = 0; j < vdistanceArray.length; j++) {
        let tX = tPos.x + cVecArray[j].x;
        let tY = tPos.y + cVecArray[j].y;
        if (
          this.collisionCheck(
            item3D,
            new Three.Vector2(tX, tY),
            item.rotation,
            {
              userData: { itemId: null }
            },
            item,
            this.context.catalog
          )
        ) {
          item3D.position.set(tX, originPos.y, -tY);
          sPoint.set(tX, tY);
          break;
        }
      }
    };

    this.snap = function (obj, layer) {
      // snap operation
      let target = obj.userData.target;
      for (; target.parent != null; ) {
        if (target.name == 'pivot') break;
        target = target.parent;
      }
      let source = obj.parent.parent.parent;
      if (target.userData.type == 'item') {
        let sRot = layer.getIn(['items', source.userData.itemId]).rotation;
        let tRot = layer.getIn(['items', target.userData.itemId])
          ? layer.getIn(['items', target.userData.itemId]).rotation
          : 0;
        let item = layer.getIn(['items', source.userData.itemId]);
        let layoutType = item.layoutpos;
        let altitudeLength = convert(
          item.properties.getIn(['altitude', '_length'])
        )
          .from('in')
          .to('cm');
        let sBounding = source.children[0].userData;
        let tBounding = target.children[0].userData;
        let tPos = target.position.clone();
        let width = sBounding.max.x - sBounding.min.x;
        let height = sBounding.max.y - sBounding.min.y;
        let depth = sBounding.max.z - sBounding.min.z;
        let snapBoxGeom = new Three.BoxGeometry(width, height, depth);
        let snapBoxObj = new Three.Mesh(
          snapBoxGeom,
          new Three.MeshBasicMaterial({
            // color: 0x2cde6b,
            // opacity: 0.7,
            transparent: true,
            blending: Three.MultiplyBlending
          })
        );
        let removeSnapBoxObj = () => {
          if (snapBoxObj) {
            planData.plan.remove(snapBoxObj);
            disposeObject(snapBoxObj);
          }
          snapFlag = false;
        };
        let box = new Three.BoxHelper(snapBoxObj, 0xffffff);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 200;
        snapBoxObj.add(box);
        snapBoxObj.position.set(
          source.position.x,
          layoutType === 'Wall'
            ? altitudeLength + source.position.y + height / 2
            : source.position.y + height / 2,
          source.position.z
        );
        snapBoxObj.rotation.set(
          source.rotation.x,
          source.rotation.y,
          source.rotation.z
        );
        snapBoxObj.name = 'TransformBox';
        planData.plan.add(snapBoxObj);
        let deltaX =
          (tBounding.max.x - tBounding.min.x) / 2 +
          (sBounding.max.x - sBounding.min.x) / 2;
        let deltaZ =
          (tBounding.max.z - tBounding.min.z) / 2 -
          (sBounding.max.z - sBounding.min.z) / 2;
        let sPos = snapBoxObj.position.clone();
        // avaliable snap place///////////////
        let tPoses = [];
        let pX =
          tPos.x +
          deltaX * Math.cos((tRot / 180) * Math.PI) +
          deltaZ * Math.sin((tRot / 180) * Math.PI);
        let pZ =
          tPos.z -
          deltaX * Math.sin((tRot / 180) * Math.PI) +
          deltaZ * Math.cos((tRot / 180) * Math.PI);
        tPoses.push(new Three.Vector3(pX, 0, pZ));
        deltaX = -deltaX;
        pX =
          tPos.x +
          deltaX * Math.cos((tRot / 180) * Math.PI) +
          deltaZ * Math.sin((tRot / 180) * Math.PI);
        pZ =
          tPos.z -
          deltaX * Math.sin((tRot / 180) * Math.PI) +
          deltaZ * Math.cos((tRot / 180) * Math.PI);
        tPoses.push(new Three.Vector3(pX, 0, pZ));
        deltaX =
          (tBounding.max.x - tBounding.min.x) / 2 -
          (sBounding.max.x - sBounding.min.x) / 2;
        deltaZ =
          -(tBounding.max.z - tBounding.min.z) / 2 -
          (sBounding.max.z - sBounding.min.z) / 2;
        pX =
          tPos.x +
          deltaX * Math.cos((tRot / 180) * Math.PI) +
          deltaZ * Math.sin((tRot / 180) * Math.PI);
        pZ =
          tPos.z -
          deltaX * Math.sin((tRot / 180) * Math.PI) +
          deltaZ * Math.cos((tRot / 180) * Math.PI);
        tPoses.push(new Three.Vector3(pX, 0, pZ));
        deltaX = -deltaX;
        pX =
          tPos.x +
          deltaX * Math.cos((tRot / 180) * Math.PI) +
          deltaZ * Math.sin((tRot / 180) * Math.PI);
        pZ =
          tPos.z -
          deltaX * Math.sin((tRot / 180) * Math.PI) +
          deltaZ * Math.cos((tRot / 180) * Math.PI);
        tPoses.push(new Three.Vector3(pX, 0, pZ));
        let distance = Math.sqrt(
          (sPos.x - tPoses[0].x) * (sPos.x - tPoses[0].x) +
            (sPos.z - tPoses[0].z) * (sPos.z - tPoses[0].z)
        );
        let tNum = 1;
        tPos = tPoses[0].clone();
        for (let i = 1; i < tPoses.length; i++) {
          let curDis = Math.sqrt(
            (sPos.x - tPoses[i].x) * (sPos.x - tPoses[i].x) +
              (sPos.z - tPoses[i].z) * (sPos.z - tPoses[i].z)
          );
          if (curDis < distance) {
            distance = curDis;
            tNum = i + 1;
            tPos = tPoses[i].clone();
          }
        }
        // //////////////////////////////////
        if (
          targetObj != null &&
          targetObj.userData.itemId == target.userData.itemId &&
          tNum == targetNumber
        ) {
          removeSnapBoxObj();
          return;
        } else {
          removeSnapBox();
        }
        // //////////////////////////////////
        if (tNum >= 3) tRot += 180;

        snapAnimI = 0;
        t_i = 0;
        targetObj = target;
        targetNumber = tNum;
        targetRot = tRot;
        targetPoint = tPos;
        snapBox = snapBoxObj;
        let cx = sPos.x - tPos.x;
        let cz = sPos.z - tPos.z;
        targetUVec = new Three.Vector3(cx, 0, cz);
        targetCRotation = (((tRot - sRot) % 360) / 180) * Math.PI;
      } else {
        let item = layer.getIn(['items', source.userData.itemId]);
        if (holeItems.length) {
          let i;
          for (i = 0; i < holeItems.length; i++) {
            let hole = holeItems[i];
            if (Math.abs(Math.sin(selectedObj.rotRad)) === 1) {
              if (
                item.y + selectedObj.size.width / 2 >=
                  hole.y - hole.width / 2 &&
                item.y - selectedObj.size.width / 2 <=
                  hole.y + hole.width / 2 &&
                (selectedObj.rotRad == 0 || selectedObj.rotRad == -Math.PI / 2
                  ? item.x <= hole.x &&
                    item.x + selectedObj.size.height >= hole.x
                  : item.x >= hole.x &&
                    item.x - selectedObj.size.height <= hole.x)
              )
                break;
            } else {
              if (
                item.x + selectedObj.size.width / 2 >=
                  hole.x - hole.width / 2 &&
                item.x - selectedObj.size.width / 2 <=
                  hole.x + hole.width / 2 &&
                (selectedObj.rotRad == 0 || selectedObj.rotRad == -Math.PI / 2
                  ? item.y <= hole.y &&
                    item.y + selectedObj.size.height >= hole.y
                  : item.y >= hole.y &&
                    item.y - selectedObj.size.height <= hole.y)
              )
                break;
            }
          }
          if (i != holeItems.length) return;
        }
        if (target.userData.type == 'hole') {
          snapFlag = false;
          return;
        } else {
          let layoutType = item.layoutpos;
          let altitudeLength = convert(
            item.properties.getIn(['altitude', '_length'])
          )
            .from('in')
            .to('cm');
          let sBounding = source.children[0].userData;
          let width = sBounding.max.x - sBounding.min.x;
          let height = sBounding.max.y - sBounding.min.y;
          let depth = sBounding.max.z - sBounding.min.z;
          let snapBoxGeom = new Three.BoxGeometry(width, height, depth);
          let snapBoxObj = new Three.Mesh(
            snapBoxGeom,
            new Three.MeshBasicMaterial({
              // color: 0x2cde6b,
              // opacity: 0.7,
              transparent: true,
              blending: Three.MultiplyBlending
            })
          );
          let removeSnapBoxObj = () => {
            if (snapBoxObj) {
              planData.plan.remove(snapBoxObj);
              disposeObject(snapBoxObj);
            }
            snapFlag = false;
          };
          let box = new Three.BoxHelper(snapBoxObj, 0xffffff);
          box.material.linewidth = 2;
          box.material.depthTest = false;
          box.renderOrder = 100;
          snapBoxObj.add(box);

          snapBoxObj.position.set(
            source.position.x,
            layoutType === 'Wall'
              ? altitudeLength + source.position.y + height / 2
              : source.position.y + height / 2,
            source.position.z
          );
          snapBoxObj.rotation.set(
            source.rotation.x,
            source.rotation.y,
            source.rotation.z
          );
          snapBoxObj.name = 'TransformBox';
          planData.plan.add(snapBoxObj);

          let snapLine = layer.getIn(['lines', target.userData.lineId]);
          // let snapLineThickness = snapLine.properties.getIn([
          //   'thickness',
          //   'length'
          // ]);
          let snapLineThickness = 10.64;
          let vertices = [];
          if (snapLine === undefined) return;
          snapLine.vertices.forEach(data => {
            let vec = layer.getIn(['vertices', data]);
            vertices.push(vec);
          });
          let iX = source.position.clone().x;
          let iY = -source.position.clone().z;
          let lineVec = new Three.Vector2(
            vertices[1].x - vertices[0].x,
            vertices[1].y - vertices[0].y
          );
          let oLength = Math.sqrt(
            lineVec.x * lineVec.x + lineVec.y * lineVec.y
          );
          lineVec.normalize();
          let vec2 = new Three.Vector2(iX - vertices[0].x, iY - vertices[0].y);
          let vec2Legnth = Math.sqrt(vec2.x * vec2.x + vec2.y * vec2.y);
          let angle = Math.abs(lineVec.angle() - vec2.angle());
          angle = angle > Math.PI ? 2 * Math.PI - angle : angle;
          let lineLength = Math.cos(angle) * vec2Legnth;
          let transLength = 0;
          if (lineLength < 100) {
            transLength = -lineLength + (snapLineThickness + width) / 2;
          }
          if (lineLength > oLength - 100) {
            transLength =
              -lineLength - (snapLineThickness + width) / 2 + oLength;
          }
          let directPoint = new Three.Vector2(
            lineVec.x * lineLength + vertices[0].x,
            lineVec.y * lineLength + vertices[0].y
          );
          let directLine = new Three.Vector2(
            directPoint.x - iX,
            directPoint.y - iY
          );
          let dLength = Math.sqrt(
            (iX - directPoint.x) * (iX - directPoint.x) +
              (iY - directPoint.y) * (iY - directPoint.y)
          );
          let reduceLen = (snapLineThickness + depth) / 2;
          let scale = (dLength - reduceLen) / dLength;
          let tPos = new Three.Vector2(
            iX + (directPoint.x - iX) * scale + lineVec.x * transLength,
            iY + (directPoint.y - iY) * scale + lineVec.y * transLength
          );
          let realAngle = directLine.angle() - Math.PI / 2;
          let tRot = (realAngle * 180) / Math.PI;
          let sPos = new Three.Vector2(iX, iY);
          let sRot = item.rotation;
          let tNum = 0;
          // //////////////////////////////////////
          // check part////
          let result = this.collisionCheck(
            source,
            tPos,
            tRot,
            target,
            item,
            this.context.catalog
          );
          // console.log('result', result);
          if (result == false) {
            removeSnapBoxObj();
            removeSnapBox();
            return;
          }
          // ////////////////
          if (targetObj === target && snapBox !== null) {
            removeSnapBoxObj();
            snapAnimI = 20;
            targetPoint = new Three.Vector3(tPos.x, 0, -tPos.y);
            let sourcePos = snapBox.position.clone();
            let cx = sourcePos.x - targetPoint.x;
            let cz = sourcePos.z - targetPoint.z;
            targetUVec = new Three.Vector3(cx, 0, cz);
            targetRot = tRot;
            targetCRotation = (((tRot - sRot) % 360) / 180) * Math.PI;
            snapFlag = false;
            return;
          } else {
            removeSnapBox();
          }
          // //////////////////////////////////////
          snapAnimI = 0;
          t_i = 0;
          targetObj = target;
          targetNumber = tNum;
          targetRot = tRot;
          targetPoint = new Three.Vector3(tPos.x, 0, -tPos.y);
          snapBox = snapBoxObj;
          let sourcePos = snapBox.position.clone();
          let cx = sourcePos.x - targetPoint.x;
          let cz = sourcePos.z - targetPoint.z;
          targetUVec = new Three.Vector3(cx, 0, cz);
          targetRot = tRot;
          targetCRotation = (((tRot - sRot) % 360) / 180) * Math.PI;
        }
      }
    };

    /*end of snap functions*/
    let selectedObj = null;
    let firstMove = false;
    let prevX, prevY;

    let selObj = null;

    let createToolObject = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 100;
      canvas.height = 200;
      canvas.style.width = 50 + 'px';
      canvas.style.height = 100 + 'px';
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#FFFFFF';
      ctx.strokeStyle = '#000000';
      ctx.beginPath();
      ctx.arc(50, 50, 40, 0, 4 * Math.PI);
      ctx.fill();
      ctx.stroke();
      let img1 = new Image();
      img1.crossOrigin = 'anonymous';
      img1.src = '/assets/img/svg/3d_item_rotation.svg';
      img1.onload = function () {
        ctx.drawImage(img1, 16, 16, 68, 68);
      };

      ctx.beginPath();
      ctx.arc(50, 150, 40, 0, 4 * Math.PI);
      ctx.fill();
      ctx.stroke();
      let img2 = new Image();
      img2.crossOrigin = 'anonymous';
      img2.src = '/assets/img/svg/3d_item_move.svg';
      img2.onload = function () {
        ctx.drawImage(img2, 16, 116, 68, 68);
      };

      return canvas;
    };

    let clockWise = true;
    let lastAngle = 0;

    let createAngleObject = rotate => {
      const canvas = document.createElement('canvas');
      canvas.width = 100;
      canvas.height = 100;
      canvas.style.width = 100 + 'px';
      canvas.style.height = 100 + 'px';
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = 10;
      ctx.beginPath();
      ctx.arc(50, 50, 45, 0, 2 * Math.PI);
      ctx.stroke();

      ctx.strokeStyle = SECONDARY_PURPLE_COLOR;
      ctx.lineWidth = 6;
      ctx.beginPath();
      if (lastAngle < 15 && lastAngle > -15) {
        if (rotate >= 0) {
          clockWise = false;
        } else {
          clockWise = true;
        }
        if (lastAngle === 0) {
          if (rotate > -180) {
            clockWise = true;
          } else {
            clockWise = false;
          }
        }
      }

      ctx.arc(50, 50, 45, 0, (rotate / 180.0) * Math.PI, clockWise);
      ctx.stroke();

      lastAngle = rotate;

      return canvas;
    };

    let toolTexture = new Three.Texture(createToolObject());
    toolTexture.needsUpdate = true;

    let toolObj = new Three.Sprite(
      new Three.SpriteMaterial({ map: toolTexture, sizeAttenuation: true })
    );
    toolObj.material.transparent = true;
    toolObj.material.depthTest = false;
    toolObj.scale.set(20, 40, 20);
    toolObj.renderOrder = 3;
    toolObj.name = 'toolObj';

    let angleTexture = new Three.Texture(createAngleObject(0));
    angleTexture.needsUpdate = true;

    let angleObj = new Three.Sprite(
      new Three.SpriteMaterial({ map: angleTexture, sizeAttenuation: false })
    );

    angleObj.scale.set(0.075, 0.075, 0.075);
    angleObj.material.transparent = true;
    angleObj.material.depthTest = false;
    angleObj.renderOrder = 3;
    angleObj.name = 'angleObj';

    /**
     * Calculate plane point of mouse with `event` & `altitude`
     * * Calculate mouse.x & mouse.y
     * * Set raycaster from camera & Set raycaster direction
     * * Calculate Point & dispatch an action
     */
    const getPoint = (e, alti) => {
      mouse.x = (e.offsetX / this.width) * 2 - 1;
      mouse.y = (-e.offsetY / this.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      rayDirection = raycaster.ray.direction;

      Point = mapCursorPosition(e, alti);
      this.context.projectActions.updateMouseCoord(Point);
    };

    const getIntersectWallPoint = e => {
      mouse.x = (e.offsetX / this.width) * 2 - 1;
      mouse.y = (-e.offsetY / this.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      rayDirection = raycaster.ray.direction;

      let lines = planData.sceneGraph.layers[scene.selectedLayer].lines;
      let keys = Object.keys(lines);
      let arrMesh = [];
      keys.forEach(key => {
        lines[key].children[0].children.forEach(mesh => {
          if (mesh.name == 'soul' && mesh.visible) arrMesh.push(mesh);
        });
      });
      if (arrMesh.length > 0) {
        const intersects = raycaster.intersectObjects(arrMesh);
        if (intersects.length > 0) {
          let intersectPt = intersects[0].point;
          intersectPt.applyMatrix4(gridMatrix);

          Point = { x: intersectPt.x, y: -intersectPt.z };
          this.context.projectActions.updateMouseCoord(Point);
          return true;
        }
      }

      return false;
    };

    this.mouseDownEvent = event => {
      gridPlanOrigin = gridPlane.position;
      gridMatrix.copy(gridPlane.matrixWorld).invert();
      let altitude = 0;
      if (
        allItemRect &&
        allItemRect.cur &&
        allItemRect.cur.itemInfo !== undefined
      ) {
        let properties = allItemRect.cur.itemInfo.properties;
        altitude = properties.getIn(['altitude', '_length']);
        let unit = properties.getIn(['altitude', '_unit']) || 'in';
        altitude = convert(altitude).from(unit).to(this.props.state.scene.unit);
      }
      getPoint(event, altitude);

      const state = this.props.state;

      this.lastMousePosition.x = mouse.x;
      this.lastMousePosition.y = mouse.y;

      const layer = state.scene.getIn(['layers', state.scene.selectedLayer]);
      const sCount =
        layer.selected.areas.size +
        layer.selected.holes.size +
        layer.selected.items.size +
        layer.selected.lines.size;

      if (sCount <= 0) {
        isSelected = false;
      } else if (sCount >= 0 && selectedObject && 'holeID' in selectedObject) {
        isSelected = true;
      }

      prevX = Point.x;
      prevY = Point.y;

      /**
       * 0 - rotate
       * 1 - move up/down
       * 2 - move x/y
       * 3 - camera rotate
       */
      let transflag = 3;
      raycaster.setFromCamera(mouse, camera);
      const meshes = [];
      toIntersect.forEach(object => {
        if (!object) return;
        object.traverse(o => {
          if (o.isMesh) meshes.push(o);
        });
      });
      let intersects = raycaster.intersectObjects(meshes, true);

      let toolIntersects = raycaster.intersectObjects([toolObj], true);

      if (toolIntersects.length > 0) {
        let distance = Math.sqrt(
          Math.pow((toolIntersects[0].uv.x - 0.5) * 50, 2) +
            Math.pow((toolIntersects[0].uv.y - 0.25) * 100, 2)
        );
        if (distance <= 20) {
          transflag = 2;
        } else {
          distance = Math.sqrt(
            Math.pow((toolIntersects[0].uv.x - 0.5) * 50, 2) +
              Math.pow((toolIntersects[0].uv.y - 0.75) * 100, 2)
          );
          if (distance <= 20) {
            transflag = 0;
          }
        }
      }

      if (intersects.length > 0) {
        let i = 0,
          length = intersects.length;
        for (i = 0; i < length; i++) {
          if (
            intersects[i].object.type !== 'BoxHelper' &&
            // intersects[i].object.type === OBJTYPE_MESH &&
            intersects[i].object.name !== 'lineText' &&
            intersects[i].object.name !== 'soul' &&
            !intersects[i].object.name.includes('WarningBox')
          )
            break;
        }

        if (intersects[i] !== undefined) {
          if (intersects[i].object.name.indexOf('transUp') != -1) transflag = 1;
          if (intersects[i].object.name.indexOf('transHole') != -1)
            transflag = 2;
        } else {
          console.log('intersects[i] is undefined in viewer3d/viewer3d.js');
        }
      }

      if (this.props.state.mode == MODE_DRAWING_ITEM_3D) return;

      if (isSelected) {
        if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
          let i;
          for (i = 0; i < intersects.length; i++) {
            if (
              intersects[i].object instanceof Three.Mesh &&
              intersects[i].object.name != 'TransformBox' &&
              intersects[i].object.type != 'Line' &&
              intersects[i].object.name != 'lineText' &&
              intersects[i].object.name != 'countertops' &&
              intersects[i].object.type != 'BoxHelper' &&
              intersects[i].object.name != 'floor' &&
              intersects[i].object.name != 'backFace' &&
              intersects[i].object.name != 'soul' &&
              !intersects[i].object.name.includes('backsplash') &&
              !intersects[i].object.name.includes('WarningBox')
            )
              break;
          }
          if (intersects[i] === undefined) {
            if (transflag !== 0 && transflag !== 2) {
              isSelected = false;
              this.context.projectActions.unselectAll();
              scene3D.remove(toolObj);
              this.context.itemsActions.removeReplacingSupport();
              // orbitController.enableRotate = true;
              return;
            }
          }
          if (selectedObject !== undefined) {
            sPoint.set(Point.x, Point.y);
            if (transflag == 0) {
              scene3D.remove(toolObj);
              let alti = 0;
              if (
                allItemRect &&
                allItemRect.cur &&
                allItemRect.cur.itemInfo !== undefined
              ) {
                let properties = allItemRect.cur.itemInfo.properties;
                alti = properties.getIn(['altitude', '_length']);
                let unit = properties.getIn(['altitude', '_unit']) || 'in';
                alti = convert(alti).from(unit).to(this.props.state.scene.unit);
              }
              getPoint(
                { offsetX: event.offsetX - 50, offsetY: event.offsetY },
                alti
              );
              let selectedItem =
                planData.sceneGraph.layers[selectedObject.layerID].items[
                  selectedObject.itemID
                ];
              if (isUndefined(selectedItem)) return;
              const selItem = this.props.state.scene.layers
                .get(selectedObject.layerID)
                .items.get(selectedObject.itemID);

              lastAngle = 0;

              angleObj.position.set(
                planData.plan.position.x + Point.x,
                selItem.category === 'lighting'
                  ? -planData.plan.position.y -
                      selItem.properties.get('height').get('length')
                  : selItem.properties.get('altitude').get('length') +
                      planData.plan.position.y,
                planData.plan.position.z - Point.y
              );

              angleTexture.image = createAngleObject(0);
              angleTexture.needsUpdate = true;

              scene3D.add(angleObj);
              let centerPos = Point;
              getPoint(event, alti);

              this.context.itemsActions.beginRotatingItem3D(
                selectedObject.layerID,
                selectedObject.itemID,
                Point.x,
                Point.y,
                centerPos.x,
                centerPos.y
              );
              bRotate = true;
            } else if (transflag == 1) {
              bMoveUP = true;
            } else if (transflag == 2) {
              switch (true) {
                case 'holeID' in selectedObject:
                  this.context.holesActions.beginDraggingHole3D(
                    selectedObject.layerID,
                    selectedObject.holeID,
                    Point.x,
                    Point.y
                  );
                  break;
                default:
                  this.context.itemsActions.beginDraggingItem3D(
                    selectedObject.layerID,
                    selectedObject.itemID,
                    Point.x,
                    Point.y
                  );
                  break;
              }
              bMove = true;
              scene3D.remove(toolObj);
            }
            cameraControls.mouseButtons.left = CameraControls.ACTION.NONE;
          }
        } else {
          isSelected = false;
          this.context.projectActions.unselectAll();
          scene3D.remove(toolObj);
          this.context.itemsActions.removeReplacingSupport();
          // orbitController.enableRotate = true;
          return;
        }
      }
    };

    this.mouseUpEvent = event => {
      firstMove = 0;
      let altitude = 0;
      if (
        allItemRect &&
        allItemRect.cur &&
        allItemRect.cur.itemInfo !== undefined
      ) {
        let properties = allItemRect.cur.itemInfo.properties;
        altitude = properties.getIn(['altitude', '_length']);
        let unit = properties.getIn(['altitude', '_unit']) || 'in';
        altitude = convert(altitude).from(unit).to(this.props.state.scene.unit);
      }
      scene3D.remove(angleObj);
      scene3D.remove(toolObj);
      getPoint(event, altitude);
      if (this.props.state.mode == MODE_DRAWING_ITEM_3D) {
        if (Point.x > this.props.state.scene.width)
          Point.x = this.props.state.width;
        if (Point.y > this.props.state.scene.height)
          Point.y = this.props.state.height;
        if (Point.x < 0) Point.x = 0;
        if (Point.y < 0) Point.y = 0;
        if (snapBox == null) {
          actions.itemsActions.endDrawingItem(
            this.props.state.scene.selectedLayer,
            sPoint.x,
            sPoint.y
          );
        } else {
          actions.itemsActions.updateDraggingItemChanged(
            targetPoint.x,
            -targetPoint.z,
            selectedObject.layerID,
            selectedObject.itemID
          );
          this.context.itemsActions.updateRotatingItemChanged(
            targetRot,
            selectedObject.layerID,
            selectedObject.itemID
          );
          removeSnapBox();

          actions.itemsActions.endDrawingItem(
            this.props.state.scene.selectedLayer,
            Point.x,
            Point.y
          );
        }
        bMove = false;
        return;
      }

      if (this.props.state.mode == MODE_DRAGGING_ITEM_3D) {
        this.context.itemsActions.endDraggingItem3D();
      }

      if (this.props.state.mode == MODE_ROTATING_ITEM_3D) {
        this.context.itemsActions.endRotatingItem3D(sPoint.x, sPoint.y);
      }

      if (this.props.state.mode == MODE_DRAWING_HOLE_3D) {
        gridPlanOrigin = gridPlane.position;
        gridMatrix.copy(gridPlane.matrixWorld).invert();
        camPos = camera.position;
        if (!getIntersectWallPoint(event)) return;
        actions.holesActions.endDrawingHole3D(
          this.props.state.scene.selectedLayer,
          Point.x,
          Point.y
        );
        bMove = false;
        return;
      }

      event.preventDefault();
      if (event.button === 0) {
        // orbitController.enableRotate = true;
        cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
        mouse.x = (event.offsetX / this.width) * 2 - 1;
        mouse.y = -(event.offsetY / this.height) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        rayDirection = raycaster.ray.direction;
        rayDirection = rayDirection.normalize();

        const meshes = [];
        toIntersect.forEach(object => {
          if (!object) return;
          object.traverse(o => {
            if (o.isMesh) meshes.push(o);
          });
        });

        let intersects = raycaster.intersectObjects(meshes, true);
        let i;

        if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
          for (i = 0; i < intersects.length; i++) {
            if (intersects[i].object.name === 'warningObj') break;
          }
          if (intersects[i] !== undefined) {
            let distance = Math.sqrt(
              Math.pow((intersects[i].uv.x - 0.5) * 50, 2) +
                Math.pow((intersects[i].uv.y - 0.25) * 100, 2)
            );
            if (distance <= 20) {
              if (!bMove && !bRotate) {
                isSelected = false;
                this.context.projectActions.unselectAll();
                scene3D.remove(toolObj);
                this.context.itemsActions.removeReplacingSupport();
                // orbitController.enableRotate = true;

                let replaceInfo = intersects[i].object.parent.parent.userData;
                this.context.itemsActions.selectItem(
                  replaceInfo.layerId,
                  replaceInfo.itemId
                );
                this.props.replaceCabinet(true);

                return;
              }
            }
          }
        }
        this.props.replaceCabinet(false);
        if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
          for (i = 0; i < intersects.length; i++) {
            if (
              intersects[i].object instanceof Three.Mesh &&
              intersects[i].object.name != 'TransformBox' &&
              intersects[i].object.type != 'Line' &&
              intersects[i].object.name != 'lineText' &&
              intersects[i].object.name != 'countertops' &&
              intersects[i].object.type != 'BoxHelper' &&
              intersects[i].object.name != 'soul' &&
              !intersects[i].object.name.includes('backsplash') &&
              !intersects[i].object.name.includes('WarningBox')
            )
              break;
          }
        }
        gridMatrix.copy(gridPlane.matrixWorld).invert();
        if (
          (Math.abs(mouse.x - this.lastMousePosition.x) <= 0.02 &&
            Math.abs(mouse.y - this.lastMousePosition.y) <= 0.02) ||
          bMove
        ) {
          if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
            if (intersects[i] === undefined) {
              if (!bMove) {
                isSelected = false;
                this.context.projectActions.unselectAll();
                scene3D.remove(toolObj);
                this.context.itemsActions.removeReplacingSupport();
                // orbitController.enableRotate = true;
                return;
              }
            }

            if (!bMove) {
              selectedObject =
                intersects[i].object.interact &&
                intersects[i].object.interact();
            }

            if (selectedObject !== undefined && 'itemID' in selectedObject) {
              currentObject = intersects[i].object;
              for (; currentObject.parent != null; ) {
                if (currentObject.name == 'pivot') break;
                currentObject = currentObject.parent;
              }
              isSelected = true;
              setTimeout(() => {
                getDistances(layer);
                let selectedItem =
                  planData.sceneGraph.layers[selectedObject.layerID].items[
                    selectedObject.itemID
                  ];
                if (isUndefined(selectedItem)) return;
                const selItem = this.props.state.scene.layers
                  .get(selectedObject.layerID)
                  .items.get(selectedObject.itemID);
                let itemPos = selectedItem.position.clone();

                if (
                  intersects[i].object.parent &&
                  intersects[i].object.parent.parent.userData.itemId ===
                    selectedItem.userData.itemId
                ) {
                  toolObj.position.set(
                    intersects[i].point.x,
                    intersects[i].point.y,
                    intersects[i].point.z
                  );
                } else {
                  toolObj.position.set(
                    planData.plan.position.x + itemPos.x,
                    selItem.category === 'lighting'
                      ? -planData.plan.position.y -
                          selItem.properties.get('height').get('length')
                      : planData.plan.position.y +
                          selectedItem.children[0].position.y,
                    planData.plan.position.z + itemPos.z
                  );
                }
                scene3D.add(toolObj);
                // showItemButtons(layer.getIn(['items', selectedObject.itemID]), currentObject, event, camera, this.renderer);
                let pointArray = [],
                  cnt = 0;
                pointArray.push([fVLine[0].userData.distance, 90]);
                pointArray.push([fVLine[1].userData.distance, -90]);
                pointArray.push([fVLine[2].userData.distance, 180]);
                pointArray.push([fVLine[3].userData.distance, 0]);
                pointArray.forEach((pointElement, index) => {
                  if (pointElement[0] == undefined) pointArray[index][0] = 0;
                });
                pointArray.forEach(pointElement => {
                  if (pointElement[0] == 0) cnt++;
                });
                if (cnt == 4 || cnt == 3) {
                  pointArray[0][0] = 100;
                  pointArray[1][0] = 100;
                }
                actions.itemsActions.storeDistArray(
                  layer.id,
                  selectedObject.itemID,
                  pointArray
                );
              });
            } else {
              // orbitController.enableRotate = true;
              if (selectedObject) {
                switch (true) {
                  case 'holeID' in selectedObject:
                    if (selObj?.object?.name.includes('transHole')) {
                      actions.holesActions.endDraggingHole3D(
                        sPoint.x,
                        sPoint.y
                      );
                    }
                    break;
                  case 'lineID' in selectedObject:
                    if (selObj?.object?.name.includes('transHole')) {
                      actions.holesActions.endDraggingHole3D(
                        sPoint.x,
                        sPoint.y
                      );
                    }
                    break;
                  default:
                    break;
                }
              }
              isSelected = false;
            }
          } else {
            isSelected = false;
            this.context.projectActions.unselectAll();
            switch (true) {
              case 'holeID' in selectedObject:
                actions.holesActions.endDraggingHole3D(sPoint.x, sPoint.y);
                break;
              default:
                this.context.itemsActions.removeReplacingSupport();
                break;
            }
          }
          bMove = false;
          bRotate = false;
          bMoveUP = false;
          if (isSelected) {
            prepareSnap(layer);
            selectedObj = allItemRect.cur;
          }
        } else {
          visibleTransformBox(false);
          let alti = 0;
          if (
            allItemRect &&
            allItemRect.cur &&
            allItemRect.cur.itemInfo !== undefined
          ) {
            let properties = allItemRect.cur.itemInfo.properties;
            alti = properties.getIn(['altitude', '_length']);
            let unit = properties.getIn(['altitude', '_unit']) || 'in';
            alti = convert(alti).from(unit).to(this.props.state.scene.unit);
          }
          getPoint(event, alti);
          if (bRotate) {
            let selectedItem =
              planData.sceneGraph.layers[selectedObject.layerID].items[
                selectedObject.itemID
              ];
            if (isUndefined(selectedItem)) return;
            const selItem = this.props.state.scene.layers
              .get(selectedObject.layerID)
              .items.get(selectedObject.itemID);
            let itemPos = selectedItem.position.clone();

            if (
              intersects[i].object.parent.parent.userData.itemId ===
              selectedItem.userData.itemId
            ) {
              toolObj.position.set(
                intersects[i].point.x,
                intersects[i].point.y,
                intersects[i].point.z
              );
            } else {
              toolObj.position.set(
                planData.plan.position.x + itemPos.x,
                selItem.category === 'lighting'
                  ? -planData.plan.position.y -
                      selItem.properties.get('height').get('length')
                  : planData.plan.position.y +
                      selectedItem.children[0].position.y,
                planData.plan.position.z + itemPos.z
              );
            }

            scene3D.add(toolObj);
            this.context.itemsActions.endRotatingItem3D(Point.x, Point.y);
            bRotate = false;
          }
          if (bMove) {
            bMove = false;
            let selectedItem =
              planData.sceneGraph.layers[selectedObject.layerID].items[
                selectedObject.itemID
              ];
            if (isUndefined(selectedItem)) return;
            const selItem = this.props.state.scene.layers
              .get(selectedObject.layerID)
              .items.get(selectedObject.itemID);
            let itemPos = selectedItem.position.clone();
            if (
              intersects[i].object.parent.parent.userData.itemId ===
              selectedItem.userData.itemId
            ) {
              toolObj.position.set(
                intersects[i].point.x,
                intersects[i].point.y,
                intersects[i].point.z
              );
            } else {
              toolObj.position.set(
                planData.plan.position.x + itemPos.x,
                selItem.category === 'lighting'
                  ? -planData.plan.position.y -
                      selItem.properties.get('height').get('length')
                  : planData.plan.position.y +
                      selectedItem.children[0].position.y,
                planData.plan.position.z + itemPos.z
              );
            }

            scene3D.add(toolObj);
            if (snapBox == null) {
              let item3D =
                planData.sceneGraph.layers[selectedObject.layerID].items[
                  selectedObject.itemID
                ];
              if (!item3D) return;
              item3D.visible = true;
              let originPos = item3D.position.clone();
              setTimeout(() => {
                this.context.itemsActions.updateDraggingItemChanged(
                  originPos.x,
                  -originPos.z,
                  selectedObject.layerID,
                  selectedObject.itemID
                );
              }, 50);
            } else {
              this.context.itemsActions.updateDraggingItemChanged(
                targetPoint.x,
                -targetPoint.z,
                selectedObject.layerID,
                selectedObject.itemID
              );
              this.context.itemsActions.updateRotatingItemChanged(
                targetRot,
                selectedObject.layerID,
                selectedObject.itemID
              );
              let item3D =
                planData.sceneGraph.layers[selectedObject.layerID].items[
                  selectedObject.itemID
                ];
              item3D.position.x = targetPoint.x;
              item3D.position.z = targetPoint.z;
              item3D.visible = true;
            }
          }
          if (bMoveUP) {
            bMoveUP = false;
          }
        }
        removeSnapBox();

        actions.sceneActions.updateMovingState(true);
        if (isSelected === true) {
          prepareSnap(layer);
          selectedObj = allItemRect.cur;
        }
      }
    };

    this.mouseMoveEvent = event => {
      event.preventDefault();
      backsplashVisible = false;

      let altitude = 0;
      if (
        allItemRect &&
        allItemRect.cur &&
        allItemRect.cur.itemInfo !== undefined
      ) {
        let properties = allItemRect.cur.itemInfo.properties;
        altitude = properties.getIn(['altitude', '_length']);
        let unit = properties.getIn(['altitude', '_unit']) || 'in';
        altitude = convert(altitude).from(unit).to(this.props.state.scene.unit);
      }

      if (this.props.state.mode === MODE_DRAWING_HOLE_3D) {
        gridPlanOrigin = gridPlane.position;
        gridMatrix.copy(gridPlane.matrixWorld).invert();
        camPos = camera.position;
        if (!getIntersectWallPoint(event)) return;

        let drawingSupport = this.props.state.drawingSupport;
        if (!drawingSupport.has('currentID')) {
          this.context.holesActions.updateDrawingHole3D(
            this.props.state.scene.selectedLayer,
            Point.x,
            Point.y
          );
          sPoint.set(Point.x, Point.y);
        } else {
          let layerID = this.props.state.scene.selectedLayer;
          let holeID = this.props.state.drawingSupport.get('currentID');

          deleteSpecifiedMeshObjects('WarningBox' + holeID);
          prepareSnap(layer);

          // let {nx, ny, rot} = GeometryUtils.calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, Point.x, Point.y, allArea);
          let nx = Point.x;
          let ny = Point.y;
          actions.holesActions.updateDrawingHole3D(layerID, nx, ny);
          sPoint.set(nx, ny);
        }
      }

      if (this.props.state.mode === MODE_DRAWING_ITEM_3D) {
        // We need to set cam position since when MODE_DRAWING_ITEM_3D mouse down event
        // is not emited so cam position is not valid
        gridPlanOrigin = gridPlane.position;
        gridMatrix.copy(gridPlane.matrixWorld).invert();
        camPos = camera.position;
        getPoint(event, altitude);
        if (
          Point.x > this.props.state.scene.width ||
          Point.x < 0 ||
          Point.y > this.props.state.scene.height ||
          Point.y < 0
        )
          return;
        let drawingSupport = this.props.state.drawingSupport;
        if (!drawingSupport.has('currentID')) {
          this.context.itemsActions.updateDrawingItem(
            this.props.state.scene.selectedLayer,
            Point.x,
            Point.y
          );
          sPoint.set(Point.x, Point.y);
        } else {
          let layerID = this.props.state.scene.selectedLayer;
          let itemID = this.props.state.drawingSupport.get('currentID');
          selectedObject = { layerID: layerID, itemID: itemID };
          let mX = Point.x - sPoint.x;
          let mY = Point.y - sPoint.y;
          let item3D =
            planData.sceneGraph.layers[selectedObject.layerID].items[
              selectedObject.itemID
            ];
          let layer = this.props.state.scene.getIn([
            'layers',
            selectedObject.layerID
          ]);

          deleteSpecifiedMeshObjects('TransformBox');
          deleteSpecifiedMeshObjects('WarningBox' + itemID);
          prepareSnap(layer);

          let item = layer.items.getIn([selectedObject.itemID]);
          item.counterTop.uri = layer.counterTop.uri;
          let sRot = item.rotation;

          if (!item3D) return;

          let originPos = item3D.position.clone();
          let tPos = new Three.Vector2(mX + originPos.x, mY - originPos.z);
          let sBounding = item3D.children[0].userData;
          let swidth = sBounding.max.x - sBounding.min.x;
          let sdepth = sBounding.max.z - sBounding.min.z;
          let sVertices = this.getRectPoints(
            swidth,
            sdepth,
            tPos.clone(),
            ((sRot % 360) / 180) * Math.PI
          );
          let { nx, ny, rot } = GeometryUtils.calcSnap(
            allItemRect,
            allItemSnap,
            allLineRects,
            allLineSnap,
            allRect,
            Point.x,
            Point.y,
            allArea
          );
          let nPos = new Three.Vector2(nx, ny);
          if (
            !this.collisionHoleCheck(
              item3D,
              nPos,
              item.rotation,
              {
                userData: { itemId: null }
              },
              item,
              this.context.catalog
            )
          ) {
            this.collisionSlide(
              item3D,
              originPos,
              layer,
              sVertices,
              tPos,
              item
            );
            //return;
          }
          let polygonPoint = lineRect(layer);
          if (polygonPoint.length > 0) {
            let PolygonSect = GeometryUtils.ContainsPoint(polygonPoint, nx, ny);
            if (!PolygonSect) {
              let { cx, cy, crot } = GeometryUtils.calcCreateSnap(
                allItemRect,
                allItemSnap,
                allLineRects,
                allLineSnap,
                allRect,
                Point.x,
                Point.y,
                polygonPoint
              );
              nx = cx;
              ny = cy;
              rot = crot;
            }
          }
          let polygon = lineRect(layer);
          if (polygon.length > 0) {
            let Sect = GeometryUtils.ContainsPoint(polygon, nx, ny);
            if (!Sect) return;
          }
          actions.itemsActions.updateDraggingItemChanged(
            nx,
            ny,
            selectedObject.layerID,
            selectedObject.itemID
          );
          actions.itemsActions.updateRotatingItemChanged(
            rot,
            selectedObject.layerID,
            selectedObject.itemID
          );
          sPoint.set(nx, ny);
          getDistances(layer);
          let pointArray = [],
            cnt = 0;
          pointArray.push([fVLine[0].userData.distance, 90]);
          pointArray.push([fVLine[1].userData.distance, -90]);
          pointArray.push([fVLine[2].userData.distance, 180]);
          pointArray.push([fVLine[3].userData.distance, 0]);
          pointArray.forEach((pointElement, index) => {
            if (pointElement[0] == undefined) pointArray[index][0] = 0;
          });
          pointArray.forEach(pointElement => {
            if (pointElement[0] == 0) cnt++;
          });
          if (cnt == 4 || cnt == 3) {
            pointArray[0][0] = 100;
            pointArray[1][0] = 100;
          }
          actions.itemsActions.storeDistArray(layer.id, item.id, pointArray);
          let minDis = fVLine[0].userData.distance;
          let snapObj = fVLine[0];
          let iPos = item3D.position.clone();
          let snapDis = Math.sqrt(
            (iPos.x - targetPoint.x) * (iPos.x - targetPoint.x) +
              (iPos.z - targetPoint.z) * (iPos.z - targetPoint.z)
          );
          if (snapDis >= 100 && snapObj != null) {
            removeSnapBox();
            backsplashVisible = false;
            //console.log('1 snap no');
          }
          for (let i = 1; i < fVLine.length; i++) {
            if (minDis > fVLine[i].userData.distance) {
              minDis = fVLine[i].userData.distance;
              snapObj = fVLine[i];
            }
          }
          if (snapBox == null) {
            snapFlag = false;
          }
          actions.sceneActions.updateMovingState(false);
          if (minDis < snapDelta && !snapFlag) {
            this.snap(snapObj, layer);
            snapFlag = true;
            //console.log('1 snap Yes')
            getDistances(layer, true);
            let i = 0;
            for (i = 0; i < fVLine.length; i++) {
              if (fVLine[i].userData.distance < snapDelta) {
                break;
              }
            }
            if (i === fVLine.length) backsplashVisible = false;
            else backsplashVisible = true;
          }
          actions.itemsActions.setBacksplashVisible(
            selectedObject.itemID,
            backsplashVisible
          );
        }
      }

      if (bRotate) {
        getPoint(event, altitude);
        this.context.itemsActions.updateRotatingItem(Point.x, Point.y);

        let layer = this.props.state.scene.getIn([
          'layers',
          selectedObject.layerID
        ]);
        let item = layer.items.getIn([selectedObject.itemID]);

        let orginRot = this.props.state.rotatingSupport.get('originRotation');

        angleTexture.image = createAngleObject(
          (orginRot < 0 ? 360 - orginRot : orginRot) - item.rotation
        );
        angleTexture.needsUpdate = true;

        deleteSpecifiedMeshObjects('WarningBox' + selectedObject.itemID);
        deleteSpecifiedMeshObjects('backsplash' + selectedObject.itemID);

        actions.sceneActions.updateMovingState(false);
      }

      if (bMoveUP) {
        this.context.itemsActions.updateItemsAltitude(
          selectedObject.layerID,
          selectedObject.itemID,
          event.movementY
        );
      }

      if (bMove) {
        if ('holeID' in selectedObject) {
          gridPlanOrigin = gridPlane.position;
          gridMatrix.copy(gridPlane.matrixWorld).invert();
          camPos = camera.position;
          if (!getIntersectWallPoint(event)) return;
          let draggingSupport = this.props.state.draggingSupport;
          if (!draggingSupport.has('currentID')) {
            this.context.holesActions.updateDraggingHole(Point.x, Point.y);
            sPoint.set(Point.x, Point.y);
          } else {
            let holeID = this.props.state.draggingSupport.get('currentID');
            deleteSpecifiedMeshObjects('WarningBox' + holeID);
            let nx = Point.x;
            let ny = Point.y;
            actions.holesActions.updateDraggingHole(nx, ny);
            sPoint.set(nx, ny);
          }
        } else {
          const item3D =
            planData.sceneGraph.layers[selectedObject.layerID].items[
              selectedObject.itemID
            ];

          if (item3D === undefined) return;

          const layer = this.props.state.scene.getIn([
            'layers',
            item3D.userData.layerId
          ]);

          const item = layer.items.getIn([selectedObject.itemID]);
          if (item.counterTop.uri == '')
            item.counterTop.uri = layer.counterTop.uri;

          let sRot = item.rotation;
          let mX = Point.x - sPoint.x;
          let mY = Point.y - sPoint.y;

          deleteSpecifiedMeshObjects('WarningBox' + selectedObject.itemID);
          deleteSpecifiedMeshObjects('backsplash' + selectedObject.itemID);
          prepareSnap(layer);

          getPoint(event, altitude);

          let originPos = item3D.position.clone();
          let tPos = new Three.Vector2(mX + originPos.x, mY - originPos.z);
          let sBounding = item3D.children[0].userData;
          let swidth = sBounding.max.x - sBounding.min.x;
          let sdepth = sBounding.max.z - sBounding.min.z;
          let sVertices = this.getRectPoints(
            swidth,
            sdepth,
            tPos.clone(),
            ((sRot % 360) / 180) * Math.PI
          );
          let { nx, ny, rot } = GeometryUtils.calcSnap(
            allItemRect,
            allItemSnap,
            allLineRects,
            allLineSnap,
            allRect,
            Point.x,
            Point.y,
            allArea
          );
          let nPos = new Three.Vector2(nx, ny);
          if (
            !this.collisionHoleCheck(
              item3D,
              nPos,
              item.rotation,
              {
                userData: { itemId: null }
              },
              item,
              this.context.catalog
            )
          ) {
            this.collisionSlide(
              item3D,
              originPos,
              layer,
              sVertices,
              tPos,
              item
            );
            //return;
          }
          let polygonPoint = lineRect(layer);
          if (polygonPoint.length > 0) {
            let PolygonSect = GeometryUtils.ContainsPoint(polygonPoint, nx, ny);
            if (!PolygonSect) {
              let { cx, cy, crot } = GeometryUtils.calcCreateSnap(
                allItemRect,
                allItemSnap,
                allLineRects,
                allLineSnap,
                allRect,
                Point.x,
                Point.y,
                polygonPoint
              );
              nx = cx;
              ny = cy;
              rot = crot;
            }
          }
          let polygon = lineRect(layer);
          if (polygon.length > 0) {
            let Sect = GeometryUtils.ContainsPoint(polygon, nx, ny);
            if (!Sect) return;
          }
          actions.itemsActions.updateDraggingItemChanged(
            nx,
            ny,
            selectedObject.layerID,
            selectedObject.itemID
          );
          actions.itemsActions.updateRotatingItemChanged(
            rot,
            selectedObject.layerID,
            selectedObject.itemID
          );
          sPoint.set(nx, ny);
          getDistances(layer);
          let pointArray = [],
            cnt = 0;
          pointArray.push([fVLine[0].userData.distance, 90]);
          pointArray.push([fVLine[1].userData.distance, -90]);
          pointArray.push([fVLine[2].userData.distance, 180]);
          pointArray.push([fVLine[3].userData.distance, 0]);
          pointArray.forEach((pointElement, index) => {
            if (pointElement[0] == undefined) pointArray[index][0] = 0;
          });
          pointArray.forEach(pointElement => {
            if (pointElement[0] == 0) cnt++;
          });
          if (cnt == 4 || cnt == 3) {
            pointArray[0][0] = 100;
            pointArray[1][0] = 100;
          }
          actions.itemsActions.storeDistArray(layer.id, item.id, pointArray);
          let minDis = fVLine[0].userData.distance;
          let snapObj = fVLine[0];
          let iPos = item3D.position.clone();
          let snapDis = Math.sqrt(
            (iPos.x - targetPoint.x) * (iPos.x - targetPoint.x) +
              (iPos.z - targetPoint.z) * (iPos.z - targetPoint.z)
          );
          if (snapDis >= 100 && snapObj != null) {
            removeSnapBox();
            backsplashVisible = false;
            //console.log('1 snap no');
          }
          for (let i = 1; i < fVLine.length; i++) {
            if (minDis > fVLine[i].userData.distance) {
              minDis = fVLine[i].userData.distance;
              snapObj = fVLine[i];
            }
          }
          if (snapBox == null) {
            snapFlag = false;
          }
          actions.sceneActions.updateMovingState(false);
          if (minDis < snapDelta && !snapFlag) {
            this.snap(snapObj, layer);
            snapFlag = true;
            //console.log('1 snap Yes')
            getDistances(layer, true);

            let i = 0;
            for (i = 0; i < fVLine.length; i++) {
              if (fVLine[i].userData.distance < snapDelta) {
                break;
              }
            }
            if (i === fVLine.length) backsplashVisible = false;
            else backsplashVisible = true;
          }
          actions.itemsActions.setBacksplashVisible(
            selectedObject.itemID,
            backsplashVisible
          );
        }
      }

      if (!bMove && !bRotate && !bMoveUP) {
        let curPos = {
          x: (event.offsetX / this.width) * 2 - 1,
          y: -(event.offsetY / this.height) * 2 + 1
        };

        raycaster.setFromCamera(curPos, camera);
        rayDirection = raycaster.ray.direction;
        rayDirection = rayDirection.normalize();

        const meshes = getAllMeshes(toIntersect);
        let intersects = raycaster.intersectObjects(meshes, true);
        let i;
        if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
          for (i = 0; i < intersects.length; i++) {
            if (intersects[i].object.name === 'warningObj') break;
          }
          if (intersects[i] !== undefined) {
            let distance = Math.sqrt(
              Math.pow((intersects[i].uv.x - 0.5) * 50, 2) +
                Math.pow((intersects[i].uv.y - 0.75) * 100, 2)
            );
            if (distance <= 20) {
              let infoPos = { x: 0, y: 0 };
              if (event.offsetY > 150) {
                infoPos.y = event.offsetY - 60;
              } else {
                infoPos.y = event.offsetY + 10;
              }
              if (event.offsetX > this.width - 650) {
                infoPos.x = event.offsetX - 650;
              } else {
                infoPos.x = event.offsetX;
              }
              document.getElementById('warning_box_2d').style.display = 'flex';
              document.getElementById(
                'warning_box_2d'
              ).style.top = `${infoPos.y}px`;
              document.getElementById(
                'warning_box_2d'
              ).style.left = `${infoPos.x}px`;
              return;
            }
          }
        }
      }
      document.getElementById('warning_box_2d').style.display = 'none';
    };

    this.onkeydown = event => {
      if (this.props.keyDownEnable) {
        switch (event.keyCode) {
          case 27: // escape key
            isSelected = false;
            this.context.projectActions.unselectAll();
            this.context.itemsActions.removeReplacingSupport();
            this.context.itemsActions.setMoveStatus(false);
            this.context.itemsActions.setRotateStatus(false);
            bMoveUP = false;
            removeSnapBox();
            break;
          case 65: // w (move forward)
            pivot.rotation.y -= 0.03;
            break;
          case 68: // s (move backward)
            pivot.rotation.y += 0.03;
            break;
          case 83: // a (look left)
            pivot.rotation.x += 0.03;
            break;
          case 87: // d (look left)
            pivot.rotation.x -= 0.03;
            break;
          case 37: // left (move forward)
            planData.plan.position.x += 10;
            planData.grid.position.x += 10;
            break;
          case 38: // Up (move backward)
            planData.plan.position.y += 10;
            planData.grid.position.y += 10;
            break;
          case 39: // right arrow (look left)
            planData.plan.position.x -= 10;
            planData.grid.position.x -= 10;
            break;
          case 40: // down arrow (look left)
            planData.plan.position.y -= 10;
            planData.grid.position.y -= 10;
            break;
        }
      }
    };

    this.handleKeyDown = event => {
      this.onkeydown(event);
    };

    // Renderer & Event listener

    this.renderer.setClearColor(new Three.Color(SharedStyle.COLORS.white)); // 3D background color
    this.renderer.setSize(this.width, this.height);

    this.renderer.domElement.addEventListener('mousedown', this.mouseDownEvent);
    this.renderer.domElement.addEventListener('mouseup', this.mouseUpEvent);
    this.renderer.domElement.addEventListener('mousemove', this.mouseMoveEvent);

    window.addEventListener('keydown', this.onkeydown);
    window.SPKeyDown = this.handleKeyDown;
    window.tDKeyDown = this.handleKeyDown;

    // Add the output of the renderer to the html element
    const canvasWrapper = ReactDOM.findDOMNode(this.refs.canvasWrapper);
    canvasWrapper.appendChild(this.renderer.domElement);

    //

    if (
      scene.getIn(['layers', scene.selectedLayer, 'selected', 'items']).size !=
      0
    ) {
      // if selected Object
      isSelected = true;
      selectedObject.layerID = scene.selectedLayer;
      selectedObject.itemID = scene
        .getIn(['layers', scene.selectedLayer, 'selected', 'items'])
        .toJS()[0];
      setTimeout(() => {
        try {
          currentObject =
            planData.sceneGraph.layers[selectedObject.layerID].items[
              selectedObject.itemID
            ];
          if (isUndefined(currentObject)) return;
          const selItem = this.props.state.scene.layers
            .get(selectedObject.layerID)
            .items.get(selectedObject.itemID);
          let itemPos = currentObject.position.clone();
          toolObj.position.set(
            planData.plan.position.x + itemPos.x,
            selItem.category === 'lighting'
              ? -planData.plan.position.y -
                  selItem.properties.get('height').get('length')
              : planData.plan.position.y + currentObject.children[0].position.y,
            planData.plan.position.z + itemPos.z
          );

          scene3D.add(toolObj);
        } catch (err) {
          console.log(
            'selectedObject : ' +
              JSON.stringify(selectedObject) +
              '\nError: ' +
              err
          );
        }
      }, 2000);
    }

    // Scene functions

    function init() {
      clock = new Three.Clock();
      scene3D = new Three.Scene();
      scene3D.background = new Three.Color(0xc3cadc); // change color about v1: 0x8791AB, v2: 0xC2C2C2, v3: 0xC3CADC
      // scene3D.fog = new Three.Fog(0xC3CADC, 2000, 3500);
      window.scene3D = scene3D;
      // Camera

      const aspectRatio = self.width / self.height;
      camera = new Three.PerspectiveCamera(45, aspectRatio, 1, 300000);
      camera.position.set(0, 0, 1);
      camera.layers.enable(1);

      // Camera Controls
      cameraControls = new CameraControls(camera, self.renderer.domElement);
      cameraControls.dollyToCursor = true;
      cameraControls.infinityDolly = true;
      cameraControls.minDistance = 50;
      cameraControls.maxDistance = Infinity;
      cameraControls.zoomSpeed = 1;

      function loadENV() {
        new RGBELoader().load(
          '/assets/brown_photostudio_02_1k.hdr',
          function (texture) {
            texture.mapping = Three.EquirectangularReflectionMapping;
            scene3D.environment = texture;
            texture.dispose();
          }
        );
      }
      loadENV();

      scene3D.add(planData.plan);
      scene3D.add(planData.grid);
      scene3D.add(camera);

      //

      pivot = new Three.Object3D();
      pivot.add(camera);

      scene3D.add(pivot);

      // LIGHT

      let light = new Three.AmbientLight(0xbfbfbf, 0.9); // soft white light
    }

    function render() {
      const delta = clock.getDelta(); // Get time delta for smooth updates
      cameraControls.update(delta);
      for (let i = 0; i < lights.length; i++) {
        lights[i].light.position.set(
          planData.plan.position.x + lights[i].x,
          planData.plan.position.y + lights[i].height - 10,
          planData.plan.position.z - lights[i].y
        );
        lights[i].target.position.set(
          planData.plan.position.x + lights[i].x,
          planData.plan.position.y,
          planData.plan.position.z - lights[i].y
        );
      }

      camera.updateMatrix();
      camera.updateMatrixWorld();

      for (let elemID in planData.sceneGraph.LODs) {
        planData.sceneGraph.LODs[elemID].update(camera);
      }
      if (snapBox !== null) {
        if (snapAnimI >= 15) {
          if ('x' in targetPoint)
            snapBox.position.set(
              targetPoint.x,
              snapBox.position.y,
              targetPoint.z
            );
          snapFlag = false;
          t_i++;
        } else {
          if (snapAnimI == 0) {
            targetCRotation =
              ((((targetRot / 180) * Math.PI - snapBox.rotation.y + Math.PI) *
                180) /
                Math.PI) %
              360;
            if (targetCRotation > 180) targetCRotation -= 360;
            if (targetCRotation < -180) targetCRotation += 360;
            targetCRotation = (targetCRotation / 180) * Math.PI;
          }
          if (snapAnimI < 10) {
            snapBox.rotateY(targetCRotation / 10);
          } else {
            snapBox.position.set(
              snapBox.position.clone().x - targetUVec.x / 10,
              snapBox.position.clone().y - targetUVec.y / 10,
              snapBox.position.clone().z - targetUVec.z / 10
            );
          }
          snapAnimI++;
        }
      }

      if (t_i === 20) {
        //stop snap after 5s
        removeSnapBox();
      }

      // hide hole if wall is not visible
      let layerID = self.props.state.scene.selectedLayer;
      let layer = self.props.state.scene.layers.get(layerID);
      layer.holes.forEach(data => {
        let line = planData.sceneGraph.layers[layerID].lines[data.line];
        let hole = planData.sceneGraph.layers[layerID].holes[data.id];
        if (line instanceof Three.Object3D && !line.isMesh) {
          line = line.children[0].children[0];
          // index(faces) of the line
          const indexAttribute = line.geometry.getIndex();
          const firstFaceIndices = [
            indexAttribute.getX(0),
            indexAttribute.getX(1),
            indexAttribute.getX(2)
          ];

          if (firstFaceIndices == undefined) return;
          // normal vector of the line
          const normalAttribute = line.geometry.attributes.normal;
          let normal = new Three.Vector3()
            .fromBufferAttribute(normalAttribute, 0)
            .clone();
          normal = normal
            .applyMatrix4(line.matrixWorld)
            .sub(new Three.Vector3(0, 0, 0).applyMatrix4(line.matrixWorld))
            .normalize();
          let vertices = layer.lines.get(data.line).vertices.toJS();
          let vertex1 = layer.vertices.get(vertices[0]);
          let vertex2 = layer.vertices.get(vertices[1]);
          let cX = (vertex1.x + vertex2.x) / 2;
          let cY = (vertex1.y + vertex2.y) / 2;
          let posVec = new Three.Vector3(cX, 150, -cY);
          posVec = posVec.add(planData.plan.position.clone());
          let cameraLine = camera.position.clone().sub(posVec);
          if (hole) {
            hole.traverse(child => {
              if (child.isMesh) {
                child.material.opacity = cameraLine.dot(normal) > 0 ? 1 : 0;
                child.material.transparent =
                  cameraLine.dot(normal) > 0 ? false : true;
                child.material.needsUpdate = true;
              }
            });
          }
        }
        // /////////////////////////////////////
      });

      self.renderer.render(scene3D, camera);
      self.renderingID = requestAnimationFrame(render);
    }

    //

    window.planData = planData;
    this.planData = planData;
    this.camera = camera;
    this.cameraControls = cameraControls;
    this.scene3D = scene3D;

    //

    prepareSnap(layer);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.renderingID);

    this.cameraControls.dispose();

    this.renderer.domElement.removeEventListener(
      'mousedown',
      this.mouseDownEvent
    );
    this.renderer.domElement.removeEventListener('mouseup', this.mouseUpEvent);

    disposeScene(this.scene3D);
    this.scene3D.remove(this.planData.plan);
    this.scene3D.remove(this.planData.grid);

    this.scene3D = null;
    this.planData = null;
    this.camera = null;
    this.cameraControls = null;
    this.renderer.renderLists.dispose();
  }

  componentWillReceiveProps(nextProps) {
    let { width, height } = nextProps;
    let actions = {
      areaActions: this.context.areaActions,
      holesActions: this.context.holesActions,
      itemsActions: this.context.itemsActions,
      sceneActions: this.context.sceneActions,
      linesActions: this.context.linesActions,
      projectActions: this.context.projectActions,
      catalog: this.context.catalog
    };

    let isLoadingCabinet = nextProps.state.scene.isLoadingCabinet;
    if (this.state.isLoadingCabinet !== isLoadingCabinet)
      this.setState({ isLoadingCabinet });

    this.width = width;
    this.height = height;

    let allLines;
    let allLineRects;
    let allItemRect;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    let data = nextProps.state.scene;
    let layer = data.getIn(['layers', data.selectedLayer]);
    const self = this;

    function implementBacksplash() {
      let allItems = GeometryUtils.getAllItemSpecified(
        nextProps.state.scene,
        actions.catalog,
        'Base'
      );
      let i,
        items = [];
      for (i = 0; i < allItems.others.length; i++)
        items.push(allItems.others[i]);
      if (allItems.cur) items.push(allItems.cur);

      for (i = 0; i < items.length; i++) {
        let calcRect = GeometryUtils.getCalcRectFromItem3D(items[i]);
        let visible = GeometryUtils.isSnappedLine(calcRect, allLineRects);
        actions.itemsActions.setBacksplashVisible(
          items[i].itemInfo.id,
          visible
        );

        createBacksplash(
          items[i],
          nextProps.state.scene.getIn([
            'layers',
            nextProps.state.scene.selectedLayer
          ]),
          planData,
          nextProps.state.scene
        );
      }
    }
    function implementWarningBox() {
      let holeItems = GeometryUtils.getHoleItems(layer);
      let i,
        items = [];
      for (i = 0; i < allItemRect.others.length; i++)
        items.push(allItemRect.others[i]);
      if (allItemRect.cur) items.push(allItemRect.cur);

      for (i = 0; i < items.length; i++)
        checkCabinetOverlap(
          { x: items[i].pos.x, y: items[i].pos.y },
          items[i],
          holeItems,
          planData
        );
    }

    let prepareSnapSpec = layer => {
      allLines = GeometryUtils.getAllLines(layer);
      allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
      allItemRect = GeometryUtils.getAllItemSpecified(
        this.props.state.scene,
        actions.catalog,
        ['Wall', 'Tall']
      );
    };

    let layer1 = this.props.state.scene.getIn(['layers', data.selectedLayer]);

    if (this.state.showflag) {
      prepareSnapSpec(layer);
      implementBacksplash();
      implementWarningBox();
      this.setState({ showflag: false });
    }
    if (
      nextProps.state.scene !== this.props.state.scene ||
      (nextProps.state.doorStyle &&
        nextProps.state.doorStyle.get('name') !==
          this.props.state.doorStyle.get('name'))
    ) {
      let changedValues = diff(this.props.state.scene, nextProps.state.scene);
      prepareSnapSpec(layer);
      if (
        nextProps.state.doorStyle &&
        nextProps.state.doorStyle.get('name') ===
          this.props.state.doorStyle.get('name')
      ) {
        self.setState({ isLoading: true });
        self.renderer.domElement.style.display = 'none';
      }
      if (nextProps.state.scene.showfg == true) {
        implementBacksplash();
        implementWarningBox();
      } else {
        deleteSpecifiedMeshObjects('TransformBox');
        //deleteSpecifiedMeshObjects("WarningBox");
      }

      var { promise } = updateScene(
        this.planData,
        nextProps.state.scene,
        this.props.state.scene,
        changedValues.toJS(),
        actions,
        this.context.catalog,
        nextProps.state.mode
      );
      promise.then(p1Value => {
        self.setState({ isLoading: false });
        self.renderer.domElement.style.display = 'block';
      });
    }
    this.renderer.setSize(width, height);
  }

  render() {
    const { isLoading, isLoadingCabinet } = this.state;
    if (isLoading) {
      this.renderer.domElement.style.display = 'none';
      return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <img
            style={{ animation: 'spin 2s linear infinite', marginTop: `22%` }}
            src={'/assets/img/loading_large.gif'}
            alt="img"
          />
        </div>
      );
    } else if (isLoadingCabinet) {
      this.renderer.domElement.style.pointerEvents = 'none';
      this.renderer.domElement.style.opacity = '0.4';
      return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <img
            style={{
              animation: 'spin 2s linear infinite',
              position: `absolute`,
              top: `50%`
            }}
            src={'/assets/img/loading_large.gif'}
            alt="img"
          />
        </div>
      );
    } else {
      this.renderer.domElement.style.pointerEvents = 'auto';
      this.renderer.domElement.style.opacity = '1';
      return React.createElement('div', { ref: 'canvasWrapper' });
    }
  }
}

Scene3DViewer.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  replaceCabinet: PropTypes.func.isRequired
};

Scene3DViewer.contextTypes = {
  areaActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  sceneActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object
};

import {
  TOGGLE_LOADING_CABINET,
  SELECT_ITEM,
  SELECT_TOOL_DRAWING_ITEM,
  UPDATE_DRAWING_ITEM,
  END_DRAWING_ITEM,
  BEGIN_DRAGGING_ITEM,
  BEGIN_DRAGGING_ITEM_3D,
  UPDATE_DRAGGING_ITEM,
  UPDATE_DRAGGING_ITEM_3DX,
  UPDATE_DRAGGING_ITEM_3DY,
  END_DRAGGING_ITEM,
  END_DRAGGING_ITEM_3D,
  UPDATE_DRAGGING_ITEM_CHANGED,
  UPDATE_ROTATING_ITEM_CHANGED,
  BEGIN_ROTATING_ITEM,
  BEGIN_ROTATING_ITEM_3D,
  UPDATE_ROTATING_ITEM,
  UPDATE_ROTATING,
  END_ROTATING_ITEM,
  END_ROTATING_ITEM_3D,
  REPLACE_SUBMODULE,
  ANIMATE_OBJECT,
  REMOVE_REPLACE_SUBMODULE,
  ITEM_MOVE_UP,
  SELECT_TOOL_DRAWING_ITEM_3D,
  SET_DOOR_STYLE,
  SET_HANDLE_MATERIAL,
  SET_INITIAL_DOOR_STYLE,
  SET_DISTANT,
  SET_DOOR_HANDLE,
  SET_WALL_COLOR,
  END_CREATING_CABINET,
  UPDATE_POPUP_OPEN,
  SET_MODELLING,
  SET_COUNTER_TOP,
  SET_BACKSPLASH,
  SET_BACKSPLASH_VISIBLE,
  SET_APPLIANCE_MATERIAL,
  DUPLICATE_SELECTED,
  EDIT_WIDTH,
  END_LOADING,
  STORE_DIST_ARRAY,
  SET_MOLDING,
  SET_LINEAR,
  VALIDATE_ITEM_POSTIONS,
  REPLACE_ITEM,
  SET_MOVE_STATUS,
  SET_ROTATE_STATUS
} from '../constants';

export function storeDistArray(layerID, itemID, distArray) {
  return {
    type: STORE_DIST_ARRAY,
    layerID,
    itemID,
    distArray
  };
}

export function validateItemPositions(layerID) {
  return {
    type: VALIDATE_ITEM_POSTIONS,
    layerID
  };
}

export function endLoading() {
  return {
    type: END_LOADING
  };
}

export function toggleLoadingCabinet() {
  return {
    type: TOGGLE_LOADING_CABINET
  };
}

export function editWidth(newWidth, layerID, itemID) {
  return {
    type: EDIT_WIDTH,
    newWidth,
    layerID,
    itemID
  };
}

export function duplicateSelected(currentObject) {
  return {
    type: DUPLICATE_SELECTED,
    currentObject
  };
}

export function replaceItem(selectedPos, currentObject, selectedObject) {
  return {
    type: REPLACE_ITEM,
    selectedPos,
    currentObject,
    selectedObject
  };
}

export function selectItem(layerID, itemID) {
  return {
    type: SELECT_ITEM,
    layerID,
    itemID
  };
}

export function endCreatingCabinet() {
  return {
    type: END_CREATING_CABINET
  };
}
export function updatePopupOpen(value) {
  return {
    type: UPDATE_POPUP_OPEN,
    value
  };
}

export function selectToolDrawingItem(sceneComponentType) {
  return {
    type: SELECT_TOOL_DRAWING_ITEM,
    sceneComponentType
  };
}

export function selectToolDrawingItem3D(sceneComponentType) {
  return {
    type: SELECT_TOOL_DRAWING_ITEM_3D,
    sceneComponentType
  };
}

export function updateDrawingItem(layerID, x, y) {
  return {
    type: UPDATE_DRAWING_ITEM,
    layerID,
    x,
    y
  };
}

export function endDrawingItem(layerID, x, y) {
  return {
    type: END_DRAWING_ITEM,
    layerID,
    x,
    y
  };
}

export function beginDraggingItem(layerID, itemID, x, y) {
  return {
    type: BEGIN_DRAGGING_ITEM,
    layerID,
    itemID,
    x,
    y
  };
}

export function beginDraggingItem3D(layerID, itemID, x, y) {
  return {
    type: BEGIN_DRAGGING_ITEM_3D,
    layerID,
    itemID,
    x,
    y
  };
}

export function updateDraggingItem(x, y) {
  return {
    type: UPDATE_DRAGGING_ITEM,
    x,
    y
  };
}

export function updateDraggingItemChanged(x, y, layerID, itemID) {
  return {
    type: UPDATE_DRAGGING_ITEM_CHANGED,
    x,
    y,
    layerID,
    itemID
  };
}

export function updateDraggingItem3DX(x) {
  return {
    type: UPDATE_DRAGGING_ITEM_3DX,
    x
  };
}
export function updateDraggingItem3DY(y) {
  return {
    type: UPDATE_DRAGGING_ITEM_3DY,
    y
  };
}
export function endDraggingItem(x, y) {
  return {
    type: END_DRAGGING_ITEM,
    x,
    y
  };
}

export function endDraggingItem3D() {
  return {
    type: END_DRAGGING_ITEM_3D
  };
}

export function beginRotatingItem(layerID, itemID, x, y) {
  return {
    type: BEGIN_ROTATING_ITEM,
    layerID,
    itemID,
    x,
    y
  };
}

export function beginRotatingItem3D(layerID, itemID, x, y, centerX, centerY) {
  return {
    type: BEGIN_ROTATING_ITEM_3D,
    layerID,
    itemID,
    x,
    y,
    centerX,
    centerY
  };
}

export function updateRotatingItem(x, y) {
  return {
    type: UPDATE_ROTATING_ITEM,
    x,
    y
  };
}

export function updateRotating(rotation) {
  return {
    type: UPDATE_ROTATING,
    rotation
  };
}

export function updateRotatingItemChanged(rotation, layerID, itemID) {
  return {
    type: UPDATE_ROTATING_ITEM_CHANGED,
    rotation,
    layerID,
    itemID
  };
}

export function endRotatingItem(x, y) {
  return {
    type: END_ROTATING_ITEM,
    x,
    y
  };
}

export function endRotatingItem3D(x, y) {
  return {
    type: END_ROTATING_ITEM_3D,
    x,
    y
  };
}

export function replaceSubmodule(url) {
  return {
    type: REPLACE_SUBMODULE,
    url: url
  };
}

export function animateObject(value) {
  return {
    type: ANIMATE_OBJECT,
    value: value
  };
}

export function removeReplacingSupport() {
  return {
    type: REMOVE_REPLACE_SUBMODULE
  };
}

export function updateItemsAltitude(layerID, itemID, value) {
  return {
    type: ITEM_MOVE_UP,
    layerID: layerID,
    itemID: itemID,
    value: value
  };
}
export function setDoorStyle(doorStyle, pathes, isAll) {
  return {
    type: SET_DOOR_STYLE,
    doorStyle: doorStyle,
    pathes: pathes,
    isAll: isAll
  };
}
export function setCounterTop(counterTop) {
  return {
    type: SET_COUNTER_TOP,
    counterTop
  };
}
export function setHandleMaterial(material) {
  return {
    type: SET_HANDLE_MATERIAL,
    material
  };
}
export function setDoorHandle(texture) {
  return {
    type: SET_DOOR_HANDLE,
    doorHandle: texture
  };
}
export function setWallColor(texture) {
  return {
    type: SET_WALL_COLOR,
    wallColor: texture
  };
}
export function setMolding(texture) {
  return {
    type: SET_MOLDING,
    molding: texture
  };
}
export function setLinear(linear) {
  return {
    type: SET_LINEAR,
    linear: linear
  };
}
export function setBacksplash(texture) {
  return {
    type: SET_BACKSPLASH,
    backsplash: texture
  };
}
export function setBacksplashVisible(itemID, value) {
  return {
    type: SET_BACKSPLASH_VISIBLE,
    backsplashVisible: value,
    itemID
  };
}
export function setApplianceMaterial(material) {
  return {
    type: SET_APPLIANCE_MATERIAL,
    material
  };
}
export function setModelling(model) {
  return {
    type: SET_MODELLING,
    model: model
  };
}
export function setInitialDoorStyle(doorStyle, oStyle) {
  return {
    type: SET_INITIAL_DOOR_STYLE,
    doorStyle: doorStyle,
    oStyle: oStyle
  };
}

export function setMoveStatus(status) {
  return {
    type: SET_MOVE_STATUS,
    status: status
  };
}

export function setRotateStatus(status) {
  return {
    type: SET_ROTATE_STATUS,
    status: status
  };
}

import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { element } from 'prop-types';
import {
  ReactSVGPanZoom,
  TOOL_NONE,
  TOOL_PAN,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,
  TOOL_AUTO,
  zoom
} from 'react-svg-pan-zoom';
import * as constants from '../../constants';
import State from './state';
import * as SharedStyle from '../../shared-style';
import { RulerX, RulerY } from './export';
import { FaFile } from 'react-icons/fa/index';
import { GoPin } from 'react-icons/go/index';
import { MODE_IDLE, MODE_2D_PAN, LINE_THICKNESS } from '../../constants';
import { GeometryUtils } from '../../utils/export';
import FormNumberInput from '../style/form-number-input';
import convert from 'convert-units';
import { Map } from 'immutable';

// variables
let pinFlag = false;
let sFlag = false; //for all object move
let sPoint = {}; //for all object move
let endPoint = {};
let current_sel_obj_id = null;
let allItemRect;
let allItemSnap;
let allLines;
let allLineRects;
let allLineSnap;
let allRect;
let holeRect;
let allArea;

// /////////
function mode2Tool(mode) {
  if (pinFlag) {
    return TOOL_NONE;
  }
  switch (mode) {
    case constants.MODE_2D_PAN:
      return TOOL_PAN;
    case constants.MODE_2D_ZOOM_IN:
      return TOOL_ZOOM_IN;
    case constants.MODE_2D_ZOOM_OUT:
      return TOOL_ZOOM_OUT;
    case constants.MODE_IDLE:
      return TOOL_AUTO;
    default:
      return TOOL_NONE;
  }
}

function mode2PointerEvents(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
      return { pointerEvents: 'none' };

    default:
      return {};
  }
}

function mode2Cursor(mode) {
  switch (mode) {
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_ITEM:
      return { cursor: 'move' };

    case constants.MODE_ROTATING_ITEM:
      return { cursor: 'ew-resize' };

    case constants.MODE_WAITING_DRAWING_LINE:
    case constants.MODE_DRAWING_LINE:
      return { cursor: 'crosshair' };
    default:
      return { cursor: 'default' };
  }
}

function mode2DetectAutopan(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
      return true;

    default:
      return false;
  }
}

function extractElementData(node) {
  while (
    !node.attributes.getNamedItem('data-element-root') &&
    node.tagName !== 'svg'
  ) {
    node = node.parentNode;
  }
  if (node.tagName === 'svg') return null;

  return {
    length: node.attributes.getNamedItem('data-length')
      ? node.attributes.getNamedItem('data-length').value
      : 0,
    part: node.attributes.getNamedItem('data-part')
      ? node.attributes.getNamedItem('data-part').value
      : undefined,
    layer: node.attributes.getNamedItem('data-layer').value,
    prototype: node.attributes.getNamedItem('data-prototype').value,
    selected: node.attributes.getNamedItem('data-selected').value === 'true',
    id: node.attributes.getNamedItem('data-id').value,
    direct: node.attributes.getNamedItem('data-direct')
      ? node.attributes.getNamedItem('data-direct').value
      : 0
  };
}

export default function Viewer2D(
  { state, width, height, setToolbar, replaceCabinet },
  {
    viewer2DActions,
    linesActions,
    holesActions,
    verticesActions,
    itemsActions,
    areaActions,
    projectActions,
    catalog
  }
) {
  const [rulerEdit, setRulerEdit] = useState(null);
  const [rulerEditID, setRulerEditID] = useState(null);
  const Viewer = useRef(null);

  let { viewer2D, mode, scene } = state;

  useEffect(() => {
    // move viewer point to center
    let selectedLayer = state.scene.layers.get(state.scene.selectedLayer);
    const vertices = selectedLayer.vertices;

    if (vertices.isEmpty()) {
      return;
    }

    let bottomX = 0,
      bottomY = 100000,
      topX = 100000,
      topY = 0;

    vertices.forEach(vertex => {
      if (bottomX < vertex.x) bottomX = vertex.x;
      if (bottomY > vertex.y) bottomY = vertex.y;
      if (topX > vertex.x) topX = vertex.x;
      if (topY < vertex.y) topY = vertex.y;
    });

    let moveX = topX + (bottomX - topX) / 2;
    let moveY = 2000 - (bottomY + (topY - bottomY) / 2);

    Viewer.current.setPointOnViewerCenter(moveX, moveY, viewer2D.get('a'));
  }, []);

  let layerID = scene.selectedLayer;
  let wall_thickness = LINE_THICKNESS / 2;

  /** Create Lines from State */
  let createLineGeometry = scene => {
    let layer = scene.getIn(['layers', layerID]);
    let lines = layer.lines;

    lines.toArray().forEach(line => {
      let vertex0Id = line.vertices.get(0);
      let vertex1Id = line.vertices.get(1);
      let { x: x0, y: y0 } = layer.vertices.get(vertex0Id);
      let { x: x1, y: y1 } = layer.vertices.get(vertex1Id);
      if (x0 == x1 && y0 == y1) return;

      let allLines = layer.lines.toArray();
      let relatedLineArray = [];
      let relatedLine0 = allLines.filter(allLine => {
        return (
          allLine.vertices.toArray().includes(vertex0Id) &&
          line.id != allLine.id
        );
      })[0];
      let relatedLine1 = allLines.filter(allLine => {
        return (
          allLine.vertices.toArray().includes(vertex1Id) &&
          line.id != allLine.id
        );
      })[0];

      let normalVertice = GeometryUtils.getNormaline(x0, y0, x1, y1);
      let rx0 = x0 + normalVertice.x * wall_thickness;
      let ry0 = y0 + normalVertice.y * wall_thickness;
      let rx1 = x1 + normalVertice.x * wall_thickness;
      let ry1 = y1 + normalVertice.y * wall_thickness;

      let intersection = [];

      relatedLine0
        ? relatedLineArray.push({ index: 0, line: relatedLine0 })
        : intersection.push({ index: 0, point: { x: rx0, y: ry0 } });
      relatedLine1
        ? relatedLineArray.push({ index: 1, line: relatedLine1 })
        : intersection.push({ index: 1, point: { x: rx1, y: ry1 } });

      let originalLineFunction = GeometryUtils.linePassingThroughTwoPoints(
        x0,
        y0,
        x1,
        y1
      );
      originalLineFunction.c -=
        Math.sqrt(
          originalLineFunction.b * originalLineFunction.b +
            originalLineFunction.a * originalLineFunction.a
        ) * wall_thickness;

      relatedLineArray.forEach(lineInfo => {
        let vertexId, originx, originy;
        let { index, line: relatedLine } = lineInfo;
        index == 0
          ? ((vertexId = vertex0Id), (originx = x0), (originy = y0))
          : ((vertexId = vertex1Id), (originx = x1), (originy = y1));

        let relatedVertices = relatedLine.vertices.toArray();
        let relatedOtherVertexID =
          relatedVertices[0] == vertexId
            ? relatedVertices[1]
            : relatedVertices[0];
        let relatedVertex = layer.getIn(['vertices', relatedOtherVertexID]);
        if (originx == relatedVertex.x && originy == relatedVertex.y) return;

        let relatedLineFunction = GeometryUtils.linePassingThroughTwoPoints(
          originx,
          originy,
          relatedVertex.x,
          relatedVertex.y
        );
        let delta = GeometryUtils.distancePointFromLine(
          relatedLineFunction.a,
          relatedLineFunction.b,
          relatedLineFunction.c,
          index == 0 ? x1 : x0,
          index == 0 ? y1 : y0
        );
        if (delta < 0.01) {
          // if the directions of the current line and related line, then no need to calculate the inteersection point
          let rx = originx + normalVertice.x * wall_thickness;
          let ry = originy + normalVertice.y * wall_thickness;
          intersection.push({ index, point: { x: rx, y: ry } });
        } else {
          relatedLineFunction.c -=
            (relatedVertices[0] == vertexId ? 1 : -1) *
            Math.sqrt(
              relatedLineFunction.b * relatedLineFunction.b +
                relatedLineFunction.a * relatedLineFunction.a
            ) *
            wall_thickness;
          let point = GeometryUtils.twoLinesIntersection(
            originalLineFunction.a,
            originalLineFunction.b,
            originalLineFunction.c,
            relatedLineFunction.a,
            relatedLineFunction.b,
            relatedLineFunction.c
          );
          point && intersection.push({ index, point });
        }
      });
      linesActions.setRelatedLine(layer.id, line.id, intersection);
    });
  };

  let mapCursorPosition = ({ x, y }) => {
    return { x, y: -y + scene.height };
  };
  // let pinBackColor = pinFlag ? "#4488ff" : "black";
  // let topRightImg = state.mode == MODE_IDLE || state.mode == MODE_2D_PAN ? "/assets/img/svg/topbar/edit_inactive.svg" : "/assets/img/svg/topbar/edit_active.svg";
  // let topBtnAction = state.mode == MODE_IDLE || state.mode == MODE_2D_PAN ? () => linesActions.selectToolDrawingLine("wall") : () => projectActions.setMode(MODE_IDLE);

  let prepareSnap = () => {
    let layerID = scene.selectedLayer;
    let layer = scene.layers.get(layerID);

    allLines = GeometryUtils.getAllLines(layer);
    allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
    allItemRect = GeometryUtils.getAllItems(scene, catalog, allLineRects);
    allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
    allLineSnap = GeometryUtils.getAllLineSnap(allLineRects, allItemRect.cur);
    allRect = allItemRect.others.concat(allLineRects);
    allItemSnap = GeometryUtils.validateSnaps(allItemSnap, allRect);
    allLineSnap = GeometryUtils.validateSnaps(allLineSnap, allRect);
    allArea = GeometryUtils.getAllArea(layer);
  };

  let updateRulerDistAttribute = (elementData, value) => {
    let newVal = value.get('length');
    let oldVal = elementData.length;
    let direct = elementData.direct;

    const thisItem = scene
      .getIn(['layers', elementData.layer, 'items', elementData.id])
      .toJS();

    let oldX = thisItem.x;
    let oldY = thisItem.y;
    let oldRotRad = (thisItem.rotation / 180) * Math.PI;

    let rotRad = oldRotRad + (direct / 180) * Math.PI;
    let delta = oldVal - newVal;

    let newX = oldX + delta * Math.cos(rotRad);
    let newY = oldY + delta * Math.sin(rotRad);

    itemsActions.updateDraggingItemChanged(
      newX,
      newY,
      elementData.layer,
      elementData.id
    );

    setRulerEdit(null);
  };

  let updateangleChangeAttribute = (elementData, value) => {
    let verArr = elementData.id.split(',');
    let oldAngle = elementData.length;
    let newAngle = value.getIn(['_length']);
    let cenId = verArr[0];
    let verId = verArr[1];
    let x0 = state.scene.getIn(['layers', layerID, 'vertices', verArr[0], 'x']);
    let y0 = state.scene.getIn(['layers', layerID, 'vertices', verArr[0], 'y']);
    let x1 = state.scene.getIn(['layers', layerID, 'vertices', verArr[1], 'x']);
    let y1 = state.scene.getIn(['layers', layerID, 'vertices', verArr[1], 'y']);
    let x, y, delta;

    delta = newAngle - oldAngle;

    if (verArr[2] === 'true') {
      delta = -delta;
    }

    x =
      (x1 - x0) * Math.cos((delta * Math.PI) / 180) -
      (y1 - y0) * Math.sin((delta * Math.PI) / 180) +
      x0;
    y =
      (x1 - x0) * Math.sin((delta * Math.PI) / 180) +
      (y1 - y0) * Math.cos((delta * Math.PI) / 180) +
      y0;

    setRulerEdit(null);
    verticesActions.beginDraggingVertex(
      elementData.layer,
      verArr[1],
      x,
      y,
      state.snapMask
    );
    verticesActions.updateDraggingVertex(x, y, state.snapMask);
    verticesActions.endDraggingVertex(x, y, state.snapMask);
  };

  let updateRulerAttribute = (elementData, value) => {
    const selectedLine = scene
      .getIn(['layers', elementData.layer, 'lines', elementData.id])
      .toJS();

    let v_0 = scene.getIn([
      'layers',
      elementData.layer,
      'vertices',
      selectedLine.vertices[0]
    ]);

    let v_1 = scene.getIn([
      'layers',
      elementData.layer,
      'vertices',
      selectedLine.vertices[1]
    ]);

    let [v_a, v_b] = GeometryUtils.orderVertices([v_0, v_1]);
    let v_b_new = GeometryUtils.extendLine(
      v_a.x,
      v_a.y,
      v_b.x,
      v_b.y,
      value.get('length'),
      2
    );

    // Blocked function that as wall changed, opposite wall should changed together.
    // let layer = scene.getIn(['layers', elementData.layer]);
    // let allLines = layer.lines.toArray()
    // let relatedLine = allLines.filter(line => { return line.vertices.toArray().includes(v_b.id) && line.id != selectedLine.id })[0];
    // let relatedVertexID = relatedLine && (relatedLine.vertices.toArray()[0] == v_b.id ? relatedLine.vertices.toArray()[1] : relatedLine.vertices.toArray()[0]);
    // let v_d = relatedVertexID && layer.getIn(['vertices', relatedVertexID]);
    // let delta = {
    //   x : v_b_new.x - v_b.x,
    //   y : v_b_new.y - v_b.y
    // };
    // let newPosition = {
    //   x : v_d.x + delta.x,
    //   y : v_d.y + delta.y
    // }

    var attributesData = {
      // (v_0 === v_a ? 'vertexTwo' : 'vertexOne') : v_b.merge(v_b_new)),
      lineLength: value
    };
    if (v_0 === v_a) {
      attributesData = {
        ...attributesData,
        vertexTwo: v_b.merge(v_b_new),
        vertexOne: v_a
      };
    } else {
      attributesData = {
        ...attributesData,
        vertexOne: v_b.merge(v_b_new),
        vertexTwo: v_a
      };
    }
    let attributesFormData = new Map({
      ...attributesData
    });
    setRulerEdit(null);
    projectActions.setLinesAttributes(attributesFormData, true, {
      layerID: elementData.layer,
      lineID: elementData.id
    });

    // Blocked function that as wall changed, opposite wall should changed together.
    // verticesActions.beginDraggingVertex(elementData.layer, relatedVertexID, newPosition.x, newPosition.y, state.snapMask);
    // verticesActions.updateDraggingVertex(newPosition.x, newPosition.y, state.snapMask);
    // verticesActions.endDraggingVertex(newPosition.x, newPosition.y, state.snapMask);
  };

  let updateTwoHoleRulerAttribute = (elementData, value) => {
    let elementIds = elementData.id.split(',');
    elementData.id = elementIds[1];
    elementData.length /= 2;
    updateLeftHoleRulerAttribute(elementData, value);
    elementData.id = elementIds[0];
    updateRightHoleRulerAttribute(elementData, value);
  };

  let updateLeftHoleRulerAttribute = (elementData, value) => {
    let newVal = value.get('length');
    let oldVal = elementData.length;
    const thisItem = scene
      .getIn(['layers', elementData.layer, 'holes', elementData.id])
      .toJS();
    let oldX = thisItem.x;
    let oldY = thisItem.y;
    let oldRotRad = thisItem.rotation;
    let delta = oldVal - newVal;
    let newX = oldX - delta * Math.cos(oldRotRad);
    let newY = oldY - delta * Math.sin(oldRotRad);
    holesActions.beginDraggingHole(
      elementData.layer,
      elementData.id,
      newX,
      newY
    );
    holesActions.updateDraggingHoleChanged(
      newX,
      newY,
      elementData.layer,
      elementData.id
    );
    holesActions.endDraggingHole(newX, newY);
    setRulerEdit(null);
  };

  let updateHoleRulerAttribute = (elementData, value) => {
    let newVal = value.get('length');
    const thisItem = scene
      .getIn(['layers', elementData.layer, 'holes', elementData.id])
      .toJS();
    let oldX = thisItem.x;
    let oldY = thisItem.y;
    holesActions.beginDraggingHole(
      elementData.layer,
      elementData.id,
      oldX,
      oldY
    );
    holesActions.updateDraggingHoleRulerChanged(
      newVal,
      elementData.layer,
      elementData.id
    );
    holesActions.endDraggingHole(oldX, oldY);
    setRulerEdit(null);
  };

  let updateRightHoleRulerAttribute = (elementData, value) => {
    let newVal = value.get('length');
    let oldVal = elementData.length;
    const thisItem = scene
      .getIn(['layers', elementData.layer, 'holes', elementData.id])
      .toJS();
    let oldX = thisItem.x;
    let oldY = thisItem.y;
    let oldRotRad = thisItem.rotation;
    let delta = oldVal - newVal;
    let newX = oldX + delta * Math.cos(oldRotRad);
    let newY = oldY + delta * Math.sin(oldRotRad);
    holesActions.beginDraggingHole(
      elementData.layer,
      elementData.id,
      newX,
      newY
    );
    holesActions.updateDraggingHoleChanged(
      newX,
      newY,
      elementData.layer,
      elementData.id
    );
    holesActions.endDraggingHole(newX, newY);
    setRulerEdit(null);
  };

  let onMouseMove = viewerEvent => {
    createLineGeometry(scene);

    //workaround that allow imageful component to work

    let evt = new Event('mousemove-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    let { x, y } = mapCursorPosition(viewerEvent);
    projectActions.updateMouseCoord({ x, y });
    if (sFlag) {
      let differs = { x: x - sPoint.x, y: y - sPoint.y };
      projectActions.selectAll(differs);
    } else
      switch (mode) {
        case constants.MODE_DRAWING_LINE:
          // Blocked 90 degree snap.
          // let prevVertexID = state.getIn(['scene', 'layers', layerID, 'selected', 'vertices']).toJS()[0];
          // let prevVertex = state.getIn(['scene', 'layers', layerID, 'vertices', prevVertexID]);
          // let dx = Math.abs(x - prevVertex.x);
          // let dy = Math.abs(y - prevVertex.y);
          // if (dx > dy) y = prevVertex.y
          // else x = prevVertex.x;
          linesActions.updateDrawingLine(x, y, state.snapMask);
          break;

        case constants.MODE_DRAWING_HOLE:
          holesActions.updateDrawingHole(layerID, x, y);
          break;

        case constants.MODE_DRAWING_ITEM:
          let layer = scene.layers.get(layerID);
          let flag = false;
          layer.items.some(item => {
            if (item.selected) {
              item.counterTop.uri = layer.counterTop.uri;
              current_sel_obj_id = item.id;
              flag = true;
            }
          });
          if (current_sel_obj_id === null || !flag) {
            itemsActions.updateDrawingItem(layerID, x, y);
            endPoint.x = x;
            endPoint.y = y;
          } else {
            prepareSnap();
            var { nx, ny, rot, rotRad } = GeometryUtils.calcSnap(
              allItemRect,
              allItemSnap,
              allLineRects,
              allLineSnap,
              allRect,
              x,
              y,
              allArea
            );
            let val = {
              pos: { x, y },
              rotRad,
              size: allItemRect.cur && allItemRect.cur.size,
              layoutpos: allItemRect.cur && allItemRect.cur.layoutpos,
              is_corner: allItemRect.cur && allItemRect.cur.is_corner
            };
            let { isSect, snap } = GeometryUtils.getAllHoleRect(scene, val);
            if (snap !== null && snap !== [] && isSect) {
              if (snap.length == 1) val.pos = { x: snap[0].x, y: snap[0].y };
              else {
                if (
                  (snap[0].x - x) * (snap[0].x - x) +
                    (snap[0].y - y) * (snap[0].y - y) <
                  (snap[1].x - x) * (snap[1].x - x) +
                    (snap[1].y - y) * (snap[1].y - y)
                )
                  val.pos = { x: snap[0].x, y: snap[0].y };
                else val.pos = { x: snap[1].x, y: snap[1].y };
              }
              let interSect = GeometryUtils.validInterSect(
                allItemRect.others,
                val
              );
              if (interSect) {
                nx = val.pos.x;
                ny = val.pos.y;
              }
            }

            val.pos = { x: nx, y: ny };
            let isrectSect = GeometryUtils.validInterSect(
              allItemRect.others,
              val
            );
            if (isrectSect && isSect) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
            if (
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Cook Top')) ||
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('cabinet'))
            ) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
            if (
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Hood')) ||
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Range')) ||
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Cook Top'))
            ) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
          }
          break;

        case constants.MODE_DRAGGING_HOLE:
          holesActions.updateDraggingHole(x, y);
          break;

        case constants.MODE_DRAGGING_LINE:
          linesActions.updateDraggingLine(x, y, state.snapMask);
          break;

        case constants.MODE_DRAGGING_VERTEX:
          verticesActions.updateDraggingVertex(x, y, state.snapMask);
          break;

        case constants.MODE_DRAGGING_ITEM:
          prepareSnap();
          var { nx, ny, rot, rotRad } = GeometryUtils.calcSnap(
            allItemRect,
            allItemSnap,
            allLineRects,
            allLineSnap,
            allRect,
            x,
            y,
            allArea
          );
          let val = {
            pos: { x, y },
            rotRad,
            size: allItemRect.cur && allItemRect.cur.size,
            layoutpos: allItemRect.cur && allItemRect.cur.layoutpos,
            is_corner: allItemRect.cur && allItemRect.cur.is_corner
          };
          let { isSect, snap } = GeometryUtils.getAllHoleRect(scene, val);
          if (snap !== null && snap !== [] && isSect) {
            if (snap.length == 1) val.pos = { x: snap[0].x, y: snap[0].y };
            else if (snap.length == 2) {
              if (
                (snap[0].x - x) * (snap[0].x - x) +
                  (snap[0].y - y) * (snap[0].y - y) <
                (snap[1].x - x) * (snap[1].x - x) +
                  (snap[1].y - y) * (snap[1].y - y)
              )
                val.pos = { x: snap[0].x, y: snap[0].y };
              else val.pos = { x: snap[1].x, y: snap[1].y };
            }
            let interSect = GeometryUtils.validInterSect(
              allItemRect.others,
              val
            );
            if (interSect) {
              nx = val.pos.x;
              ny = val.pos.y;
            }
          }

          val.pos = { x: nx, y: ny };
          let isrectSect = GeometryUtils.validInterSect(
            allItemRect.others,
            val
          );
          if (isrectSect && isSect) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          if (
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Cook Top')) ||
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Cabinet'))
          ) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          if (
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Hood')) ||
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Range')) ||
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Cook Top'))
          ) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          break;

        case constants.MODE_ROTATING_ITEM:
          itemsActions.updateRotatingItem(x, y);
          break;
      }

    viewerEvent.originalEvent.stopPropagation();
  };

  let onMouseDown = viewerEvent => {
    let event = viewerEvent.originalEvent;
    //workaround that allow imageful component to work
    let evt = new Event('mousedown-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    createLineGeometry(scene);
    let { x, y } = mapCursorPosition(viewerEvent);
    let layer = state.scene.getIn(['layers', state.scene.selectedLayer]);
    let sCount =
      layer.selected.areas.size +
      layer.selected.holes.size +
      layer.selected.items.size +
      layer.selected.lines.size;
    if (mode === constants.MODE_DRAWING_LINE) {
      if (event.nativeEvent.which === 3) {
        projectActions.rollback();
        event.stopPropagation();
        return;
      }
    }
    if (mode === constants.MODE_IDLE) {
      let elementData = extractElementData(event.target);
      if (!elementData) return;

      if (sCount < 2)
        switch (elementData.prototype) {
          case 'lines':
            if (elementData.selected) {
              if (elementData.part === 'remove') break;
              linesActions.beginDraggingLine(
                elementData.layer,
                elementData.id,
                x,
                y,
                state.snapMask
              );
            }
            break;
          case 'vertices':
            verticesActions.beginDraggingVertex(
              elementData.layer,
              elementData.id,
              x,
              y,
              state.snapMask
            );
            break;

          case 'items':
            current_sel_obj_id = elementData.id;
            if (elementData.part === 'rotation-anchor')
              itemsActions.beginRotatingItem(
                elementData.layer,
                elementData.id,
                x,
                y
              );
            else if (elementData.part === 'remove') break;
            else if (elementData.part === 'duplicate') break;
            else if (elementData.part === 'warning_edit') break;
            else {
              // closes the setting dialog
              document.getElementById('setting_dialog').style.display = 'none';

              itemsActions.selectItem(elementData.layer, elementData.id);
              // projectActions.setMode(constants.MODE_DRAGGING_ITEM);
              itemsActions.beginDraggingItem(
                elementData.layer,
                elementData.id,
                x,
                y
              );
              replaceCabinet(false);
            }
            break;

          case 'holes':
            if (elementData.selected)
              holesActions.beginDraggingHole(
                elementData.layer,
                elementData.id,
                x,
                y
              );
            break;

          default:
            break;
        }
      else {
        sPoint.x = x;
        sPoint.y = y;
        sFlag = true;
      }
    }
    event.stopPropagation();
  };

  let onMouseUp = viewerEvent => {
    //set move all flag false
    sFlag = false;
    // //////////////////////
    // setRulerEdit(null);
    let event = viewerEvent.originalEvent;
    let bbox = event.target.getBoundingClientRect();
    if (!mode.includes('ING')) {
      setToolbar('');
    }
    // bbox.width = event.target.getBBox().width;
    // bbox.height = event.target.getBBox().height;
    if (event.target.tagName === 'rect') {
      if (event.target.id) {
        setRulerEditID(event.target.id);
      }
    }
    setRulerEdit(null);
    let evt = new Event('mouseup-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    createLineGeometry(scene);
    let { x, y } = mapCursorPosition(viewerEvent);
    switch (mode) {
      case constants.MODE_IDLE:
        let elementData = extractElementData(event.target);
        // if (elementData && elementData.selected) return;
        switch (elementData ? elementData.prototype : 'none') {
          case 'areas':
            areaActions.selectArea(elementData.layer, elementData.id);
            break;

          case 'lines':
            if (elementData.part === 'remove') {
              projectActions.remove();
              break;
            } else {
              linesActions.selectLine(elementData.layer, elementData.id);
              break;
            }

          case 'holes':
            holesActions.selectHole(elementData.layer, elementData.id);
            break;

          case 'items':
            if (elementData.part === 'duplicate') {
              let currentObject = state.getIn([
                'scene',
                'layers',
                layerID,
                'items',
                elementData.id
              ]);
              itemsActions.duplicateSelected(currentObject);
              break;
            } else if (elementData.part === 'remove') {
              projectActions.remove();
              break;
            } else if (elementData.part === 'warning_edit') {
              // closes the setting dialog
              document.getElementById('setting_dialog').style.display = 'none';

              itemsActions.selectItem(elementData.layer, elementData.id);
              replaceCabinet(true);
              break;
            } else {
              projectActions.unselectAll();
              break;
            }
          case 'rulerDist':
            let _length1 = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceText1 = `${_length1.toFixed(2)}`;
            const numberInput1 = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceText1}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRulerDistAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput1);
            projectActions.unselectAll();
            break;
          case 'ruler':
            let _length = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceText = `${_length.toFixed(2)}`;
            const numberInput = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceText}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput);
            projectActions.unselectAll();
            break;
          case 'twoHoleRuler':
            let _lengthTwoHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextTwoHoleRuler = `${_lengthTwoHoleRuler.toFixed(2)}`;
            const numberInputTwoHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    padding: 'auto',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextTwoHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value / 2)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value / 2,
                      _unit: scene.rulerUnit
                    });
                    updateTwoHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputTwoHoleRuler);
            projectActions.unselectAll();
            break;
          case 'leftHoleRuler':
            let _lengthLeftHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextLeftHoleRuler = `${_lengthLeftHoleRuler.toFixed(
              2
            )}`;
            const numberInputLeftHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextLeftHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateLeftHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputLeftHoleRuler);
            projectActions.unselectAll();
            break;
          case 'rulerHole':
            let _lengthRulerHole = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextRulerHole = `${_lengthRulerHole.toFixed(2)}`;
            const numberInputRulerHole = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextRulerHole}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputRulerHole);
            projectActions.unselectAll();
            break;
          case 'rightHoleRuler':
            let _lengthRightHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextRightHoleRuler = `${_lengthRightHoleRuler.toFixed(
              2
            )}`;
            const numberInputRightHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextRightHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRightHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputRightHoleRuler);
            projectActions.unselectAll();
            break;
          case 'angleChange':
            let _length2 = elementData.length;
            const numberInput2 = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 50,
                    height: 50
                  }}
                  value={_length2}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateangleChangeAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput2);
            projectActions.unselectAll();
            break;
          case 'none':
            projectActions.unselectAll();
            break;
        }
        break;

      case constants.MODE_WAITING_DRAWING_LINE:
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_LINE:
        // Blocked 90 degree snap.
        // let prevVertexID = state.getIn(['scene', 'layers', layerID, 'selected', 'vertices']).toJS()[0];
        // let prevVertex = state.getIn(['scene', 'layers', layerID, 'vertices', prevVertexID]);
        // let dx = Math.abs(x - prevVertex.x);
        // let dy = Math.abs(y - prevVertex.y);
        // if (dx > dy) y = prevVertex.y
        // else x = prevVertex.x;
        linesActions.endDrawingLine(x, y, state.snapMask);
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.endDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.endDrawingItem(layerID, endPoint.x, endPoint.y);
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.endDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.endDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        projectActions.setMode(MODE_IDLE);
        break;

      case constants.MODE_DRAGGING_HOLE:
        holesActions.endDraggingHole(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.endRotatingItem(x, y);
        break;
    }

    event.stopPropagation();
  };

  let onChangeValue = value => {
    if (sFlag) return;
    let _zoomValue = parseInt((value.a - 0.5) / constants.ZOOM_VARIABLE);
    if (_zoomValue > 404) return;
    if (_zoomValue < 0 || Number.isNaN(_zoomValue)) return;

    if (
      (rulerEdit !== null && value.startX && value.startY) ||
      value.lastAction === 'zoom'
    ) {
      let _rulerEdit = document.getElementById('ruler_numberInput'),
        _rect;
      if (rulerEditID !== null) {
        _rect = document.getElementById(rulerEditID);
      }
      let bbox;
      if (_rect && _rulerEdit) {
        bbox = _rect.getBoundingClientRect();
        // bbox.width = _rect.getBBox().width;
        // bbox.height = _rect.getBBox().height;
        _rulerEdit.style.left = `${bbox.left - (150 - bbox.width) / 2}px`;
        _rulerEdit.style.top = `${bbox.top - (50 - bbox.height) / 2}px`;
      }
    }

    if (
      value.e <= 10 &&
      value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth &&
      value.f <= 80 &&
      value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight
    ) {
      /*let val = Object.assign({}, value);
      if (refresh === 2) {
        val.a += 0.4; val.d += 0.4;
        val.e -= (val.SVGWidth * val.a - val.SVGWidth * (val.a - 0.4)) / 2;
        val.f -= (val.SVGHeight * val.d - val.SVGHeight * (val.d - 0.4)) / 2;
        console.log('----', val)
      }*/
      projectActions.updateZoomScale(value.a);
      return viewer2DActions.updateCameraView(value);
    }
  };

  let onChangeTool = tool => {
    switch (tool) {
      case TOOL_NONE:
        projectActions.selectToolEdit();
        break;

      case TOOL_PAN:
        viewer2DActions.selectToolPan();
        break;

      case TOOL_ZOOM_IN:
        viewer2DActions.selectToolZoomIn();
        break;

      case TOOL_ZOOM_OUT:
        viewer2DActions.selectToolZoomOut();
        break;
    }
  };

  let onZoom = value => {
    // var nextValue = viewer2D.toJS();
    // nextValue = zoom(nextValue, 0, 0, value.a / nextValue.a)
    // projectActions.updateZoomScale(nextValue.a);
    // return viewer2DActions.updateCameraView(nextValue)
  };

  let { e, f, SVGWidth, SVGHeight } = state.get('viewer2D').toJS();

  let rulerSize = 0; //px
  let rulerUnitPixelSize = 100;
  let rulerBgColor = SharedStyle.PRIMARY_COLOR.main;
  let rulerFnColor = SharedStyle.COLORS.white;
  let rulerMkColor = SharedStyle.SECONDARY_COLOR.main;
  let sceneWidth = SVGWidth || state.getIn(['scene', 'width']);
  let sceneHeight = SVGHeight || state.getIn(['scene', 'height']);
  let sceneZoom = state.zoom || 1;
  let rulerXElements = Math.ceil(sceneWidth / rulerUnitPixelSize) + 1;
  let rulerYElements = Math.ceil(sceneHeight / rulerUnitPixelSize) + 1;
  return (
    <div>
      {rulerEdit}
      <div
        style={{
          margin: 0,
          padding: 0,
          display: 'grid',
          gridRowGap: '0',
          gridColumnGap: '0',
          gridTemplateColumns: `${rulerSize}px ${width - rulerSize}px`,
          gridTemplateRows: `${rulerSize}px ${height - rulerSize}px`,
          position: 'relative'
        }}
      >
        <div
          style={{ gridColumn: 1, gridRow: 1, backgroundColor: rulerBgColor }}
        ></div>
        <div
          style={{
            gridRow: 1,
            gridColumn: 2,
            position: 'relative',
            overflow: 'hidden'
          }}
          id="rulerX"
        >
          {sceneWidth ? (
            <RulerX
              unitPixelSize={rulerUnitPixelSize}
              zoom={sceneZoom}
              mouseX={state.mouse.get('x')}
              width={width - rulerSize}
              zeroLeftPosition={e || 0}
              backgroundColor={rulerBgColor}
              fontColor={rulerFnColor}
              markerColor={rulerMkColor}
              positiveUnitsNumber={rulerXElements}
              negativeUnitsNumber={0}
            />
          ) : null}
        </div>
        <div
          style={{
            gridColumn: 1,
            gridRow: 2,
            position: 'relative',
            overflow: 'hidden'
          }}
          id="rulerY"
        >
          {sceneHeight ? (
            <RulerY
              unitPixelSize={rulerUnitPixelSize}
              zoom={sceneZoom}
              mouseY={state.mouse.get('y')}
              height={height - rulerSize}
              zeroTopPosition={sceneHeight * sceneZoom + f || 0}
              backgroundColor={rulerBgColor}
              fontColor={rulerFnColor}
              markerColor={rulerMkColor}
              positiveUnitsNumber={rulerYElements}
              negativeUnitsNumber={0}
            />
          ) : null}
        </div>
        <ReactSVGPanZoom
          style={{ gridColumn: 2, gridRow: 2 }}
          width={width - rulerSize}
          height={height - rulerSize}
          value={viewer2D.isEmpty() ? null : viewer2D.toJS()}
          onChangeValue={onChangeValue}
          tool={mode2Tool(mode)}
          onChangeTool={onChangeTool}
          detectAutoPan={mode2DetectAutopan(mode)}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          miniaturePosition="none"
          toolbarPosition="none"
          detectPinchGesture={false}
          disableDoubleClickZoomWithToolAuto={true}
          ref={Viewer}
        >
          <svg width={scene.width} height={scene.height}>
            <defs>
              <pattern
                id="diagonalFill"
                patternUnits="userSpaceOnUse"
                width="4"
                height="4"
                fill="#FFF"
              >
                <rect x="0" y="0" width="4" height="4" fill="#FFF" />
                <path
                  d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                  style={{ stroke: '#8E9BA2', strokeWidth: 1 }}
                />
              </pattern>
            </defs>
            <g
              style={Object.assign(mode2Cursor(mode), mode2PointerEvents(mode))}
            >
              <State
                state={state}
                catalog={catalog}
                viewer2DActions={viewer2DActions}
              />
            </g>
          </svg>
        </ReactSVGPanZoom>
      </div>
    </div>
  );
}

Viewer2D.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

Viewer2D.contextTypes = {
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  verticesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};

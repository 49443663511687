import React from 'react';
import * as S from './styles';
import * as PS from '../styles';
import './styles.css';
import { FaTimes } from 'react-icons/fa';
import { emailValidation } from '../../../../utils/email-validator';
import {
  DEFAULT_FONT_FAMILY,
  PROJECT_NAME_LENGTH_LIMIT,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_2
} from '../../../../constants';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { StyledAlert } from '../../main/myComponents';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import SnackBar from '../../../atoms/Snackbar/index';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
    width: 428px;
  }
`;

let saveAlert = '';

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DesignerAssistPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailfocusOn: false,
      phone: '',
      phonefocusOn: false,
      isCheck: false,
      notes: '',
      notesFocusOn: false,
      firstName: '',
      firstNamefocusOn: false,
      lastNamefocusOn: false,
      lastName: '',
      first: false,
      second: false,
      projectName: '',
      isSnackBarOpen: false,
      snackBarMessage: '',
      question1: false,
      question2: false,
      question3: false,
      question4: false,
      error: false,
      phoneCall: true,
      phoneSMS: false
    };
  }
  componentWillReceiveProps(newProps) {
    const email = sessionStorage.getItem('email');
    if (email !== null && newProps.visible !== this.props.visible)
      this.setState({ email });
    const phone = sessionStorage.getItem('phone');
    if (phone !== null && newProps.visible !== this.props.visible)
      this.setState({ phone });
    const firstName = sessionStorage.getItem('firstName');
    if (firstName !== null && newProps.visible !== this.props.visible)
      this.setState({ firstName });
    const lastName = sessionStorage.getItem('lastName');
    if (lastName !== null && newProps.visible !== this.props.visible)
      this.setState({ lastName });
    if (newProps.visible !== this.props.visible)
      this.setState({ error: false });
  }
  _submit() {
    const {
      email,
      phone,
      first,
      second,
      notes,
      firstName,
      lastName,
      question1,
      question2,
      question3,
      question4,
      isCheck,
      phoneCall,
      phoneSMS
    } = this.state;

    if (!question1 && !question2 && !question3 && !question4 && !isCheck) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }

    let contact_preference = 'Email';
    if (phoneCall) {
      contact_preference = 'Call';
    } else if (phoneSMS) {
      contact_preference = 'Text/SMS';
    }

    if (!emailValidation(email)) {
      let msg;
      if (email === '' && emailCheck) msg = 'Type your email address';
      else if (!emailValidation(email) && emailCheck)
        msg = 'Type your valid email address';
      else if (phone === '' && phoneCheck) msg = 'Type your phone number';
      this.setState({ isSnackBarOpen: true, snackBarMessage: msg });
      return;
    }
    var issue_type = (first ? '1' : '0') + '|' + (second ? '2' : '0');
    this.props.designerAssist({
      email,
      phone,
      issue_type,
      notes,
      question1,
      question2,
      question3,
      question4,
      contact_preference
    });
    this.closePanel();
  }
  closePanel() {
    this.props.handleDesignerAssist(false);
    this.setState({
      email: '',
      phone: '',
      notes: ''
    });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({
      phone: e.target.validity.valid ? e.target.value : this.state.phone
    });
  }
  handleCheckChange(event) {
    this.setState({ [event.target.value]: event.target.checked });
  }
  render() {
    let { visible } = this.props;
    let {
      email,
      phone,
      isCheck,
      notes,
      notesFocusOn,
      firstName,
      lastName,
      firstNamefocusOn,
      lastNamefocusOn,
      emailfocusOn,
      phonefocusOn,
      question1,
      question2,
      question3,
      question4,
      error,
      phoneSMS,
      phoneCall
    } = this.state;

    const updatePhoneReceiveMethodHandler = (callClicked = true) => {
      this.setState(prevState => {
        let newPhoneCall = prevState.phoneCall;
        let newPhoneSMS = prevState.phoneSMS;

        if (callClicked) {
          newPhoneCall = !newPhoneCall;
          newPhoneSMS = newPhoneCall && newPhoneSMS ? false : newPhoneSMS;
        } else {
          newPhoneSMS = !newPhoneSMS;
          newPhoneCall = newPhoneCall && newPhoneSMS ? false : newPhoneCall;
        }

        return {
          phoneCall: newPhoneCall,
          phoneSMS: newPhoneSMS
        };
      });
    };

    // let { email, phone, emailCheck, phoneCheck, notes, first, second } = this.state;

    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <SnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() =>
            this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
          }
        />
        <S.DialogTitle>Request Assistance</S.DialogTitle>
        <S.DialogContent>
          Would you like to be fast-tracked to a <strong>designer</strong>?
        </S.DialogContent>
        <S.DialogContentSelection>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              onFocus={e => this.setState({ emailfocusOn: true })}
              onBlur={e => this.setState({ emailfocusOn: false })}
              required
            />
            {!emailfocusOn && !!!email.length && (
              <S.StyledInputLabel style={{ pointerEvents: 'none' }}>
                Email Address
              </S.StyledInputLabel>
            )}
            {(emailfocusOn || !!email.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                Email Address
              </S.StyledInputHeadLabel>
            )}
            {emailfocusOn && !!!email.length && (
              <S.StyledInputLabel>Enter email address</S.StyledInputLabel>
            )}
            {!!email.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ email: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={phone}
              onChange={e =>
                this.setState({
                  phone: e.target.validity.valid
                    ? e.target.value
                    : this.state.phone
                })
              }
              onFocus={e => this.setState({ phonefocusOn: true })}
              onBlur={e => this.setState({ phonefocusOn: false })}
              pattern={'[0-9]*'}
              maxLength={50}
              required
            />
            {!phonefocusOn && !!!phone.length && (
              <S.StyledInputLabel style={{ pointerEvents: 'none' }}>
                Phone
              </S.StyledInputLabel>
            )}
            {(phonefocusOn || !!phone.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !phonefocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                Phone
              </S.StyledInputHeadLabel>
            )}
            {phonefocusOn && !!!phone.length && (
              <S.StyledInputLabel>Enter phone</S.StyledInputLabel>
            )}
            {!!phone.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ phone: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.StyledPhoneMethod style={{ marginBottom: '15px' }}>
            <span style={{ paddingRight: '10px' }}>Method: </span>
            <FormControlLabel
              style={{ alignItems: 'flex-start' }}
              margin-left="10px"
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset', margin: '0 5px' }}
                  checked={phoneCall}
                  onChange={() => updatePhoneReceiveMethodHandler(true)}
                  value={phoneCall}
                />
              }
              label={<S.DialogContents> Call </S.DialogContents>}
            />
            <span style={{ paddingRight: '18px' }}> or </span>
            <FormControlLabel
              style={{ alignItems: 'flex-start' }}
              margin-left="10px"
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset', margin: '0 5px' }}
                  checked={phoneSMS}
                  onChange={() => updatePhoneReceiveMethodHandler(false)}
                  value={phoneSMS}
                />
              }
              label={<S.DialogContents> Text/SMS </S.DialogContents>}
            />
          </S.StyledPhoneMethod>

          <S.StyledInputWrapper>
            <S.StyledInput
              value={firstName}
              onChange={e => this.setState({ firstName: e.target.value })}
              onFocus={e => this.setState({ firstNamefocusOn: true })}
              onBlur={e => this.setState({ firstNamefocusOn: false })}
              required
            />
            {!firstNamefocusOn && !!!firstName.length && (
              <S.StyledInputLabel style={{ pointerEvents: 'none' }}>
                First Name(optional)
              </S.StyledInputLabel>
            )}
            {(firstNamefocusOn || !!firstName.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !firstNamefocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                First Name (optional)
              </S.StyledInputHeadLabel>
            )}
            {firstNamefocusOn && !!!firstName.length && (
              <S.StyledInputLabel>Enter first name</S.StyledInputLabel>
            )}
            {!!firstName.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ firstName: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
              onFocus={e => this.setState({ lastNamefocusOn: true })}
              onBlur={e => this.setState({ lastNamefocusOn: false })}
              required
            />
            {!lastNamefocusOn && !!!lastName.length && (
              <S.StyledInputLabel style={{ pointerEvents: 'none' }}>
                Last Name(optional)
              </S.StyledInputLabel>
            )}
            {(lastNamefocusOn || !!lastName.length) && (
              <S.StyledInputHeadLabel
                style={{ color: !lastNamefocusOn && TEXT_COLOR_NEUTRAL_2 }}
              >
                Last Name (optional)
              </S.StyledInputHeadLabel>
            )}
            {lastNamefocusOn && !!!lastName.length && (
              <S.StyledInputLabel>Enter last name</S.StyledInputLabel>
            )}
            {!!lastName.length && (
              <S.PlusImage
                maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                onClick={() => this.setState({ lastName: '' })}
              />
            )}
          </S.StyledInputWrapper>
          <S.DialogContents style={{ color: error && 'red' }}>
            Please select at least one of the options below.
          </S.DialogContents>
          <FormControlLabel
            style={{ alignItems: 'flex-start' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question1}
                onChange={() =>
                  this.setState({ question1: !question1, error: false })
                }
                value={question1}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I have general questions about my design or quote
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question2}
                onChange={() =>
                  this.setState({ question2: !question2, error: false })
                }
                value={question2}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I would like to work with a designer to help me with building
                the layout
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question3}
                onChange={() =>
                  this.setState({ question3: !question3, error: false })
                }
                value={question3}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                I'm a contractor and need help ordering for my customer
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={question4}
                onChange={() =>
                  this.setState({ question4: !question4, error: false })
                }
                value={question4}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                My design is complete and I need assistance building my cart to
                check out
              </S.DialogContents>
            }
          />
          <FormControlLabel
            style={{ alignItems: 'flex-start' }}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{
                  padding: 'unset',
                  margin: '0 10px',
                  color: error && 'red'
                }}
                checked={isCheck}
                onChange={() =>
                  this.setState({ isCheck: !isCheck, error: false })
                }
                value={isCheck}
              />
            }
            label={
              <S.DialogContents style={{ color: error && 'red' }}>
                Other
              </S.DialogContents>
            }
          />
          <div
            style={{
              width: '100%',
              position: 'relative',
              marginTop: 15,
              display:
                !question1 &&
                !question2 &&
                !question3 &&
                !question4 &&
                !isCheck &&
                'none'
            }}
          >
            <S.DialogTextArea
              value={notes}
              maxLength={200}
              onFocus={e => this.setState({ notesFocusOn: true })}
              onBlur={e => this.setState({ notesFocusOn: false })}
              placeholder={!notesFocusOn ? 'Notes (optional)' : 'Input'}
              onChange={e => this.setState({ notes: e.target.value })}
            />
            {(notesFocusOn || !!notes.length) && (
              <S.StyledInputHeadLabel
                style={{
                  color: !notesFocusOn && TEXT_COLOR_NEUTRAL_2,
                  left: 7
                }}
              >
                Notes (optional)
              </S.StyledInputHeadLabel>
            )}
            <span
              style={{
                fontFamily: DEFAULT_FONT_FAMILY,
                fontSize: 14,
                color: TEXT_COLOR_NEUTRAL_1,
                float: 'right'
              }}
            >
              {notes.length}/200 characters
            </span>
          </div>
        </S.DialogContentSelection>
        <S.DialogAction>
          <S.Button onClick={() => this.closePanel()}>Cancel</S.Button>
          <S.Button style={{ marginRight: 0 }} onClick={() => this._submit()}>
            OK
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
      // <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
      //   <S.Div>
      //     <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
      //       <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
      //         <PS.IconButton><FaTimes /></PS.IconButton>
      //       </div>
      //     </div>
      //     <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
      //       Would you like to be fast-tracked to a Designer?<br/>
      //       What is the best way to reach you for immediate contact?
      //     </div>
      //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      //       <div style={{ paddingTop: 10 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.emailCheck }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="emailCheck"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           E-mail:
      //         </label>
      //         <input value={email} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handleEmailChange(e)} disabled={!emailCheck} />
      //       </div>
      //       <div style={{ paddingTop: 20 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.phoneCheck }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="phoneCheck"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           Phone:
      //         </label>
      //         <input value={phone} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handlePhoneChange(e)} disabled={!phoneCheck} pattern={"[0-9]*"} />
      //       </div>
      //     </div>
      //     <div style={{ fontSize: 14, marginTop: 10, marginLeft: 80, alignItems: 'center'}}>
      //       Specify the type of issue you are having
      //     </div>
      //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative', marginLeft: '80px' }}>
      //       <div style={{ paddingTop: 12 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.second }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="second"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           I don't have measurements
      //         </label>
      //       </div>
      //       <div style={{ paddingTop: 12 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.first }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="first"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           Other
      //         </label>
      //       </div>
      //       <div style={{ paddingTop: '0px', width: '340px' }}>
      //         <div style={{ marginTop: '8px' }}>
      //           <textarea value={notes} style={{ width: '100%', height: '100%', resize: 'none' }} disabled={!first} rows={10} onChange={e => this.setState({ notes: e.target.value })} />
      //         </div>
      //       </div>
      //     </div>
      //     <div style={{ width: '70px', marginLeft: '350px', marginTop: '10px', cursor: 'pointer' }} onClick={() => this._submit()}>
      //       <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
      //       <S.ButtonTitle>
      //         {'SUBMIT'}
      //       </S.ButtonTitle>
      //     </div>
      //   </S.Div>
      // </div>
    );
  }
}

export default DesignerAssistPopup;

import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
// import { MdSettings, MdUndo, MdDirectionsRun } from 'react-icons/md';
import convert from 'convert-units';
import ErrorIcon from '@material-ui/icons/Error';
import { FaPlayCircle } from 'react-icons/fa';
import ToolbarButton from '../button';
import FloorToolbarButton from '../popup/floorplan';
import CabinetDoorStyleToolbarButton from '../popup/doorStyle';
import CabinetToolbarButton from '../popup/cabinet';
import ApplianceToolbarButton from '../popup/appliance';
import FinishingTouchToolbarButton from '../popup/finishingtouch';
import ReviewQuoteToolbarButton from '../popup/reviewquote';
import PanelElementEditor from './../../sidebar/panel-element-editor/panel-element-editor';
import FormNumberInput from '../../style/form-number-input';
import * as rectangleData from './rectangle.json';
import * as lShaped from './lShaped.json';
import * as oRectangle from './oRectangle.json';
import * as longNarrow from './longNarrow.json';
import If from '../../../utils/react-if';
import PlgItem from '../plugin-item.jsx';
import * as PS from '../popup/styles';
import { Map } from 'immutable';
import { StyledAlert } from './myComponents';
import { toFixedFloat } from '../../../utils/math';
// import * as Three from 'three';
import ConfirmPopup from './confirm-popup';
import * as door_closet from '../../../../demo/src/catalog/holes/door-closet/planner-element';
import * as door_interior from '../../../../demo/src/catalog/holes/door-interior/planner-element';
import * as door_exterior from '../../../../demo/src/catalog/holes/door-exterior/planner-element';
import * as door_sliding from '../../../../demo/src/catalog/holes/door-sliding/planner-element';
import * as door_framed from '../../../../demo/src/catalog/holes/doorway-framed/planner-element';
import * as door_frameless from '../../../../demo/src/catalog/holes/doorway-frameless/planner-element';
import * as window_clear from '../../../../demo/src/catalog/holes/window-clear/planner-element';
import * as window_cross from '../../../../demo/src/catalog/holes/window-cross/planner-element';
import * as window_vertical from '../../../../demo/src/catalog/holes/window-vertical/planner-element';
import * as window_double_hung from '../../../../demo/src/catalog/holes/window-double-hung/planner-element';
import * as testJsonData from '../../../../../../../test/*.json';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';

import {
  // API_SERVER,
  MODE_IDLE,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAGGING_ITEM_3D,
  MODE_2D_PAN,
  MODE_DRAWING_ITEM_3D,
  MAKE_FLOOR_PLAN,
  SELECT_DOOR_STYLE,
  ADD_CABINETS,
  ADD_APPLIANCES,
  FINISHING_TOUCH,
  REVIEW_AND_QUOTE,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_5,
  ARRAY_3D_MODES,
  TEXT_COLOR_NEUTRAL_2,
  STATUS_NEGATIVE_COLOR,
  BG_COLOR_1,
  TEXT_COLOR_NEUTRAL_0,
  STATUS_POSITIVE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  MODE_DRAWING_LINE
} from '../../../constants';
import * as SharedStyle from '../../../shared-style';
import * as S from './styles';
import { getSignedUrl } from '../../../utils/helper';
import SetDoorStyleOptionPopup from '../popup/setDoorStyleOption';
import ReviewItem from '../popup/product/reviewItem';
import ToggleMeasureButton from '../../button/ToggleMeasureButton';
import SnackBar from '../../atoms/Snackbar/index';
import { returnReplaceableDeepSearchType } from '../../viewer2d/utils';
import { GeometryUtils } from '../../../utils/export';
import {
  createMDFromItem,
  getLinesOfItem,
  getLinesOverLap,
  tryAdjacentMD
} from '../../../components/viewer3d/scene-creator';
import ReviewMolding from '../popup/product/reviewMolding';

let unitData = [
  {
    _name: 'inch',
    _unit: 'in'
  },
  {
    _name: 'centimeter',
    _unit: 'cm'
  },
  {
    _name: 'millimeter',
    _unit: 'mm'
  },
  {
    _name: 'meter',
    _unit: 'm'
  },
  {
    _name: 'feet',
    _unit: 'ft'
  }
];

const ASIDE_STYLE = {
  marginTop: '85px'
  // zIndex: 6,
};

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If key={ind} condition={el.condition} style={{ position: 'relative' }}>
      {el.dom}
    </If>
  );
};

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: TEXT_COLOR_NEUTRAL_0,
    color: BG_COLOR_1,
    maxWidth: 180,
    fontSize: 9,
    fontFamily: DEFAULT_FONT_FAMILY,
    boxShadow:
      '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)',
    padding: '2px 15px'
  }
}))(Tooltip);

const searchInput = {
  width: 'calc( 100% - 2em )',
  height: '2em',
  marginTop: '15px',
  padding: '0 1em',
  margin: '0 1em',
  border: '1px solid #EEE'
};

const finishingtouchData = [
  {
    name: 'Handles',
    thumbnail: '/assets/img/svg/toolbar/handles.svg'
  },
  {
    name: 'Countertop',
    thumbnail: '/assets/img/svg/toolbar/countertop.svg'
  },
  {
    name: 'Backsplash',
    thumbnail: '/assets/img/svg/toolbar/backsplash.svg'
  },
  {
    name: 'Wall Color',
    thumbnail: '/assets/img/svg/toolbar/wall_color_icon.svg'
  },
  {
    name: 'Floor Styles',
    thumbnail: '/assets/img/svg/toolbar/floorstyle.svg'
  },
  {
    name: 'Lighting',
    thumbnail: '/assets/img/svg/toolbar/lighting.svg'
  },
  {
    name: 'Furnishing',
    thumbnail: '/assets/img/svg/toolbar/furnishings_icon.svg'
  }
];

let hintText = '';
export default class Toolbar extends Component {
  constructor(props, context) {
    super(props, context);

    let layer = props.layers.get(props.selectedLayer);
    let elements = {
      lines: layer.lines,
      holes: layer.holes,
      items: layer.items,
      molding: layer.molding
    };
    let ceilingHeight = props.state.scene.layers.get(
      props.selectedLayer
    ).ceilHeight;
    let measurementUnit = unitData.filter(
      unit =>
        unit._unit === props.state.scene.layers.get(props.selectedLayer).unit
    )[0];

    this.state = {
      elements,
      appliancesData: [],
      doorHandle: [],
      wallColors: [],
      floorStyles: [],
      lighting: [],
      counterTop: [],
      molding: [],
      backsplash: [],
      furnishing: [],
      opened: false,
      vData: [],
      toData: [],
      vDataArray: [],
      dData: [],
      oData: [],
      DoorStyles: [],
      dDataArray: [],
      currentTexture:
        layer.doorStyle !== null || layer.doorStyle !== undefined
          ? layer.doorStyle
          : props.state.doorStyle.toJS(),
      changeTexture: undefined,
      doorTitle: [],
      cabinetTitle: [],
      cabinetCollapse: true,
      sortFlag: true,
      doorCollapse: true,
      doorExpand: false,
      searchCabinet: '',
      searchDoor: '',
      floorCollapse: true,
      animOpened: false,
      closeover: false,
      animover: false,
      cabinetStyle: 0,
      plgItems: [],
      plgUrls: [],
      filter: '',
      activeButton: 'Floor Plan',
      search: '',
      controlButton: '',
      topButton: '',
      recentStyle: [],
      selectAll: false,
      cabinetSubCategoryData: [],
      cabinetExpand: -1,
      applianceSubCategoryData: [],
      applianceCategoryCollapse: true,
      applianceExpand: -1,
      finishingSubCategoryData: [],
      finishingCategoryCollapse: true,
      finishingProductCollapse: true,
      floorSubCategoryData: [],
      floorCategoryCollapse: true,
      floorProductCollapse: true,
      doorStyleSubCategoryData: [],
      doorStyleCategoryCollapse: true,
      doorStyleProductCollapse: true,
      doorStyleStep: 0,
      stepSource: [],
      doorStyleExpand: -1,
      reviewCollapse: true,
      isMolding: false,
      editWidthVisible: false,
      isModalVisible: false,
      setDoorStylePopupVisible: false,
      imageUri: '',
      image: '',
      croppedImage: '',
      isMouseDown: false,
      firstMousePoint: { x: 0, y: 0 },
      lastMousePoint: { x: 1000, y: 1000 },
      mouse: { p: { x: 0, y: 0 }, frame: 0, frames: 30 },
      topLeft: {
        x: this.percentToPxHor(17.275),
        y: this.percentToPxVert(59.691)
      },
      bottomLeft: {
        x: this.percentToPxHor(17.275),
        y: this.percentToPxVert(60.72)
      },
      topRight: {
        x: this.percentToPxHor(71.459),
        y: this.percentToPxVert(61.407)
      },
      bottomRight: {
        x: this.percentToPxHor(71.352),
        y: this.percentToPxVert(62.436)
      },
      ceilingHeight: ceilingHeight,
      measurementUnit: measurementUnit,
      cabinetCategory: { name: '', data: [] },
      subCabinetCategory: { name: '', data: [] },
      selectedFinishing: '',
      selectedRoomElement: '',
      checkedRoomElement: {},
      selectedProject: '',
      isPopupOpen: false,
      cabinetStyleChanging: true,
      cabinetStyle: { name: '', data: [] },
      subCabinetStyle: { name: '', data: [] },
      isAssembled: false,
      viewMoreDoorstyle: false,
      searchName: '',
      replaceCabinetFlag: false,
      chosenReplacedItem: {},
      isSnackBarOpen: false,
      snackBarMessage: '',
      isShowProperty: true,
      addingJson: false,
      helpViewNum: 1,
      notes: '',
      videoUrl: '',
      moldingData: []
    };
    this.onMouseUp_On_New_Save_SQ_DA =
      this.onMouseUp_On_New_Save_SQ_DA.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.getLinearOfMolding = this.getLinearOfMolding.bind(this);
    // this.onMoveDown = this.onMoveDown.bind(this);
    // this.onMoveUp = this.onMoveUp.bind(this);
    // this.onRotateDown = this.onRotateDown.bind(this);
    // this.onRotateUp = this.onRotateUp.bind(this);
  }

  hideModal() {
    this.setState({ isModalVisible: false });
  }
  editWidth() {
    this.setState({ editWidthVisible: true });
  }

  handleEditWidth(newWidth, layerID, itemID) {
    this.setState({ editWidthVisible: false });
    this.context.itemsActions.editWidth(newWidth, layerID, itemID);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
    const self = this;
    const data = self.props.categoryData;
    if (data.success === true) {
      const {
        appliances,
        cabinets,
        doorStyles,
        doorHandle,
        wallColors,
        floorStyles,
        lighting,
        counterTop,
        backsplash,
        furnishing
      } = data.data;
      var molding = [];
      data.data.cabinets.forEach(cabinet => {
        cabinet.items.forEach(index => {
          if (index.name.toLowerCase().includes('molding')) {
            index.items.forEach(item => {
              molding.push(item);
            });
          }
        });
      });
      let tmp = [];
      doorStyles.items.forEach(item => {
        let t = [];
        item.items.forEach(item1 => {
          let t2 = [];
          item1.items.forEach(item2 => {
            t2.push({ ...item2, install: item.name });
          });
          t.push({ ...item1, items: [...t2] });
        });
        tmp.push({ ...item, items: t });
      });
      self.setState({
        appliancesData: appliances,
        vData: cabinets,
        doorHandle,
        wallColors,
        floorStyles,
        lighting,
        counterTop,
        backsplash,
        furnishing,
        toData: cabinets,
        molding,
        DoorStyles: tmp,
        dData: tmp,
        stepSource: tmp
      });
      if (self.state.currentTexture == null) {
        const findDefault = data => {
          var result = undefined;
          for (var i = 0; i < data.length; i++) {
            if (data[i].type === 'door-category') {
              result = findDefault(data[i].items);
              if (result !== undefined) {
                break;
              }
            } else if (data[i].type === 'door') {
              if (data[i].install) {
                data[i]['isAssembled'] =
                  data[i].install.toLowerCase() === 'pre-assembled';
                return data[i];
              }
            }
          }
          return result;
        };

        const doorCategory = (result, data, path) => {
          if (
            data.some(element => {
              return element.type === 'door-category';
            })
          ) {
            const temp = data.filter(element => {
              return element.type === 'door-category';
            });
            temp.forEach(t => {
              if (t.items !== undefined) {
                doorCategory(
                  result,
                  t.items,
                  path != '' ? path + ',' + t.name : t.name
                );
              }
            });
          } else {
            const temp = data.filter(element => {
              return element.type !== 'door-category';
            });
            temp.forEach(t => {
              result.push({
                name: t.name,
                path: path != '' ? path + ',' + t.name : t.name,
                data: t
              });
            });
          }
          return result;
        };

        const findFinishing = data => {
          let isSelected = false;
          let temp = {
            counterTop: [],
            floorStyles: []
          };
          if (data.counterTop) {
            data.counterTop.forEach(element => {
              if (element.selected) isSelected = true;
            });
            if (!isSelected) data.counterTop[0].selected = true;
            temp.counterTop = data.counterTop;
          } else {
            console.log('There are no counterTops.');
          }
          isSelected = false;
          if (data.floorStyles) {
            data.floorStyles.forEach(element => {
              if (element.selected) isSelected = true;
            });
            if (!isSelected) data.floorStyles[0].selected = true;
            temp.floorStyles = data.floorStyles;
          } else {
            console.log('There are no floorStyles.');
          }
          temp = {
            counterTop: temp.counterTop.filter(e => {
              return e.selected == true;
            }),
            floorStyles: temp.floorStyles.filter(e => {
              return e.selected == true;
            })
          };
          return temp;
        };

        let dStyle = findDefault(tmp);
        let finishing = findFinishing({ counterTop, floorStyles });
        let oStyle = doorCategory([], tmp, '');
        let pathes = [];
        self.context.itemsActions.setInitialDoorStyle(dStyle, oStyle);
        self.context.itemsActions.setCounterTop({
          uri: finishing.counterTop[0].texture
        });
        self.context.areaActions.setFloorStyles({
          uri: finishing.floorStyles[0].texture
        });
        if (dStyle !== undefined) {
          self.setState({ currentTexture: dStyle, oData: oStyle });
        } else {
          self.setState({
            currentTexture: tmp[0].items[0].items[0],
            oData: oStyle
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let layer = nextProps.layers.get(nextProps.selectedLayer);
    this.setState({
      measurementUnit: unitData.filter(unit => unit._unit === layer.unit)[0],
      ceilingHeight: layer.ceilHeight
    });
    let tmp = [];
    this.props.categoryData.data.doorStyles.items.forEach(item => {
      let t = [];
      item.items.forEach(item1 => {
        let t2 = [];
        item1.items.forEach(item2 => {
          t2.push({ ...item2, install: item.name });
        });
        t.push({ ...item1, items: [...t2] });
      });
      tmp.push({ ...item, items: t });
    });
    if (
      layer.doorStyle !== null &&
      layer.doorStyle !== undefined &&
      this.state.currentTexture !== undefined
    ) {
      if (layer.doorStyle.id !== this.state.currentTexture.id) {
        this.setState({ currentTexture: layer.doorStyle });
      }
    } else if (layer.doorStyle === null || layer.doorStyle === undefined) {
      this.setState({
        currentTexture:
          this.props.state.doorStyle === null ||
          this.props.state.doorStyle === undefined
            ? tmp
                .filter(
                  item =>
                    item.items.filter(item1 => item1.items.length !== 0)
                      .length !== 0
                )[0]
                .items.filter(item => item.items.length !== 0)[0].items[0]
            : this.props.state.doorStyle.toJS()
      });
    }

    let activeButton = '';
    if (nextProps.reviewQuotePopupOpened) {
      activeButton = REVIEW_AND_QUOTE;
    } else if (nextProps.floorOpened) {
      activeButton = MAKE_FLOOR_PLAN;
    } else if (nextProps.cabinetOpened) {
      activeButton = ADD_CABINETS;
    } else if (nextProps.applianceOpened) {
      activeButton = ADD_APPLIANCES;
    } else if (nextProps.finishingOpened) {
      activeButton = FINISHING_TOUCH;
    } else if (nextProps.doorStyleOpen) {
      activeButton = SELECT_DOOR_STYLE;
    }
    this.setState({ activeButton });

    let propLayer = this.props.layers.get(this.props.selectedLayer);
    if (propLayer.molding !== layer.molding) {
      layer.linears.forEach(linear => {
        if (layer.molding.findIndex(m => linear.molding.name === m.name) > -1) {
          this.context.itemsActions.setLinear(linear);
        }
      });
    }

    if (propLayer.items !== layer.items) {
      layer.linears.forEach(linear => {
        let tmp = this.getLinearOfMolding(linear.molding, linear.items[0].id);
        if (tmp) {
          let f = false;
          if (
            linear.items.length === tmp.items.length &&
            linear.lines.length === tmp.lines.length
          ) {
            let i = 0,
              j = 0;
            linear.items.forEach(item => {
              if (tmp.items.findIndex(a => a.id === item.id) > -1) {
                i++;
              }
            });
            linear.lines.forEach(line => {
              if (
                tmp.lines.findIndex(a => {
                  if (
                    (GeometryUtils.samePoints(a[0], line[0]) &&
                      GeometryUtils.samePoints(a[1], line[1])) ||
                    (GeometryUtils.samePoints(a[1], line[0]) &&
                      GeometryUtils.samePoints(a[0], line[1]))
                  ) {
                    return true;
                  }
                  return false;
                }) > -1
              ) {
                j++;
              }
            });
            if (j === linear.lines.length && i === linear.items.length) {
              f = true;
            }
          }
          if (!f) {
            this.context.itemsActions.setLinear(linear);
          }
        } else {
          this.context.itemsActions.setLinear(linear);
        }
      });
    }

    if (this.props.layers.hashCode() === nextProps.layers.hashCode()) return;

    let elements = {
      lines: layer.lines,
      holes: layer.holes,
      items: layer.items,
      molding: layer.molding
    };

    this.setState({
      elements
    });
  }

  toggleOpen() {
    let { replacingSupport } = this.props.state;
    let layerID = replacingSupport.getIn(['layerID']);
    let itemID = replacingSupport.getIn(['itemID']);
    let iName = null;
    if (layerID !== undefined) {
      let obj = this.props.state.scene.getIn([
        'layers',
        layerID,
        'items',
        itemID
      ]);
      if (obj === undefined) {
        this.PanelClose();
        return;
      }
      iName = obj.name;
      this.setState({ plgUrls: [iName] });
      this.updateplgItems([iName]);
      if (ARRAY_3D_MODES.includes(this.props.state.mode) && !this.state.opened)
        this.PanelOpen();
      else this.PanelClose();
    } else {
      this.PanelClose();
    }
  }

  Animate() {
    this.context.itemsActions.animateObject(this.state.animValue);
  }

  toggleAnimationOpen() {
    let { replacingSupport } = this.props.state;
    let layerID = replacingSupport.getIn(['layerID']);
    let itemID = replacingSupport.getIn(['itemID']);
    let planData = window.planData;
    let iName = null;
    if (layerID !== undefined) {
      let obj = this.props.state.scene.getIn([
        'layers',
        layerID,
        'items',
        itemID
      ]);
      if (obj === undefined) {
        this.PanelAnimClose();
        return;
      }
      iName = obj.name;
      let item3D = planData.sceneGraph.layers[layerID].items[itemID];
      let userData = item3D.children[0].userData;
      this.setState({ animData: userData.animation, animValue: 0 });
      this.setState({ plgUrls: [iName] });
      this.updateplgItems([iName]);
      if (
        ARRAY_3D_MODES.includes(this.props.state.mode) &&
        !this.state.animOpened
      )
        this.PanelAnimOpen();
      else this.PanelAnimClose();
    } else {
      this.PanelAnimClose();
    }
  }

  PanelOpen() {
    this.setState({ opened: true, animOpened: false });
  }

  PanelClose() {
    this.setState({ opened: false, closeover: false });
  }

  PanelAnimOpen() {
    this.setState({ animOpened: true, opened: false });
  }

  PanelAnimClose() {
    this.setState({ animOpened: false, closeover: false });
  }
  getItem(i) {
    if (i == -1) {
      this.setState({
        cabinetSubCategoryData: [],
        cabinetTitle: [],
        cabinetExpand: -1
      });
    } else {
      if (i.toLowerCase().includes(' molding')) {
        this.setState({ isMolding: true });
      } else if (i.toLowerCase().includes('molding')) {
        this.setState({ cabinetSubCategoryData: this.state.molding });
      } else if (i != '-1') {
        const getCategory = (result, data, path) => {
          if (
            data.some(element => {
              return element.type === 'cabinet_category';
            })
          ) {
            const temp = data.filter(element => {
              return element.type === 'cabinet_category';
            });
            temp.forEach(t => {
              if (t.items !== undefined) {
                result.push({
                  name: t.name,
                  path: path != '' ? path + ',' + t.name : t.name,
                  data: t.items
                });
                getCategory(result, t.items, t.name);
              }
            });
          }
          return result;
        };
        const allData = getCategory([], this.state.vData, '');
        var t = allData.find(element => {
          return element.name === i;
        });
        const splitStrs = t.path.split(',');
        const result = t.data.filter(element => {
          return element.type === 'cabinet';
        });
        const cds =
          this.state.currentTexture && this.state.currentTexture.doorStyles.cds;
        const cabinetData = this.props.data,
          lastResult = [];
        if (cabinetData) {
          result.forEach(item => {
            const tempItem = cabinetData.find(element => {
              return element.name == item.name;
            });
            if (cds.some(element => element.itemID == tempItem.itemID))
              lastResult.push(item);
          });
        }
        this.setState({
          cabinetSubCategoryData: lastResult,
          cabinetTitle: splitStrs,
          isMolding: false
        });
        if (this.state.cabinetExpand === -1) {
          this.setState({ cabinetExpand: 0 });
        }
      }
    }
  }
  getAppliance(i) {
    // const appliance = this.state.appliancesData.find(element => element.name === i);
    // this.setState({ applianceSubCategoryData: appliance.items });
    if (i != '-1') {
      const getCategory = (result, data, path) => {
        if (
          data.some(element => {
            return element.type === 'appliance_category';
          })
        ) {
          const temp = data.filter(element => {
            return element.type === 'appliance_category';
          });
          temp.forEach(t => {
            if (t.items !== undefined) {
              result.push({
                name: t.name,
                path: path != '' ? path + ',' + t.name : t.name,
                data: t.items
              });
              getCategory(result, t.items, t.name);
            }
          });
        }
        return result;
      };
      const allData = getCategory([], this.state.appliancesData, '');
      var t = allData.find(element => {
        return element.name === i;
      });
      const splitStrs = t.path.split(',');
      const result = t.data.filter(element => {
        return element.type === 'cabinet';
      });
      this.setState({
        applianceSubCategoryData: result,
        cabinetTitle: splitStrs
      });
      if (this.state.applianceExpand === -1) {
        this.setState({ applianceExpand: 0 });
      }
    } else {
      this.setState({
        applianceSubCategoryData: [],
        cabinetTitle: [],
        applianceExpand: -1
      });
    }
  }
  getFloor(i) {
    if (i === -1 || i === -2) {
      this.setState({ floorSubCategoryData: i });
    } else {
      const data = this.props.catalog.getCategory('root');
      const fData = data.categories.find(el => {
        return el.name === i;
      });
      this.setState({ floorSubCategoryData: fData.elements });
    }
  }
  getFinishing(i) {
    if (i === 'Handles') {
      this.setState({ finishingSubCategoryData: this.state.doorHandle });
    } else if (i === 'Wall Color') {
      this.setState({ finishingSubCategoryData: this.state.wallColors });
    } else if (i === 'Floor Styles') {
      this.setState({ finishingSubCategoryData: this.state.floorStyles });
    } else if (i === 'Lighting') {
      this.setState({ finishingSubCategoryData: this.state.lighting });
    } else if (i === 'Countertop') {
      this.setState({ finishingSubCategoryData: this.state.counterTop });
    } else if (i === 'Backsplash') {
      this.setState({ finishingSubCategoryData: this.state.backsplash });
    } else if (i === 'Furnishing') {
      this.setState({ finishingSubCategoryData: this.state.furnishing });
    }
  }
  getStyle(texture) {
    texture = { ...texture };
    let doorTitle = this.state.doorTitle;
    let recentStyle = this.state.recentStyle;
    let rStyle = { doorTitle: doorTitle, textureData: texture };
    if (recentStyle.length == 2) {
      recentStyle[1] = recentStyle[0];
      recentStyle[0] = rStyle;
    } else {
      recentStyle.unshift(rStyle);
    }
    this.setState({ recentStyle: recentStyle });
    this.setState({
      currentTexture: texture,
      doorStyleStep: 0,
      doorStyleExpand: -1,
      doorStyleSubCategoryData: [],
      stepSource: this.state.DoorStyles,
      doorTitle: [],
      changeTexture: texture
    });

    let { scene } = this.props.state;
    let layerID = scene.get('selectedLayer');
    let itemIDs = scene.layers.getIn([layerID, 'selected', 'items']).toJS();
    if (itemIDs.length > 0) {
      this.setState({ setDoorStylePopupVisible: true });
    } else {
      this.context.itemsActions.setDoorStyle(texture, doorTitle, true);
    }

    this.props.setToolbar('');
  }
  applyMaterial(category, metalness, roughness) {
    if (category === 'floor_style')
      this.context.areaActions.setFloorStyles({ metalness, roughness });
    else if (category === 'backsplash')
      this.context.itemsActions.setBacksplash({ metalness, roughness });
    else if (category === 'counter_top')
      this.context.itemsActions.setCounterTop({ metalness, roughness });
    else if (category === 'handle')
      this.context.itemsActions.setHandleMaterial({ metalness, roughness });
    else if (category === 'appliance')
      this.context.itemsActions.setApplianceMaterial({ metalness, roughness });
  }
  setFinishing(texture) {
    this.context.projectActions.unselectAll();
    if (texture.category === 'modelling') {
      this.context.itemsActions.setModelling(texture.name);
    } else if (texture.category.toLowerCase().includes('molding')) {
      this.context.itemsActions.setMolding(texture);
    } else if (texture.category === 'wall_color') {
      this.context.itemsActions.setWallColor(texture.color);
    } else if (texture.category === 'counter_top') {
      this.context.itemsActions.setCounterTop({ uri: texture.texture });
    } else if (texture.category === 'floor_style') {
      this.context.areaActions.setFloorStyles({ uri: texture.texture });
    } else if (texture.category === 'backsplash') {
      this.context.itemsActions.setBacksplash({ uri: texture.texture });
    } else if (
      texture.category === 'lighting' ||
      texture.category === 'furnishing'
    ) {
      this.context.itemsActions.updatePopupOpen(2);
      if (
        texture.category === 'lighting' &&
        [
          MODE_IDLE_3D,
          MODE_DRAWING_ITEM_3D,
          MODE_DRAGGING_ITEM_3D,
          MODE_ROTATING_ITEM_3D
        ].includes(this.props.state.mode)
      )
        // props.itemsActions.selectToolDrawingItem(dataSource[i].name);
        this.context.itemsActions.selectToolDrawingItem3D(texture.name);
      else {
        [
          MODE_IDLE_3D,
          MODE_DRAWING_ITEM_3D,
          MODE_DRAGGING_ITEM_3D,
          MODE_ROTATING_ITEM_3D
        ].includes(this.props.state.mode)
          ? this.context.itemsActions.selectToolDrawingItem3D(texture.name)
          : this.context.itemsActions.selectToolDrawingItem(texture.name);
      }
      this.context.projectActions.pushLastSelectedCatalogElementToHistory(
        this.props.catalog.elements[texture.name]
      );
      if (document.getElementsByClassName('popup-content').length > 0)
        document.getElementsByClassName('popup-content')[0].style.display =
          'none';
      this.context.projectActions.setIsCabinetDrawing(true);
    } else {
      const gltfJSON = JSON.parse(texture.gltf);
      if (gltfJSON.gltf.some(el => el.endsWith('gltf'))) {
        this.context.itemsActions.setDoorHandle(
          gltfJSON.gltf.find(el => el.endsWith('gltf'))
        );
        if (document.getElementsByClassName('popup-content').length > 0)
          document.getElementsByClassName('popup-content')[0].style.display =
            'none';
      } else {
        this.setState({
          snackBarMessage: 'Invalid Door Handle!!!',
          isSnackBarOpen: true
        });
        console.log('There are no gltf file in ' + texture.name + 'handle!');
      }
    }
    this.props.setToolbar('');
  }

  setDoorStyleOption(value) {
    this.setState({ setDoorStylePopupVisible: false });
    this.context.itemsActions.setDoorStyle(
      this.state.changeTexture,
      this.state.doorTitle,
      value
    );
  }

  loadProject(data) {
    this.context.projectActions.loadProject(
      data || this.state.selectedProject,
      this.props.categoryData
    );
  }

  _submit() {
    this.loadProject();
    this.handleConfirmLoad();
  }

  isProjectEmpty(scene) {
    let { layers, selectedLayer } = scene;
    let layer = layers.get(selectedLayer);
    return (
      layer.areas.size +
        layer.lines.size +
        layer.holes.size +
        layer.items.size ===
      0
    );
  }

  loadProjectFromData(item, event) {
    event.preventDefault();

    if (this.isProjectEmpty(this.props.state.scene)) {
      this.loadProject(item.data, this.props.categoryData);
    } else {
      this.setState({ selectedProject: item.data, isPopupOpen: true });
    }
    this.props.setToolbar('');
  }

  handleConfirmLoad() {
    this.setState({ isPopupOpen: false });
    this.props.setToolbar('');
  }

  cabinetselect(name) {
    this.select(this.props.catalog.elements[name]);
  }
  select(element) {
    this.context.projectActions.unselectAll();
    switch (element.prototype) {
      case 'lines':
        this.context.linesActions.selectToolDrawingLine(element.name);
        break;
      case 'items':
        ARRAY_3D_MODES.includes(this.props.state.mode)
          ? this.context.itemsActions.selectToolDrawingItem3D(element.name)
          : this.context.itemsActions.selectToolDrawingItem(element.name);
        break;
      case 'holes':
        this.context.holesActions.selectToolDrawingHole(element.name);
        break;
    }
    this.context.projectActions.pushLastSelectedCatalogElementToHistory(
      element
    );
    this.context.projectActions.setIsCabinetDrawing(true);
  }

  getStyleCategory(i) {
    const { doorStyleStep } = this.state;
    if (doorStyleStep === 1) {
      this.setState({ doorStyleExpand: 0 });
    }
    this.setState({ doorStyleStep: doorStyleStep + 1 });
    if (i !== -1) {
      const { stepSource } = this.state;
      const splitStr = i.split('/');
      var data = stepSource;
      for (let x = 0; x < splitStr.length; x++) {
        const temp = data.find(el => {
          return el.name === splitStr[x];
        });
        data = temp.items;
      }
      const result = data.filter(element => {
        return element.type === 'door';
      });
      this.setState({
        doorStyleSubCategoryData: result,
        isAssembled: i.split('/')[0] === 'Pre-assembled' ? true : false
      });
    }
  }
  collapseAction(i) {
    if (i == 0) {
      if (this.state.cabinetCollapse == false)
        this.setState({ cabinetCollapse: true, doorCollapse: true });
      else this.setState({ cabinetCollapse: false, doorCollapse: true });
    }
    if (i == 1) {
      if (this.state.doorCollapse == false)
        this.setState({ cabinetCollapse: true, doorCollapse: true });
      else this.setState({ cabinetCollapse: true, doorCollapse: false });
    }
    if (i == 2) this.setState({ floorCollapse: !this.state.floorCollapse });
    if (i == 3) this.setState({ doorExpand: !this.state.doorExpand });
    if (i == 4) {
    }
  }

  expandDoorStyleAction() {
    this.setState({ doorStyleExpand: (this.state.doorStyleExpand + 1) % 2 });
  }

  expandCabinetAction() {
    this.setState({ cabinetExpand: (this.state.cabinetExpand + 1) % 2 });
  }

  expandApplianceAction() {
    this.setState({ applianceExpand: (this.state.applianceExpand + 1) % 2 });
  }

  plgItemAction(url, name, gltfUrl, self) {
    let purls = this.state.plgUrls;
    if (url == 'dir') {
      purls.push(name);
    }
    if (url == 'before') purls.pop();
    if (url != 'dir' && url != 'before') {
      this.context.itemsActions.replaceSubmodule(gltfUrl);
    }
    this.updateplgItems(this.state.plgUrls);
  }

  updateplgItems(urls) {
    let getUrl = '';
    for (let i = 0; i < urls.length; i++) {
      getUrl += '/';
      getUrl += urls[i];
    }
    const self = this;
  }

  filterChange(self) {
    let setValue = self.target.value;
    this.setState({ filter: setValue });
  }

  setAnimValue(event) {
    let value = event.target.value;
    this.setState({ animValue: value });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let openedType = nextProps.state.get('openedType');
    if (nextProps.state.get('isOpen') === true) {
      this.context.holesActions.endCreatingHole();
      this.context.itemsActions.endCreatingCabinet();
      this.context.projectActions.setIsCabinetDrawing(false);
    }

    return (
      this.props.state.mode !== nextProps.state.mode ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.state.alterate !== nextProps.state.alterate ||
      nextState
    );
  }

  selectFromRecent(i) {
    let doorTitle = this.state.recentStyle[i].doorTitle;
    doorTitle.pop();
    let textureData = this.state.recentStyle[i].textureData;
    // console.log("selectfromrecent", textureData);
    this.context.itemsActions.setDoorStyle(textureData, doorTitle);
    this.setState({
      cabinetStyle: 0,
      currentTexture: textureData,
      dData: this.state.DoorStyles,
      doorTitle: [],
      dDataArray: []
    });
    // this.setState({ cabinetStyle: 0, currentTexture: textureData, dData: DoorStyles, doorTitle: [], dDataArray: [] });
  }

  selected(doorId) {
    let texture = this.state.currentTexture;
    if (texture)
      return texture.id === doorId &&
        texture.isAssembled === this.state.isAssembled
        ? 1
        : 0;
    else return 0;
  }

  onSetSort(flag) {
    this.setState({ sortFlag: flag });
  }

  onSearch(e, i) {
    if (i == 0) this.setState({ searchCabinet: e.target.value });
    else this.setState({ searchDoor: e.target.value });
  }

  onUpdate(e) {
    this.setState({ search: e.target.value });
  }

  drawCircle(ctx, p, color, radius) {
    if (typeof radius == 'undefined') radius = 2;
    if (typeof color == 'undefined') color = 'red';
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(p.x, p.y, radius, 0, 2 * Math.PI);
    ctx.fill();
  }

  drawPoly(ctx, points, color) {
    ctx.globalAlpha = 1;
    ctx.strokeStyle = color ? color : 'red';
    ctx.beginPath();
    var p = points[points.length - 1];
    ctx.moveTo(p.x, p.y);
    for (var i = 0; i < points.length; i++) {
      var p = points[i];
      ctx.lineTo(p.x, p.y);
    }
    ctx.stroke();
  }

  percentToPxHor(canvas, intPx) {
    return (intPx * canvas.width) / 100;
  }

  percentToPxVert(canvas, intPx) {
    return (intPx * canvas.height) / 100;
  }

  canvasUpdate(ctx, canvas) {
    if (ctx === undefined || this.state.image === '') return;
    // clear canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalAlpha = 0.3;
    ctx.drawImage(this.state.image, 0, 0, canvas.width, canvas.height);
    if (this.state.isMouseDown) {
      ctx.globalAlpha = 1;
      ctx.drawImage(
        this.state.image,
        this.state.firstMousePoint.x,
        this.state.firstMousePoint.y,
        this.state.mouse.p.x - this.state.firstMousePoint.x,
        this.state.mouse.p.y - this.state.firstMousePoint.y,
        this.state.firstMousePoint.x,
        this.state.firstMousePoint.y,
        this.state.mouse.p.x - this.state.firstMousePoint.x,
        this.state.mouse.p.y - this.state.firstMousePoint.y
      );

      let img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = canvas.toDataURL();

      img.onload = () => {
        this.setState({ croppedImage: img });
      };
    }

    // draw points and connect
    this.drawCircle(ctx, this.state.topLeft, 'blue');
    this.drawCircle(ctx, this.state.bottomLeft, 'green');
    this.drawCircle(ctx, this.state.topRight, 'red');
    this.drawCircle(ctx, this.state.bottomRight, 'orange');
    this.drawPoly(ctx, [
      this.state.topLeft,
      this.state.bottomLeft,
      this.state.bottomRight,
      this.state.topRight
    ]);

    // draw mouse circle
    // this.drawCircle(ctx, this.state.mouse.p, 'rgba(255,200,0,.75)', 10);

    // draw mouse lines
    let h1 = { x: 0, y: this.state.mouse.p.y };
    let h2 = { x: canvas.width, y: this.state.mouse.p.y };
    let v1 = { x: this.state.mouse.p.x, y: 0 };
    let v2 = { x: this.state.mouse.p.x, y: canvas.height };
    if (
      Math.abs(this.state.mouse.p.x - this.state.firstMousePoint.x) < 60 &&
      this.state.isMouseDown
    ) {
      this.drawPoly(ctx, [v1, v2]);
    } else {
      this.drawPoly(ctx, [v1, v2], 'rgba(64, 155, 197, 1)');
    }
    if (
      Math.abs(this.state.mouse.p.y - this.state.firstMousePoint.y) < 60 &&
      this.state.isMouseDown
    ) {
      this.drawPoly(ctx, [h1, h2]);
    } else {
      this.drawPoly(ctx, [h1, h2], 'rgba(64, 155, 197, 1)');
    }
  }

  imageBrowserDownload(canvas, imageUri) {
    let fileOutputLink = document.createElement('a');

    let filename = 'output' + Date.now() + '.png';
    filename = window.prompt('Insert output filename', filename);
    if (!filename) return;

    fileOutputLink.setAttribute('download', filename);
    fileOutputLink.href = imageUri;
    fileOutputLink.style.display = 'none';
    document.body.appendChild(fileOutputLink);
    setTimeout(() => {
      fileOutputLink.click();
    }, 100);
    document.body.removeChild(fileOutputLink);
    this.setState({
      isModalVisible: false,
      imageUri: '',
      image: ''
    });
    canvas.style.zIndex = -1;
    document.removeEventListener('keydown', this.onKeyDown, false);
    document.removeEventListener(
      'mouseup',
      this.onMouseUp_On_New_Save_SQ_DA,
      false
    );
    canvas.style.display = 'none';
  }

  makeCroppedImage(ctx, canvas) {
    // clear canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.state.croppedImage, 0, 0, canvas.width, canvas.height);

    let croppedCanvas = document.createElement('canvas');
    let croppedCtx = croppedCanvas.getContext('2d');

    // Set width and height for the new croppedCanvas
    let heightAtt = document.createAttribute('height');
    heightAtt.value = Math.abs(
      this.state.mouse.p.y - this.state.firstMousePoint.y
    );
    croppedCanvas.setAttributeNode(heightAtt);

    let widthAtt = document.createAttribute('width');
    widthAtt.value = Math.abs(
      this.state.mouse.p.x - this.state.firstMousePoint.x
    );
    croppedCanvas.setAttributeNode(widthAtt);

    croppedCtx.fillStyle = 'white';
    croppedCtx.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);

    croppedCtx.drawImage(
      this.state.image,
      this.state.mouse.p.x - this.state.firstMousePoint.x < 0
        ? this.state.mouse.p.x
        : this.state.firstMousePoint.x,
      this.state.mouse.p.y - this.state.firstMousePoint.y < 0
        ? this.state.mouse.p.y
        : this.state.firstMousePoint.y,
      croppedCanvas.width,
      croppedCanvas.height,
      0,
      0,
      croppedCanvas.width,
      croppedCanvas.height
    );
    this.imageBrowserDownload(canvas, croppedCanvas.toDataURL());
  }

  setMousePoint(e, canvas, type) {
    let ctx = canvas.getContext('2d');
    let rectCanvas = canvas.getBoundingClientRect();
    let positionX =
      Math.round(((e.clientX - rectCanvas.left) / canvas.width) * 100 * 1000) /
      1000;
    let positionY =
      Math.round(((e.clientY - rectCanvas.top) / canvas.height) * 100 * 1000) /
      1000;
    switch (type) {
      case 'down':
        this.setState({
          firstMousePoint: {
            x: this.percentToPxHor(canvas, positionX),
            y: this.percentToPxVert(canvas, positionY)
          }
        });
        break;
      case 'move':
        this.setState({
          mouse: {
            p: {
              x: this.percentToPxHor(canvas, positionX),
              y: this.percentToPxVert(canvas, positionY)
            }
          }
        });
        break;
      case 'up':
        this.setState({
          lastMousePoint: {
            x: this.percentToPxHor(canvas, positionX),
            y: this.percentToPxVert(canvas, positionY)
          }
        });
        if (
          !(
            Math.abs(this.state.mouse.p.x - this.state.firstMousePoint.x) <
              60 ||
            Math.abs(this.state.mouse.p.y - this.state.firstMousePoint.y) < 60
          ) &&
          ctx !== undefined
        ) {
          this.makeCroppedImage(ctx, canvas);
        } else {
          this.setState({
            isModalVisible: false,
            imageUri: '',
            image: ''
          });
          canvas.style.zIndex = -1;
          document.removeEventListener('keydown', this.onKeyDown, false);
          document.removeEventListener(
            'mouseup',
            this.onMouseUp_On_New_Save_SQ_DA,
            false
          );
          canvas.style.display = 'none';
        }
        break;

      default:
        break;
    }
  }

  onMouseUp_On_New_Save_SQ_DA(e) {
    let canvas = document.getElementById('canvas_crop_image');
    switch (e.target.textContent) {
      case 'New':
      case 'Save':
      case 'Submit for Quote':
      case 'Designer Assistance':
        canvas.style.zIndex = -1;
        document.removeEventListener('keydown', this.onKeyDown, false);
        document.removeEventListener(
          'mouseup',
          this.onMouseUp_On_New_Save_SQ_DA,
          false
        );
        canvas.style.display = 'none';
        this.setState({
          isModalVisible: false,
          isMouseDown: false,
          imageUri: '',
          image: ''
        });
        break;
      default:
        break;
    }
    if (
      e.target.outerHTML.includes('review_quote.svg') ||
      e.target.outerHTML.includes('consult_designer.svg')
    ) {
      canvas.style.zIndex = -1;
      document.removeEventListener('keydown', this.onKeyDown, false);
      document.removeEventListener(
        'mouseup',
        this.onMouseUp_On_New_Save_SQ_DA,
        false
      );
      canvas.style.display = 'none';
      this.setState({
        isModalVisible: false,
        isMouseDown: false,
        imageUri: '',
        image: ''
      });
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 27) {
      // Escape
      let canvas = document.getElementById('canvas_crop_image');
      canvas.style.zIndex = -1;
      document.removeEventListener(
        'mouseup',
        this.onMouseUp_On_New_Save_SQ_DA,
        false
      );
      canvas.style.display = 'none';
      if (this.props.state.isCabinetDrawing) {
        this.context.projectActions.setIsCabinetDrawing(false);
      } else {
        this.props.setToolbar('');
      }
      this.setState({
        isModalVisible: false,
        isMouseDown: false,
        imageUri: '',
        image: ''
      });
    }
  }

  onMouseDown(e) {
    let canvas = document.getElementById('canvas_crop_image');
    this.setMousePoint(e, canvas, 'down');
    this.setState({ isMouseDown: true });
  }
  onMouseMove(e) {
    let canvas = document.getElementById('canvas_crop_image');
    let ctx = canvas.getContext('2d');
    this.setMousePoint(e, canvas, 'move');
    this.canvasUpdate(ctx, canvas);
  }
  onMouseUp(e) {
    let canvas = document.getElementById('canvas_crop_image');
    this.setMousePoint(e, canvas, 'up');
    this.setState({ isMouseDown: false });
  }

  openTab(name) {
    var i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' selected', '');
    }

    document.getElementById(name).style.display = 'flex';
    document.getElementById(name + '_link').className += ' selected';
  }

  // onMoveDown(e){
  //   e.preventDefault();
  //   this.context.itemsActions.setMoveStatus(true);
  //   document.getElementById('item_custom').style.display = 'none';
  // }

  // onMoveUp(e){
  //   e.preventDefault();
  //   this.context.itemsActions.setMoveStatus(false);
  // }

  // onRotateDown(e){
  //   e.preventDefault();
  //   this.context.itemsActions.setRotateStatus(true);
  //   document.getElementById('item_custom').style.display = 'none';
  //   let left = document.getElementById('item_custom').style.getPropertyValue('left');
  //   let top = document.getElementById('item_custom').style.getPropertyValue('top');
  //   document.getElementById('item_rotation').style.transform = 'translate(-40px, -30px)';
  //   document.getElementById('item_rotation').style.left = left;
  //   document.getElementById('item_rotation').style.top = top;
  //   document.getElementById('item_rotation').style.display = 'flex';
  // }

  // onRotateUp(e){
  //   e.preventDefault();
  // }

  getLinearOfMolding(molding, itemID) {
    let items = this.state.elements.items;
    let tmp = [];
    items.forEach(item => {
      tmp.push(item);
    });
    items = [...tmp];
    let MDArray = [];
    items.map(item => {
      if (
        (item.layoutpos === 'Base' && molding.molding_type.includes('Base')) ||
        (item.layoutpos === 'Wall' && molding.molding_type.includes('Wall')) ||
        (item.layoutpos === 'Tall' && molding.molding_type.includes('Tall'))
      ) {
        let layer = this.props.layers.get(this.props.selectedLayer);
        let allLines = GeometryUtils.getAllLines(layer);
        let allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
        let x = item.x;
        let y = item.y;
        let rotRad = (item.rotation / 180) * Math.PI;
        let w = item.properties.get('width').get('_length');
        let wUnit = item.properties.get('width').get('_unit') || 'cm';
        w = convert(w / 2)
          .from(wUnit)
          .to('cm');
        let h = item.properties.get('depth').get('_length');
        let hUnit = item.properties.get('depth').get('_unit') || 'cm';
        h = convert(h / 2)
          .from(hUnit)
          .to('cm');
        let mx = x - w * Math.cos(rotRad);
        let my = y - w * Math.sin(rotRad);
        let x0 = mx + h * Math.sin(rotRad);
        let y0 = my - h * Math.cos(rotRad);
        let x3 = mx * 2 - x0;
        let y3 = my * 2 - y0;
        let x1 = x * 2 - x3;
        let y1 = y * 2 - y3;
        let x2 = x * 2 - x0;
        let y2 = y * 2 - y0;
        if (
          item.layoutpos === 'Base' &&
          GeometryUtils.isSnappedLine(
            {
              rect: [
                { x: x0, y: y0 },
                { x: x1, y: y1 },
                { x: x2, y: y2 },
                { x: x3, y: y3 }
              ]
            },
            allLineRects
          )
        )
          return false;
        if (
          (item.layoutpos === 'Wall' || item.layoutpos === 'Tall') &&
          !GeometryUtils.isSnappedLine(
            {
              rect: [
                { x: x0, y: y0 },
                { x: x1, y: y1 },
                { x: x2, y: y2 },
                { x: x3, y: y3 }
              ]
            },
            allLineRects
          )
        )
          return false;
        let lines = getLinesOfItem(item, allLineRects, this.context.catalog);
        let newMD = createMDFromItem(item, lines, molding);
        let extMD = false;
        let extMDIndex = -1;
        let tryMergeMD = function () {
          extMDIndex = MDArray.findIndex(el => {
            extMD = tryAdjacentMD(newMD, el, molding);
            return extMD != false;
          });
          return extMDIndex > -1;
        };
        while (tryMergeMD()) {
          MDArray.splice(extMDIndex, 1);
          newMD = extMD;
        }
        newMD.lines = getLinesOverLap(
          newMD,
          items,
          allLineRects,
          this.context.catalog
        );
        if (newMD.lines.length !== 0) {
          MDArray.push(newMD);
        }
      }
    });
    MDArray = MDArray.filter(MD => {
      if (MD.items.findIndex(item => item.id === itemID) > -1) {
        return true;
      }
      return false;
    });
    if (MDArray.length === 0) {
      return false;
    }
    return MDArray[0];
  }

  render() {
    let {
      props: {
        state,
        width,
        height,
        content,
        handleSaveDesign,
        handleSubmitForQuote,
        handleDesignerAssist
      },
      context: {
        projectActions,
        viewer2DActions,
        linesActions,
        viewer3DActions,
        translator,
        areaActions,
        itemsActions
      }
    } = this;
    const {
      cabinetCategory,
      subCabinetCategory,
      selectedFinishing,
      cabinetStyleChanging,
      cabinetStyle,
      subCabinetStyle,
      currentTexture,
      ceilingHeight,
      measurementUnit,
      viewMoreDoorstyle,
      selectedRoomElement,
      checkedRoomElement,
      searchName,
      chosenReplacedItem,
      isShowProperty,
      helpViewNum,
      notes,
      videoUrl,
      moldingData
    } = this.state;
    let scene = state.get('scene');
    let layerID = scene.get('selectedLayer');

    var panel;
    let dirUrl = '';
    let mode = state.get('mode');

    let tt = this;
    this.state.counterTop.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let counterTop = layers.get('counterTop');
      data.selected = (counterTop && counterTop.uri) == data.texture;
    });

    let userInfo = [];
    if (this.props.match) userInfo = this.props.match.params;

    let update = (LengthInput, unit, type) => {
      let newLength = toFixedFloat(LengthInput);

      if (type === 0) {
        this.setState({ ceilingHeight: newLength });
        viewer2DActions.updateCeilHeight(newLength);
        viewer2DActions.updateCeilHeightUnit(unit._unit);
        viewer3DActions.update3DCeilHeight(newLength);
        viewer3DActions.update3DCeilHeightUnit(unit._unit);
      } else {
        this.setState({
          ceilingHeight: convert(newLength)
            .from(this.state.measurementUnit._unit)
            .to(unit._unit),
          measurementUnit: unit
        });
        viewer2DActions.updateCeilHeight(
          convert(newLength)
            .from(this.state.measurementUnit._unit)
            .to(unit._unit)
        );
        viewer2DActions.updateCeilHeightUnit(unit._unit);
        viewer3DActions.update3DCeilHeightUnit(unit._unit);
        viewer3DActions.update3DCeilHeight(
          convert(newLength)
            .from(this.state.measurementUnit._unit)
            .to(unit._unit)
        );
      }
    };

    const openReplacedItemToolbar = (oldItems, newItems) => {
      const newItemsIds = Object.keys(newItems);
      const oldItemIds = Object.keys(oldItems);
      const replacedItemId = newItemsIds.find(id => !oldItemIds.includes(id));
      // closes the setting dialog
      document.getElementById('setting_dialog').style.display = 'none';

      itemsActions.selectItem(layerID, replacedItemId);
    };

    this.state.backsplash.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let backsplash = layers.get('backsplash');
      data.selected = (backsplash && backsplash.uri) == data.texture;
    });

    this.state.molding.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let molding = layers.get('molding');
      data.selected =
        molding &&
        molding.some(m => {
          return m.name === data.name;
        });
    });

    this.state.wallColors.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let wallColor = layers.get('wallColor');
      data.selected = wallColor == data.color;
    });

    this.state.floorStyles.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let floorStyles = layers.get('floorStyle');
      data.selected = (floorStyles && floorStyles.uri) == data.texture;
    });

    this.state.doorHandle.forEach(data => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let doorHandle = layers.get('doorHandle');
      data.selected = doorHandle && data.gltf.includes(doorHandle);
    });

    let alterate = state.get('alterate');
    let alterateColor = alterate ? SharedStyle.MATERIAL_COLORS[500].orange : '';
    let select = this.state.activeButton;
    let topRightImg = '/assets/img/svg/topbar/edit_inactive.svg';
    let topBtnAction =
      state.mode == MODE_IDLE || state.mode == MODE_2D_PAN
        ? () => linesActions.selectToolDrawingLine('wall')
        : () => {
            projectActions.setMode(MODE_IDLE);
            linesActions.selectToolDrawingLine('wall');
          };

    let saveScreenshotToFile = event => {
      event.preventDefault();
      let canvas = document.getElementsByTagName('canvas')[1];
      let canvas_crop_image = document.getElementById('canvas_crop_image');
      let ctx = canvas_crop_image.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = canvas.height;
      canvas_crop_image.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = canvas.width;
      canvas_crop_image.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas_crop_image.width, canvas_crop_image.height);

      let img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = canvas.toDataURL();

      img.onload = () => {
        ctx.globalAlpha = 0.3;
        ctx.drawImage(
          img,
          0,
          0,
          canvas_crop_image.width,
          canvas_crop_image.height
        );
        this.setState({
          isModalVisible: true,
          imageUri: canvas.toDataURL(),
          image: img
        });
        canvas_crop_image.style.zIndex = 11;
        document.addEventListener('keydown', this.onKeyDown, false);
        document.addEventListener(
          'mouseup',
          this.onMouseUp_On_New_Save_SQ_DA,
          false
        );
        canvas_crop_image.style.display = 'block';
      };
    };

    const makeSKUForMagento = element => {
      // console.log(element.toJS());
      if (element.category.toLowerCase().includes('molding')) {
        element.type = element.name;
      }
      let _element = this.props.catalog.elements[element.type];
      if (!_element)
        _element =
          this.props.catalog.elements[
            returnReplaceableDeepSearchType(element.type)
          ];
      if (!_element) {
        return false;
      }
      let doorColor = element.doorStyle;
      if (element.doorStyle.size) doorColor = element.doorStyle.toJS();
      let elementSKU = '';
      elementSKU = _element && _element.obj.sku_number;
      let tempSKU = _element.obj.skuArray.find(
        el => el.door_color_id === doorColor.id
      );
      if (tempSKU !== undefined) elementSKU = tempSKU.sku;
      //Check special case. ex: BBC36-R, BBLC39-42-36-L
      if (elementSKU.endsWith('-R') || elementSKU.endsWith('-L'))
        elementSKU = elementSKU.slice(0, -2);
      if (doorColor.sku) elementSKU += `-${doorColor.sku}`;
      // console.log(doorColor.isAssembled)
      if (doorColor.isAssembled) elementSKU += `-Assembled`;
      return elementSKU;
    };

    let saveSVGScreenshotToFile = async event => {
      event.preventDefault();

      // First of all I need the svg content of the viewer
      let svgElements = document.getElementsByTagName('svg');

      // I get the element with max width (which is the viewer)
      let maxWidthSVGElement = svgElements[0];
      for (let i = 1; i < svgElements.length; i++) {
        if (
          svgElements[i].width.baseVal.value >
          maxWidthSVGElement.width.baseVal.value
        ) {
          maxWidthSVGElement = svgElements[i];
        }
      }

      // Begin: Change Image tags' href to local data src
      let images = maxWidthSVGElement.getElementsByTagName('image');

      let readBlob = b => {
        return new Promise(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };
          reader.onerror = () => {
            res(b);
          };
          reader.readAsDataURL(b);
        });
      };

      try {
        for (let image of images) {
          const response = await fetch(
            getSignedUrl(image.getAttribute('href'))
          );
          const blob = await response.blob();
          const content = await readBlob(blob);
          image.setAttribute('href', content);
        }
      } catch (e) {}
      // End: Change Image tags' href to local data src

      let serializer = new XMLSerializer();

      let img = new Image();

      // I create the new canvas to draw
      // let canvas = document.createElement('canvas');
      let canvas = document.getElementById('canvas_crop_image');
      let ctx = canvas.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = maxWidthSVGElement.height.baseVal.value;
      canvas.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = maxWidthSVGElement.width.baseVal.value;
      canvas.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      img.crossOrigin = 'anonymous';
      img.src = `data:image/svg+xml;base64,${window.btoa(
        serializer.serializeToString(maxWidthSVGElement)
      )}`;

      img.onload = () => {
        ctx.globalAlpha = 0.3;
        ctx.drawImage(
          img,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );
        this.setState({
          isModalVisible: true,
          imageUri: canvas.toDataURL(),
          image: img
        });
      };
      canvas.style.zIndex = 11;
      document.addEventListener('keydown', this.onKeyDown, false);
      document.addEventListener(
        'mouseup',
        this.onMouseUp_On_New_Save_SQ_DA,
        false
      );
      canvas_crop_image.style.display = 'block';
    };

    // control button active remove//
    let control = this.state.controlButton;
    let topControl = this.state.topButton;
    // /////////////////////////////
    // let control = '';
    // added component//////
    let objectProperty = '';
    // let item_custom = '';
    // let item_rotation = '';
    let item_warning = '';
    let layer = state.scene.layers.get(state.scene.selectedLayer);
    let selected = [];
    layer.selected.forEach((data, key) => {
      if (key == 'vertices') return;
      if (data.size > 0) {
        let values = data.toJS();
        for (let i = 0; i < values.length; i++) {
          selected.push({ type: key, id: values[i] });
        }
      }
    });

    if (mode.indexOf('DRAW') != -1) {
      // hintText = (<S.hintText>Press ESC key to finish drawing!</S.hintText>);
      hintText = (
        <StyledAlert
          text={
            <span style={{ fontSize: 16, fontFamily: DEFAULT_FONT_FAMILY }}>
              Press <strong>Enter</strong> key to finish drawing, or{' '}
              <strong>ESC</strong> to cancel
            </span>
          }
          onClose={() => (hintText = '')}
        />
      );
      // if (mode.indexOf("DRAW") != -1) {
      //   hintText = <StyledAlert text = "Hit ESC to complete the current wall" />;
    } else if (this.state.isModalVisible) {
      hintText = (
        <StyledAlert
          text={
            <span style={{ fontSize: 16, fontFamily: DEFAULT_FONT_FAMILY }}>
              Press <strong>ESC</strong> key to stop clipping!
            </span>
          }
          onClose={() => (hintText = '')}
        />
      );
    } else {
      hintText = '';
    }
    if (selected.length == 1) {
      let selData = selected[0];
      let currentObject;
      switch (selData.type) {
        case 'holes':
          currentObject = layer.holes.get(selData.id);
          break;
        case 'items':
          currentObject = layer.items.get(selData.id);
          break;
        case 'lines':
          currentObject = layer.lines.get(selData.id);
          break;
      }
      if (currentObject != undefined) {
        let elementName = currentObject.type;
        let select = this.props.catalog.elements[elementName];
        let replaceCabinetData;
        if (!select)
          select = this.props.catalog.elements[elementName.toLowerCase()];
        if (!select)
          select =
            this.props.catalog.elements[
              returnReplaceableDeepSearchType(elementName)
            ];
        if (!select)
          select =
            this.props.catalog.elements[
              returnReplaceableDeepSearchType(elementName.toLowerCase())
            ];
        if (select) {
          const attrPorpSeparatorStyle = {
            position: 'relative',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          };
          const headActionStyle = {
            position: 'absolute',
            right: '0em',
            top: '0.15em'
          };
          if (select.prototype === 'items') {
            this.props.categoryData.data.cabinets.map(value => {
              value.items.map(category => {
                if (category.name == select.obj.cabinet_category) {
                  replaceCabinetData = category.items;
                }
              });
            });
            var objSKU = select.obj.sku_number;
            if (
              this.state.currentTexture !== null &&
              select.obj.skuArray.length !== 0
            ) {
              let doorStyle = currentObject.doorStyle,
                dcId;
              if (doorStyle instanceof Map) {
                dcId = currentObject.doorStyle.get('id');
              } else {
                dcId = currentObject.doorStyle.id;
              }
              var tempSKU = select.obj.skuArray.find(
                el => el.door_color_id === dcId
              );
              if (tempSKU !== undefined) {
                objSKU = tempSKU.sku;
              }
            }
            let isFiller =
              select.long_name.toUpperCase().includes('FILLER') ||
              select.long_name.toUpperCase().includes('PANEL');
            let tempWidth = currentObject.properties.get('width');
            let tempDepth = currentObject.properties.get('depth');
            let tempHeight = currentObject.properties.get('height');
            let itemHeight = select.info.sizeinfo.height;
            let itemWidth = select.info.sizeinfo.width;
            let itemDepth = select.info.sizeinfo.depth;
            let _currentObject = currentObject.toJS();
            if (_currentObject.doorStyle.doorStyles !== undefined) {
              if (
                _currentObject.doorStyle.doorStyles.is_euro_cds !== undefined
              ) {
                if (
                  _currentObject.doorStyle.doorStyles.is_euro_cds &&
                  _currentObject.doorStyle.door_style_name ===
                    'Euro & Frameless'
                ) {
                  tempWidth = new Map({
                    _length: _currentObject.doorStyle.doorStyles.euro_width,
                    _unit:
                      _currentObject.doorStyle.doorStyles.width_unit.substr(
                        0,
                        2
                      )
                  });
                  tempDepth = new Map({
                    _length: _currentObject.doorStyle.doorStyles.euro_length,
                    _unit:
                      _currentObject.doorStyle.doorStyles.length_unit.substr(
                        0,
                        2
                      )
                  });
                  tempHeight = new Map({
                    _length: _currentObject.doorStyle.doorStyles.euro_height,
                    _unit:
                      _currentObject.doorStyle.doorStyles.height_unit.substr(
                        0,
                        2
                      )
                  });
                  itemHeight = tempHeight.get('_length');
                  itemWidth = tempWidth.get('_length');
                  itemDepth = tempDepth.get('_length');
                }
              }
            }
            let doorStyleThumbnail, doorStyleName, installation;
            if (_currentObject.doorStyle.thumbnail)
              doorStyleThumbnail = _currentObject.doorStyle.thumbnail;
            if (_currentObject.doorStyle.name)
              doorStyleName = _currentObject.doorStyle.name;
            // if(currentTexture) installation = currentTexture.install;
            installation = _currentObject.doorStyle.install;
            let width = tempWidth
              ? convert(tempWidth.get('_length'))
                  .from(tempWidth.get('_unit'))
                  .to('in')
                  .toFixed(2)
              : itemWidth;
            if (tempWidth.get('_unit') === 'in') width = itemWidth;
            let height = tempHeight
              ? convert(tempHeight.get('_length'))
                  .from(tempHeight.get('_unit'))
                  .to('in')
                  .toFixed(2)
              : itemHeight;
            if (tempHeight.get('_unit') === 'in') height = itemHeight;
            let depth = tempDepth
              ? convert(tempDepth.get('_length'))
                  .from(tempDepth.get('_unit'))
                  .to('in')
                  .toFixed(2)
              : itemDepth;
            if (tempDepth.get('_unit') === 'in') depth = itemDepth;
            let products = this.props.products;
            let doorColor = currentObject.doorStyle;
            if (currentObject.doorStyle.size)
              doorColor = currentObject.doorStyle.toJS();

            const isAssembled = (categoryData, doorColorId) => {
              if (categoryData.success) {
                let assembledItems = categoryData.data.doorStyles.items.find(
                  it => it.name === 'Pre-assembled'
                ).items;
                for (let idx in assembledItems) {
                  let res = assembledItems[idx].items.find(
                    it => it.id === doorColorId
                  );
                  if (res) return true;
                }
                return false;
              }

              return false;
            };

            let itemSKU = makeSKUForMagento(currentObject);

            let product = products[itemSKU];

            const shouldHideSkuNumber =
              select.type === 'appliance' ||
              select.type === 'furnishing' ||
              select.type === 'lighting' ||
              (select.long_name.toLowerCase().includes('wall') &&
                select.type !== 'cabinet');

            // item_custom=(
            //   <S.ItemCustomImageWrapper id='item_custom'>
            //     <S.ItemCustomImage src='/assets/img/svg/3d_item_rotation.svg' onMouseDown={this.onRotateDown} onMouseUp={this.onRotateUp}/>
            //     <S.ItemCustomImage src='/assets/img/svg/3d_item_move.svg' style={{marginTop: 10}} onMouseDown={this.onMoveDown} onMouseUp={this.onMoveUp} />
            //   </S.ItemCustomImageWrapper>
            // )

            // let start = this.props.state.rotatingSupport.get("originRotation");
            // let end = this.props.layers.get(this.props.selectedLayer).items.get(this.props.layers.get(this.props.selectedLayer).selected.items.toJS()[0]).rotation;
            // const degtorad = Math.PI / 180.00;
            // let diff = (end - start) % 360;
            // if(diff <0){
            //   diff += 360;
            // }
            // const clockWise = diff <= 180 ? 0 : 1;

            // item_rotation=(
            //   <S.ItemCustomImageWrapper id='item_rotation'>
            //     <svg height={80} width={80} onMouseUp={this.onRotateUp}>
            //       <circle cx={40} cy={40} r={35} fill="none" stroke="white" strokeWidth={5}></circle>
            //       <path transform="translate(40,40)"
            //         fill="none"
            //         stroke={SECONDARY_PURPLE_COLOR}
            //         strokeWidth={3}
            //         d={`M ${Math.cos(end * degtorad) * 35} ${(-1) * Math.sin(end * degtorad) * 35} A 35 35 0 ${clockWise} 1 ${Math.cos(start * degtorad) * 35} ${(-1) * Math.sin(start * degtorad)* 35}`} />
            //     </svg>
            //   </S.ItemCustomImageWrapper>
            // )

            item_warning = (
              <S.ItemCustomImageWrapper id="item_warning">
                <S.ItemCustomImage
                  src="/assets/img/svg/3d_item_warning_info.svg"
                  onMouseOver={() =>
                    (document.getElementById('warning_box').style.display =
                      'flex')
                  }
                  onMouseOut={() =>
                    (document.getElementById('warning_box').style.display =
                      'none')
                  }
                />
                <S.WarningItem id="warning_box">
                  <S.SubCategoryItemImage
                    maskImage="/assets/img/svg/3d_item_warning_info.svg"
                    style={{
                      backgroundColor: BG_COLOR_1,
                      marginRight: 10,
                      height: 30,
                      width: 30
                    }}
                  />
                  This item is not available in the current door style. Replace
                  it with another one.
                </S.WarningItem>
                <S.ItemCustomImage
                  src="/assets/img/svg/3d_item_warning_edit.svg"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    this.props.setToolbar('door-style');
                    this.setState({ cabinetStyleChanging: true });
                  }}
                />
              </S.ItemCustomImageWrapper>
            );
            objectProperty =
              this.props.replaceCabinetFlag == true ? (
                <PS.ContentObjectWrapper
                  headerH={this.props.headerH}
                  style={{ width: 'fit-content', height: '100%' }}
                >
                  <S.SubToolbarWrapper
                    style={{
                      alignItems: 'flex-start',
                      marginLeft: 20,
                      minWidth: 250
                    }}
                  >
                    <PS.DesignValue
                      style={{ margin: 0, fontSize: 20, fontWeight: 700 }}
                    >
                      Replace Cabinet
                    </PS.DesignValue>
                    <S.SubCategoryItemWrapper
                      style={{
                        overflowY: 'scroll',
                        maxHeight: 'calc(100vh - 320px)'
                      }}
                    >
                      {replaceCabinetData ? (
                        replaceCabinetData.map((item, key) => {
                          return (
                            (currentTexture?.doorStyles?.cds ?? []).filter(
                              cd => cd.itemID === item.itemID
                            ).length != 0 && (
                              <S.SubCategoryItem
                                onClick={() => {
                                  let selectedItemId = this.props.layers
                                    .get(this.props.selectedLayer)
                                    .selected.items.toJS()[0];
                                  let selectedPos = {
                                    x: this.props.layers
                                      .get(this.props.selectedLayer)
                                      .items.get(selectedItemId).x,
                                    y: this.props.layers
                                      .get(this.props.selectedLayer)
                                      .items.get(selectedItemId).y,
                                    rotation: this.props.layers
                                      .get(this.props.selectedLayer)
                                      .items.get(selectedItemId).rotation,
                                    selectedItemId: selectedItemId
                                  };
                                  this.setState({
                                    chosenReplacedItem: {
                                      selectedPos: selectedPos,
                                      select: select,
                                      item: item
                                    }
                                  });
                                }}
                                style={{
                                  cursor: 'pointer',
                                  paddingLeft: 0,
                                  backgroundColor:
                                    chosenReplacedItem.item &&
                                    chosenReplacedItem.item.itemID ===
                                      item.itemID &&
                                    '#D9E8FF'
                                }}
                                key={key}
                              >
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={item.thumbnail}
                                />
                                <S.SubCategoryItemLabel
                                  style={{ textAlign: 'left' }}
                                >
                                  {item.long_name} <br />
                                  Width: {item.info.width}" <br />
                                  Depth: {item.info.height}" <br />
                                  Height: {item.info.tall}"
                                </S.SubCategoryItemLabel>
                              </S.SubCategoryItem>
                            )
                          );
                        })
                      ) : (
                        <S.SubCategoryItemLabel>
                          No options found
                        </S.SubCategoryItemLabel>
                      )}
                    </S.SubCategoryItemWrapper>
                  </S.SubToolbarWrapper>
                  <PS.ButtonBox
                    style={{
                      marginTop: 20,
                      marginLeft: 0,
                      opacity: !chosenReplacedItem.item && '0.6',
                      cursor: !chosenReplacedItem.item && 'not-allowed'
                    }}
                    onClick={() => {
                      if (chosenReplacedItem.item) {
                        const oldItems = this.state.elements.items.toJS();
                        const { selectedPos, select, item } =
                          chosenReplacedItem;
                        itemsActions.replaceItem(selectedPos, select, item);
                        this.props.replaceCabinet(false);
                        this.setState({ chosenReplacedItem: {} }, () => {
                          const newItems = this.state.elements.items.toJS();
                          openReplacedItemToolbar(oldItems, newItems);
                        });
                      }
                    }}
                  >
                    <img
                      height={20}
                      width={20}
                      src="/assets/img/svg/check.svg"
                    />
                    <PS.ButtonTitle
                      style={{
                        color: SECONDARY_PURPLE_COLOR,
                        paddingLeft: 10,
                        margin: 0,
                        fontSize: 16
                      }}
                    >
                      Replace
                    </PS.ButtonTitle>
                  </PS.ButtonBox>
                </PS.ContentObjectWrapper>
              ) : (
                <PS.ContentObjectWrapper
                  headerH={this.props.headerH}
                  style={{ height: '100%' }}
                >
                  <div style={{ display: 'flex' }}>
                    <PS.TitleBarIconButton
                      onClick={() => {
                        this.setState({ isShowProperty: false });
                      }}
                    >
                      <img
                        style={{
                          float: 'left',
                          height: 20
                        }}
                        src="/assets/img/svg/bottombar/collapse.svg"
                      />
                    </PS.TitleBarIconButton>
                    <PS.TitleBarIconButton
                      onClick={() => projectActions.unselectAll()}
                    >
                      <img
                        style={{
                          float: 'right',
                          height: 20,
                          transform: 'rotate(45deg)'
                        }}
                        src="/assets/img/svg/bottombar/arrow-plus.svg"
                      />
                    </PS.TitleBarIconButton>
                  </div>
                  <div style={attrPorpSeparatorStyle}>
                    <PS.ButtonBox
                      style={{ width: '100%', justifyContent: 'center' }}
                    >
                      <img
                        height={20}
                        width={20}
                        src="/assets/img/svg/copy.svg"
                      />
                      <PS.ButtonTitle
                        style={{
                          color: SECONDARY_PURPLE_COLOR,
                          paddingLeft: 10,
                          margin: 0,
                          fontSize: 16
                        }}
                        onClick={e =>
                          itemsActions.duplicateSelected(currentObject)
                        }
                      >
                        {' Duplicate'}
                      </PS.ButtonTitle>
                    </PS.ButtonBox>
                    <PS.ButtonBox style={{ width: '100%' }}>
                      <PS.ButtonTitle
                        style={{ color: STATUS_NEGATIVE_COLOR, fontSize: 16 }}
                        onClick={e => projectActions.remove()}
                      >
                        {'Delete'}
                      </PS.ButtonTitle>
                    </PS.ButtonBox>
                  </div>
                  <div
                    style={{
                      overflowY: 'scroll',
                      paddingRight: 5,
                      height: 'calc(100% - 85px)'
                    }}
                  >
                    {!shouldHideSkuNumber && (
                      <PS.DesignValue
                        style={{ margin: 0, marginTop: '15px' }}
                      >{`SKU: ${select.obj.sku_number}`}</PS.DesignValue>
                    )}
                    {/* <p style={{display:"flex"}}> */}
                    {/* <PS.PropteryValue>{doorStyleName}</PS.PropteryValue> */}
                    {/* </p> */}
                    <PS.SelectedBox
                      style={{ position: 'relative', margin: '15px 0' }}
                    >
                      <img
                        src={select.info.image}
                        style={{ objectFit: 'contain' }}
                      />
                      {doorStyleThumbnail && select.type === 'cabinet' && (
                        <img
                          src={doorStyleThumbnail}
                          style={{ objectFit: 'contain', padding: '28px' }}
                        />
                      )}
                      {select.type === 'cabinet' &&
                        (currentTexture?.doorStyles?.cds ?? []).filter(
                          cd => cd.itemID === select.itemID
                        ).length === 0 && (
                          <div
                            style={{
                              position: 'absolute',
                              width: 'calc(50% - 10px)',
                              height: 'calc(100% - 10px)',
                              backgroundColor: 'rgba(232,187,47,0.7)',
                              left: 0,
                              borderRadius: '10px',
                              border: '3px solid orange',
                              margin: 5
                            }}
                          />
                        )}
                      {select.type === 'cabinet' &&
                        (currentTexture?.doorStyles?.cds ?? []).filter(
                          cd => cd.itemID === select.itemID
                        ).length === 0 && (
                          <img
                            src="/assets/img/svg/3d_item_warning_edit.svg"
                            onClick={() => {
                              this.props.replaceCabinet(true);
                            }}
                            style={{
                              position: 'absolute',
                              left: 15,
                              top: 15,
                              width: 30,
                              height: 30,
                              backgroundColor: 'white',
                              borderRadius: 15,
                              cursor: 'pointer'
                            }}
                          />
                        )}
                      {/* <PS.Image><div><img src={select.info.image} /></div></PS.Image> */}
                      {/* <PS.IconButton style={{ 'margin': '10px', 'height': '24px' }}><FaTrash /> Delete</PS.IconButton>
                    <PS.IconButton style={{ 'margin': '10px', 'height': '24px' }}><FaCopy /> Duplicate</PS.IconButton> */}
                    </PS.SelectedBox>
                    <PS.PropteryValue1 style={{ fontWeight: 600 }}>
                      {select.long_name}
                    </PS.PropteryValue1>
                    <PS.PropteryValue style={{ fontWeight: 400 }}>
                      W{' '}
                      <strong>
                        {convert(width)
                          .from('in')
                          .to(measurementUnit._unit)
                          .toFixed(2)}
                      </strong>
                      {measurementUnit._unit === 'in'
                        ? '"'
                        : measurementUnit._unit}{' '}
                      x D{' '}
                      <strong>
                        {convert(depth)
                          .from('in')
                          .to(measurementUnit._unit)
                          .toFixed(2)}
                      </strong>
                      {measurementUnit._unit === 'in'
                        ? '"'
                        : measurementUnit._unit}{' '}
                      x H{' '}
                      <strong>
                        {convert(height)
                          .from('in')
                          .to(measurementUnit._unit)
                          .toFixed(2)}
                      </strong>
                      {measurementUnit._unit === 'in'
                        ? '"'
                        : measurementUnit._unit}
                    </PS.PropteryValue>
                    {select.type === 'cabinet' && (
                      <PS.PropteryValue1>
                        Door Style: <strong>{doorStyleName}</strong>
                      </PS.PropteryValue1>
                    )}
                    {select.type === 'cabinet' && (
                      <PS.PropteryValue1>
                        Installation: <strong>{installation}</strong>
                      </PS.PropteryValue1>
                    )}
                    {product && (
                      <React.Fragment>
                        <PS.DesignValue
                          style={{
                            fontSize: 16,
                            margin: '10px 0',
                            fontWeight: 600,
                            marginBottom: 0
                          }}
                        >
                          Pricing
                        </PS.DesignValue>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 5
                          }}
                        >
                          <PS.PropteryValue2>Regular Price: </PS.PropteryValue2>
                          <PS.PropteryValue2
                            style={{
                              color: TEXT_COLOR_NEUTRAL_0,
                              textDecoration:
                                product.discounted_price !== null
                                  ? 'line-through'
                                  : 'none',
                              width: 'unset',
                              fontWeight: 600
                            }}
                          >
                            {`$${product.regular_price}`}
                          </PS.PropteryValue2>
                        </span>
                        {product.discounted_price !== null ? (
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <PS.PropteryValue2>
                              Discounted Price:{' '}
                            </PS.PropteryValue2>
                            <PS.PropteryValue2
                              style={{
                                color: 'green',
                                width: 'unset',
                                fontWeight: 600
                              }}
                            >{`$${product.discounted_price}`}</PS.PropteryValue2>
                          </span>
                        ) : null}
                      </React.Fragment>
                    )}
                    {/* <PS.PropteryValue>Door Style: <span>{select.obj.door_style}</span></PS.PropteryValue>
                  <PS.PropteryValue>Manufacturer: <span>{select.obj.manufacturer}</span></PS.PropteryValue>
                  <PS.PropteryValue>Marterial: <span>Ground Pringles</span></PS.PropteryValue> */}
                    {isFiller && this.state.editWidthVisible && (
                      <PS.ChildTitle1>
                        <FormNumberInput
                          style={{
                            width: 88,
                            height: 24.95,
                            marginTop: 10
                          }}
                          value={width}
                          onChange={event =>
                            this.handleEditWidth(
                              event.target.value,
                              layerID,
                              selData.id
                            )
                          }
                          precision={2}
                        />
                      </PS.ChildTitle1>
                    )}
                    <PS.DesignValue>Design</PS.DesignValue>
                    {/* <PS.PositionValue>Rotate</PS.PositionValue>
                  <PS.PositionValue>Resize</PS.PositionValue> */}
                    <PanelElementEditor
                      state={state}
                      categoryData={this.props.categoryData}
                      getLinearOfMolding={this.getLinearOfMolding}
                    />
                  </div>
                </PS.ContentObjectWrapper>
              );
          } else if (select.prototype === 'holes') {
            objectProperty = (
              <PS.ContentObjectWrapper headerH={this.props.headerH}>
                <div style={{ display: 'flex' }}>
                  <PS.TitleBarIconButton
                    onClick={() => {
                      this.setState({ isShowProperty: false });
                    }}
                  >
                    <img
                      style={{
                        float: 'left',
                        height: 20
                      }}
                      src="/assets/img/svg/bottombar/collapse.svg"
                    />
                  </PS.TitleBarIconButton>
                  <PS.TitleBarIconButton
                    onClick={() => projectActions.unselectAll()}
                  >
                    <img
                      style={{
                        float: 'right',
                        height: 20,
                        transform: 'rotate(45deg)'
                      }}
                      src="/assets/img/svg/bottombar/arrow-plus.svg"
                    />
                  </PS.TitleBarIconButton>
                </div>
                <div style={attrPorpSeparatorStyle}>
                  <PS.ButtonBox
                    style={{ width: '100%', justifyContent: 'center' }}
                  >
                    <img
                      height={20}
                      width={20}
                      src="/assets/img/svg/copy.svg"
                    />
                    <PS.ButtonTitle
                      style={{
                        color: '${SECONDARY_PURPLE_COLOR}',
                        margin: 0,
                        paddingLeft: 10,
                        fontSize: 16
                      }}
                      onClick={e =>
                        itemsActions.duplicateSelected(currentObject)
                      }
                    >
                      {' Duplicate'}
                    </PS.ButtonTitle>
                  </PS.ButtonBox>
                  <PS.ButtonBox style={{ width: '100%' }}>
                    <PS.ButtonTitle
                      style={{ color: 'red', fontSize: 16 }}
                      onClick={e => projectActions.remove()}
                    >
                      {'Delete'}
                    </PS.ButtonTitle>
                  </PS.ButtonBox>
                </div>
                <PS.SelectedBox style={{ margin: 0 }}>
                  <img
                    src={select.info.image}
                    style={{ objectFit: 'contain' }}
                  />
                </PS.SelectedBox>
                <PS.PropteryValue style={{ marginTop: 10 }}>
                  {select.name}
                </PS.PropteryValue>
                <PS.ChildTitle> </PS.ChildTitle>
                {/* <PS.PropteryValue>Base Price: <span>122$</span></PS.PropteryValue>
                <PS.PropteryValue>Door Style: <span>{select.obj.door_style}</span></PS.PropteryValue>
                <PS.PropteryValue>Manufacturer: <span>{select.obj.manufacturer}</span></PS.PropteryValue>
                <PS.PropteryValue>Marterial: <span>Ground Pringles</span></PS.PropteryValue> */}
                <PS.DesignValue>Design</PS.DesignValue>
                {/* <PS.PositionValue>Rotate</PS.PositionValue>
                <PS.PositionValue>Resize</PS.PositionValue> */}
                <PanelElementEditor
                  state={state}
                  categoryData={this.props.categoryData}
                  getLinearOfMolding={this.getLinearOfMolding}
                />
              </PS.ContentObjectWrapper>
            );
          } else if (select.prototype === 'lines') {
            objectProperty = (
              <PS.ContentObjectWrapper headerH={this.props.headerH}>
                <div style={{ display: 'flex' }}>
                  <PS.TitleBarIconButton
                    onClick={() => {
                      this.setState({ isShowProperty: false });
                    }}
                  >
                    <img
                      style={{
                        float: 'left',
                        height: 20
                      }}
                      src="/assets/img/svg/bottombar/collapse.svg"
                    />
                  </PS.TitleBarIconButton>
                  <PS.TitleBarIconButton
                    onClick={() => {
                      if (mode === MODE_DRAWING_LINE) {
                        projectActions.rollback();
                      }
                      projectActions.unselectAll();
                    }}
                  >
                    <img
                      style={{
                        float: 'right',
                        height: 20,
                        transform: 'rotate(45deg)'
                      }}
                      src="/assets/img/svg/bottombar/arrow-plus.svg"
                    />
                  </PS.TitleBarIconButton>
                </div>
                <div style={attrPorpSeparatorStyle}>
                  <PS.ButtonBox style={{ width: '50%', marginLeft: '50%' }}>
                    <PS.ButtonTitle
                      style={{ color: 'red', fontSize: 16 }}
                      onClick={e => projectActions.remove()}
                    >
                      {'Delete'}
                    </PS.ButtonTitle>
                  </PS.ButtonBox>
                </div>

                <PS.SelectedBox style={{ margin: 0 }}>
                  <img
                    src={select.info.image.default}
                    style={{ objectFit: 'contain' }}
                  />
                </PS.SelectedBox>
                <PS.PropteryValue style={{ marginTop: 10 }}>
                  {select.name}
                </PS.PropteryValue>
                <PS.ChildTitle>
                  <span style={{ fontWeight: 600 }}>
                    {select.info.description}
                  </span>
                </PS.ChildTitle>
                {/* <PS.PropteryValue>Base Price: <span>122$</span></PS.PropteryValue>
                <PS.PropteryValue>Door Style: <span>{select.obj.door_style}</span></PS.PropteryValue>
                <PS.PropteryValue>Manufacturer: <span>{select.obj.manufacturer}</span></PS.PropteryValue>
                <PS.PropteryValue>Marterial: <span>Ground Pringles</span></PS.PropteryValue> */}
                <PS.DesignValue>Design</PS.DesignValue>
                {/* <PS.PositionValue>Rotate</PS.PositionValue>
                <PS.PositionValue>Resize</PS.PositionValue> */}
                <PanelElementEditor
                  state={state}
                  categoryData={this.props.categoryData}
                  getLinearOfMolding={this.getLinearOfMolding}
                />
                <div style={{ height: 30 }} />
              </PS.ContentObjectWrapper>
            );
          }
        }
      }
    }

    let sorter = [
      {
        key: 1,
        index: 1,
        condition: true,
        dom: (
          <FloorToolbarButton
            closeAction={this.props.closeFloorTB}
            trigger={
              <ToolbarButton
                active={select === MAKE_FLOOR_PLAN}
                tooltip={translator.t(MAKE_FLOOR_PLAN)}
                title={translator.t(MAKE_FLOOR_PLAN)}
                title1={[]}
                title2={[]}
                src={'/assets/img/svg/toolbar/make_floorplan_active.svg'}
                onClick={event => {
                  // projectActions.setMode(MODE_IDLE);
                  this.props.openFloor();
                  projectActions.removeDrawingSupport();
                  this.setState({ floorSubCategoryData: [] });
                  this.setState({ activeButton: MAKE_FLOOR_PLAN });
                }}
              >
                <img
                  id="make_floorplan_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/make_floorplan_inactive.svg"
                />
              </ToolbarButton>
            }
            props={this.props}
            drawAction={topBtnAction}
            dataSource={this.props.catalog.getCategory('root')}
            subData={this.state.floorSubCategoryData}
            action={this.getFloor.bind(this)}
            categoryCollapse={this.state.floorCategoryCollapse}
            productCollapse={this.state.floorProductCollapse}
            collapseAction={this.collapseAction.bind(this)}
            mode={mode}
            isOpen={this.props.floorOpened}
          />
        )
      },
      {
        key: 2,
        index: 2,
        condition: true,
        dom: (
          <CabinetDoorStyleToolbarButton
            closeAction={() => {
              setTimeout(() => {
                this.props.toggleDoorStyle(false);
              }, 150);
            }}
            trigger={
              <ToolbarButton
                active={select === SELECT_DOOR_STYLE}
                tooltip={translator.t(SELECT_DOOR_STYLE)}
                title={translator.t(SELECT_DOOR_STYLE)}
                title1={[]}
                title2={[]}
                src={'/assets/img/svg/toolbar/select_doorstyle_active.svg'}
                onClick={event => {
                  this.props.toggleDoorStyle(!this.props.doorStyleOpen);
                  projectActions.removeDrawingSupport();
                  this.setState({
                    doorStyleSubCategoryData: [],
                    doorStyleStep: 0,
                    stepSource: this.state.DoorStyles,
                    doorStyleExpand: -1
                  });
                  // this.setState({ activeButton: SELECT_DOOR_STYLE });
                }}
              >
                <img
                  id="select_doorstyle_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/select_doorstyle_inactive.svg"
                />
              </ToolbarButton>
            }
            step={this.state.doorStyleStep}
            mode={mode}
            props={this.props}
            dataSource={[]}
            stepSource={this.state.stepSource}
            subData={this.state.doorStyleSubCategoryData}
            action={this.getStyleCategory.bind(this)}
            chooseAction={this.getStyle.bind(this)}
            categoryCollapse={this.state.doorStyleCategoryCollapse}
            productCollapse={this.state.doorStyleProductCollapse}
            isExpand={this.state.doorStyleExpand}
            expandAction={this.expandDoorStyleAction.bind(this)}
            collapseAction={this.collapseAction.bind(this)}
            currentTexture={this.state.currentTexture}
            recentStyle={this.state.recentStyle}
            selectFromRecent={this.selectFromRecent.bind(this)}
            selected={this.selected.bind(this)}
            isOpen={this.props.doorStyleOpen}
            // onUpdate={e => this.onUpdate(e)}
          />
        )
      },
      {
        key: 3,
        index: 3,
        condition: true,
        dom: (
          <CabinetToolbarButton
            closeAction={this.props.closeCabinetTB}
            action={this.getItem.bind(this)}
            chooseAction={this.setFinishing.bind(this)}
            subData={this.state.cabinetSubCategoryData}
            dataSource={this.state.vData}
            dData={this.state.oData}
            bFlag={this.state.vDataArray.length}
            props={this.props}
            isExpand={this.state.cabinetExpand}
            expandAction={this.expandCabinetAction.bind(this)}
            cabinetCollapse={this.state.cabinetCollapse}
            doorCollapse={this.state.doorCollapse}
            doorExpand={this.state.doorExpand}
            collapseAction={this.collapseAction.bind(this)}
            searchCabinet={this.state.searchCabinet}
            search={this.onSearch.bind(this)}
            mode={mode}
            isOpen={this.props.cabinetOpened}
            trigger={
              <ToolbarButton
                id={'cabinet_toolbar'}
                active={select === ADD_CABINETS}
                tooltip={translator.t(ADD_CABINETS)}
                title={translator.t(ADD_CABINETS)}
                title1={this.state.cabinetTitle}
                title2={this.state.doorTitle}
                src={'/assets/img/svg/toolbar/add_cabinets_active.svg'}
                onClick={event => {
                  this.props.openCabinet();
                  this.setState({
                    cabinetSubCategoryData: [],
                    cabinetTitle: [],
                    doorTitle: [],
                    cabinetExpand: -1,
                    isMolding: false
                  });
                }}
              >
                <img
                  id="add_cabinets_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/add_cabinets_inactive.svg"
                />
              </ToolbarButton>
            }
          />
        )
      },
      {
        key: 4,
        index: 4,
        condition: true,
        dom: (
          <ApplianceToolbarButton
            closeAction={this.props.closeApplianceTB}
            action={this.getAppliance.bind(this)}
            subData={this.state.applianceSubCategoryData}
            dataSource={this.state.appliancesData}
            dData={this.state.oData}
            bFlag={this.state.vDataArray.length}
            props={this.props}
            isExpand={this.state.applianceExpand}
            expandAction={this.expandApplianceAction.bind(this)}
            cabinetCollapse={this.state.cabinetCollapse}
            doorCollapse={this.state.doorCollapse}
            doorExpand={this.state.doorExpand}
            collapseAction={this.collapseAction.bind(this)}
            searchCabinet={this.state.searchCabinet}
            search={this.onSearch.bind(this)}
            // categoryCollapse={this.state.applianceCategoryCollapse}
            mode={mode}
            isOpen={this.props.applianceOpened}
            applyMaterial={this.applyMaterial.bind(this)}
            trigger={
              <ToolbarButton
                active={select === ADD_APPLIANCES}
                tooltip={translator.t(ADD_APPLIANCES)}
                title={translator.t(ADD_APPLIANCES)}
                title1={this.state.cabinetTitle}
                title2={this.state.doorTitle}
                src={'/assets/img/svg/toolbar/add_appliances_active.svg'}
                onClick={event => {
                  this.props.openAppliance();
                  projectActions.removeDrawingSupport();
                  this.context.projectActions.setIsCabinetDrawing(true);
                  this.setState({
                    applianceSubCategoryData: [],
                    cabinetTitle: [],
                    doorTitle: [],
                    applianceExpand: -1
                  });
                  this.setState({ activeButton: ADD_APPLIANCES });
                }}
              >
                <img
                  id="add_appliances_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/add_appliances_inactive.svg"
                />
              </ToolbarButton>
            }
          />
        )
      },
      {
        key: 5,
        index: 5,
        condition: true,
        dom: (
          <FinishingTouchToolbarButton
            closeAction={this.props.closeFinishingTB}
            dataSource={finishingtouchData}
            collapseAction={this.collapseAction.bind(this)}
            action={this.getFinishing.bind(this)}
            chooseAction={this.setFinishing.bind(this)}
            subData={this.state.finishingSubCategoryData}
            categoryCollapse={this.state.finishingCategoryCollapse}
            productCollapse={this.state.finishingProductCollapse}
            mode={mode}
            isOpen={this.props.finishingOpened}
            props={this.props}
            applyMaterial={this.applyMaterial.bind(this)}
            trigger={
              <ToolbarButton
                active={select === FINISHING_TOUCH}
                tooltip={translator.t(FINISHING_TOUCH)}
                title={translator.t(FINISHING_TOUCH)}
                title1={[]}
                title2={[]}
                src={'/assets/img/svg/toolbar/finishing_touches_active.svg'}
                onClick={event => {
                  this.props.openFinishing();
                  projectActions.removeDrawingSupport();
                  this.setState({ finishingSubCategoryData: [] });
                  // this.setState({ activeButton: FINISHING_TOUCH });
                }}
              >
                <img
                  id="finishing_touches_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/finishing_touches_inactive.svg"
                />
              </ToolbarButton>
            }
          />
        )
      },
      {
        key: 6,
        index: 6,
        condition: true,
        dom: (
          <ReviewQuoteToolbarButton
            isOpened={this.props.reviewQuotePopupOpened}
            mode={mode}
            onClose={() => {
              this.props.onReviewQuoteClicked(false);
            }}
            trigger={
              <ToolbarButton
                active={select === REVIEW_AND_QUOTE}
                tooltip={translator.t(REVIEW_AND_QUOTE)}
                title={translator.t(REVIEW_AND_QUOTE)}
                src={'/assets/img/svg/toolbar/review_quote_active.svg'}
                onClick={event => {
                  this.props.onReviewQuoteClicked(
                    !this.props.reviewQuotePopupOpened
                  );
                  projectActions.removeDrawingSupport();
                  this.setState({ activeButton: REVIEW_AND_QUOTE });
                }}
              >
                <img
                  id="review_quote_inactive"
                  width="75px"
                  src="/assets/img/svg/toolbar/review_quote_inactive.svg"
                />
              </ToolbarButton>
            }
            dataSource={this.state.elements.items}
            products={this.props.products}
            categoryData={this.props.categoryData}
            catalog={this.props.catalog}
            collapse={this.state.reviewCollapse}
            collapseAction={this.collapseAction.bind(this)}
            handleSaveDesign={handleSaveDesign}
            handleSubmitForQuote={handleSubmitForQuote}
            handleDesignerAssist={handleDesignerAssist}
          />
        )
      }
    ];

    let plgUrls = this.state.plgUrls;
    for (let i = 0; i < plgUrls.length; i++) {
      dirUrl += '>';
      dirUrl += plgUrls[i];
    }
    let beforeItem;
    let plgItems;
    if (this.state.filter == '') plgItems = this.state.plgItems;
    else {
      plgItems = [];
      for (let i = 0; i < this.state.plgItems.length; i++) {
        if (this.state.plgItems[i].name.indexOf(this.state.filter) != -1)
          plgItems.push(this.state.plgItems[i]);
      }
    }
    if (plgUrls.length > 1)
      beforeItem = (
        <PlgItem
          url="before"
          gltfUrl={null}
          onClick={this.plgItemAction.bind(this)}
          name="..."
        />
      );
    if (this.state.opened) {
      let multiMark = String.fromCharCode(215); //x Mark
      panel = (
        <S.PanelWrapper>
          <div>
            <div style={{ backgroundColor: SharedStyle.TOOLBAR_COLOR.active }}>
              <S.Title>
                <b>choose room features</b>
              </S.Title>
              <S.Head>
                <S.Close>
                  <a
                    onClick={this.PanelClose.bind(this)}
                    className="roundButton"
                    onMouseOut={event => this.setState({ closeover: false })}
                    onMouseOver={event => this.setState({ closeover: true })}
                  >
                    <span>{multiMark}</span>
                  </a>
                </S.Close>
              </S.Head>
              <div style={{ clear: 'both' }} />
            </div>

            <input
              value={this.state.filter}
              onChange={this.filterChange.bind(this)}
              placeholder="filter"
              style={searchInput}
              type="text"
            />
            <p style={{ float: 'left', fontSize: '80%', paddingLeft: '8px' }}>
              <b>{'features' + dirUrl}</b>
            </p>
            <div style={{ clear: 'both' }} />
            <div style={{ padding: '0 12px' }}>
              {beforeItem}
              {plgItems.map((data, key) => {
                return (
                  <PlgItem
                    url={data.url}
                    gltfUrl={data.gltf_url}
                    onClick={this.plgItemAction.bind(this)}
                    name={data.name}
                    key={key}
                  />
                );
              })}
            </div>
          </div>
        </S.PanelWrapper>
      );
    }
    if (this.state.animOpened) {
      let multiMark = String.fromCharCode(215);
      panel = (
        <div
          style={{
            position: 'fixed',
            zIndex: 9,
            top: '615px',
            left: '10px',
            marginLeft: '100px',
            width: '310px',
            backgroundColor: SharedStyle.TOOLBAR_COLOR.focus
          }}
        >
          <div>
            <div style={{ backgroundColor: SharedStyle.TOOLBAR_COLOR.active }}>
              <p
                style={{
                  float: 'left',
                  fontSize: '80%',
                  paddingLeft: '8px',
                  color: 'white'
                }}
              >
                <b>Animation</b>
              </p>
              <p
                style={{
                  display: 'inline-block',
                  float: 'right',
                  paddingRight: '12px',
                  fontSize: '80%'
                }}
              >
                <a
                  onClick={this.PanelAnimClose.bind(this)}
                  className="roundButton"
                  onMouseOut={event => this.setState({ closeover: false })}
                  onMouseOver={event => this.setState({ closeover: true })}
                  style={{
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                    color: this.state.closeover ? 'black' : 'white',
                    backgroundColor: SharedStyle.MATERIAL_COLORS['500'].red,
                    cursor: 'pointer',
                    display: 'inline-block',
                    textAlign: 'center'
                  }}
                >
                  <span style={{ fontSize: '15px', lineHeight: '15px' }}>
                    {multiMark}
                  </span>
                </a>
              </p>
              <div style={{ clear: 'both' }} />
            </div>
            <div style={{ marginTop: '15px' }}>
              <select
                value={this.state.animValue}
                onChange={this.setAnimValue.bind(this)}
                style={searchInput}
              >
                {this.state.animData.map((data, key) => {
                  return (
                    <option key={key} value={key}>
                      {data.name + ' animation'}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                padding: '0 12px',
                textAlign: 'center',
                marginTop: '15px'
              }}
            >
              <a
                onMouseOut={event => this.setState({ animover: false })}
                onMouseOver={event => this.setState({ animover: true })}
                style={{
                  color: this.state.animover ? 'white' : 'black',
                  textAlign: 'center'
                }}
              >
                <FaPlayCircle
                  onClick={this.Animate.bind(this)}
                  style={{ fontSize: '40px' }}
                />
              </a>
            </div>
          </div>
        </div>
      );
    }

    // control buttons of right side

    let regexp = new RegExp(`^-?([0-9]+)?\\.?([0-9]{0,2})?$`);

    let settingDialog = (
      <PS.SettingDialog id="setting_dialog" headerH={this.props.headerH}>
        <PS.TitleBarIconButton
          onClick={() => {
            document.getElementById('setting_dialog').style.display = 'none';
            // projectActions.unselectAll();
          }}
        >
          <img
            style={{ float: 'right', height: 35, transform: 'rotate(45deg)' }}
            src="/assets/img/svg/bottombar/arrow-plus.svg"
          />
        </PS.TitleBarIconButton>
        <S.Title>Settings</S.Title>
        <S.NumberInputWrapper>
          <S.NumberInputTitle>Measurement Unit</S.NumberInputTitle>
          <S.MeasurementUnitSelect
            id="measurement_select"
            onClick={() => {
              document.getElementById('measurement_option').style.display =
                document.getElementById('measurement_option').style.display ===
                'none'
                  ? 'flex'
                  : 'none';
              document.getElementById('measurement_select').style.borderColor =
                SECONDARY_PURPLE_COLOR;
              document.getElementById('arrow-down').style.display =
                document.getElementById('arrow-down').style.display === 'none'
                  ? 'block'
                  : 'none';
              document.getElementById('arrow-up').style.display =
                document.getElementById('arrow-up').style.display === 'block'
                  ? 'none'
                  : 'block';
            }}
          >
            {`${measurementUnit._name}(${
              measurementUnit._unit !== 'in' ? measurementUnit._unit : '"'
            })`}
          </S.MeasurementUnitSelect>
          <S.SelectArrow
            id="arrow-down"
            style={{ display: 'block' }}
            maskImage="/assets/img/svg/bottombar/arrow-down.svg"
          />
          <S.SelectArrow
            id="arrow-up"
            style={{ display: 'none' }}
            maskImage="/assets/img/svg/bottombar/arrow-up.svg"
          />
          <div
            id="measurement_option"
            style={{
              display: 'none',
              flexDirection: 'column',
              width: 150,
              zIndex: 3,
              top: 55,
              right: 0,
              position: 'absolute',
              borderRadius: 5,
              backgroundColor: 'white',
              boxShadow:
                '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)'
            }}
          >
            {unitData.map((unit, key) => {
              return (
                <S.MeasurementUnitOption
                  onClick={event => {
                    document.getElementById(
                      'measurement_option'
                    ).style.display = 'none';
                    document.getElementById('arrow-down').style.display =
                      'block';
                    document.getElementById('arrow-up').style.display = 'none';
                    update(ceilingHeight, unit, 1);
                  }}
                  key={key}
                >
                  {`${unit._name}(${unit._unit})`}
                </S.MeasurementUnitOption>
              );
            })}
          </div>
        </S.NumberInputWrapper>
        <S.NumberInputWrapper>
          <S.NumberInputTitle>Ceiling Height</S.NumberInputTitle>
          <S.CeilingHeight
            value={ceilingHeight}
            type="text"
            onChange={evt => {
              if (regexp.test(evt.target.value))
                update(event.target.value, measurementUnit, 0);
              else update(ceilingHeight, measurementUnit, 0);
            }}
          />
          <span
            style={{
              position: 'absolute',
              color: TEXT_COLOR_NEUTRAL_2,
              fontSize: 16,
              fontFamily: DEFAULT_FONT_FAMILY,
              fontWeight: 400,
              lineHeight: '22px',
              textAlign: 'left',
              right: 15
            }}
          >
            {measurementUnit._unit === 'in'
              ? `${
                  measurementUnit._unit !== 'in' ? measurementUnit._unit : '"'
                }(default)`
              : measurementUnit._unit}
          </span>
        </S.NumberInputWrapper>
        <S.Title style={{ fontSize: 14 }}>Show/Hide Measurements</S.Title>
        <S.ToggleWrapper
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <S.ToggleLabel>Wall length</S.ToggleLabel>
          <ToggleMeasureButton
            style={{ marginRight: 10, height: 30 }}
            key={'S/H Measure'}
            showMeasure={this.props.state.scene.showWallLengthMeasure}
            active={control === 'show/hide toggle measure '}
            settingMode={mode}
            state={this.props.state}
            backgroundImage={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWallLengthMeasure === 0
                    ? '2d3d_toggle.svg'
                    : '2d3d_toggle_active.svg'
                }`}
                style={{ width: '55px', height: '30px' }}
              />
            }
            onClick={() => {
              if (!this.props.layers.get(this.props.selectedLayer).areas.size) {
                this.setState({
                  snackBarMessage: 'No Floor plan',
                  isSnackBarOpen: true
                });
                return;
              }
              viewer2DActions.changeWallLengthMeasure(
                1 - this.props.state.scene.get('showWallLengthMeasure')
              );
            }}
            icon={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWallLengthMeasure === 0
                    ? '2d3d_button.svg'
                    : '2d3d_button_active.svg'
                }`}
                style={{ width: '30px', height: '30px' }}
              />
            }
          />
        </S.ToggleWrapper>
        <S.ToggleWrapper
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <S.ToggleLabel>Base cabinets</S.ToggleLabel>
          <ToggleMeasureButton
            style={{ marginRight: 10, height: 30 }}
            key={'S/H Measure'}
            active={control === 'show/hide toggle measure '}
            settingMode={mode}
            showMeasure={this.props.state.scene.showBaseCabinetMeasure}
            backgroundImage={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showBaseCabinetMeasure === 0
                    ? '2d3d_toggle.svg'
                    : '2d3d_toggle_active.svg'
                }`}
                style={{ width: '55px', height: '30px' }}
              />
            }
            onClick={() => {
              if (!this.props.layers.get(this.props.selectedLayer).areas.size) {
                this.setState({
                  snackBarMessage: 'No Floor plan',
                  isSnackBarOpen: true
                });
                return;
              }
              viewer2DActions.changeBaseCabinetMeasure(
                1 - this.props.state.scene.get('showBaseCabinetMeasure')
              );
            }}
            icon={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showBaseCabinetMeasure === 0
                    ? '2d3d_button.svg'
                    : '2d3d_button_active.svg'
                }`}
                style={{ width: '30px', height: '30px' }}
              />
            }
          />
        </S.ToggleWrapper>
        <S.ToggleWrapper
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <S.ToggleLabel>Wall cabinets</S.ToggleLabel>
          <ToggleMeasureButton
            style={{ marginRight: 10, height: 30 }}
            key={'S/H Measure'}
            active={control === 'show/hide toggle measure '}
            settingMode={mode}
            showMeasure={this.props.state.scene.showWallCabinetMeasure}
            backgroundImage={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWallCabinetMeasure === 0
                    ? '2d3d_toggle.svg'
                    : '2d3d_toggle_active.svg'
                }`}
                style={{ width: '55px', height: '30px' }}
              />
            }
            onClick={() => {
              if (!this.props.layers.get(this.props.selectedLayer).areas.size) {
                this.setState({
                  snackBarMessage: 'No Floor plan',
                  isSnackBarOpen: true
                });
                return;
              }
              viewer2DActions.changeWallCabinetMeasure(
                1 - this.props.state.scene.get('showWallCabinetMeasure')
              );
            }}
            icon={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWallCabinetMeasure === 0
                    ? '2d3d_button.svg'
                    : '2d3d_button_active.svg'
                }`}
                style={{ width: '30px', height: '30px' }}
              />
            }
          />
        </S.ToggleWrapper>
        <S.ToggleWrapper
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <S.ToggleLabel>Windows & Doors</S.ToggleLabel>
          <ToggleMeasureButton
            style={{ marginRight: 10, height: 30 }}
            key={'S/H Measure'}
            active={control === 'show/hide toggle measure '}
            settingMode={mode}
            showMeasure={this.props.state.scene.showWindowDoorMeasure}
            backgroundImage={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWindowDoorMeasure === 0
                    ? '2d3d_toggle.svg'
                    : '2d3d_toggle_active.svg'
                }`}
                style={{ width: '55px', height: '30px' }}
              />
            }
            onClick={() => {
              if (!this.props.layers.get(this.props.selectedLayer).areas.size) {
                this.setState({
                  snackBarMessage: 'No Floor plan',
                  isSnackBarOpen: true
                });
                return;
              }
              viewer2DActions.changeWindowDoorMeasure(
                1 - this.props.state.scene.get('showWindowDoorMeasure')
              );
            }}
            icon={
              <img
                src={`/assets/img/svg/bottombar/${
                  this.props.state.scene.showWindowDoorMeasure === 0
                    ? '2d3d_button.svg'
                    : '2d3d_button_active.svg'
                }`}
                style={{ width: '30px', height: '30px' }}
              />
            }
          />
        </S.ToggleWrapper>
      </PS.SettingDialog>
    );

    // let videoModal = (
    //   <PS.VideoModal
    //     id="videoModal"
    //     onClick={()=>{
    //       if(event.target.tagName !== 'VIDEO') {
    //         document.getElementById('videoModal').style.display = 'none';
    //         this.setState({videoUrl: ""});
    //       }
    //     }}
    //   >
    //     <div className='modal-body'>
    //       <ReactPlayer
    //         url={videoUrl}
    //         width="600px"
    //         controls
    //         loop
    //         muted={true}
    //       />
    //     </div>
    //   </PS.VideoModal>
    // );

    // let helpDialog = '';
    // helpDialog = this.props.state.isHelp ? (
    //   <PS.HelpDialog id="help_dialog">
    //     <div style={{overflowY: 'auto', maxHeight: 'calc(100vh - 230px)', padding: '10px'}}>
    //       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //         <PS.HCTitle>Help Center</PS.HCTitle>
    //         <PS.Support onClick={()=>{this.setState({helpViewNum: 3})}}><img src='/assets/img/svg/lefttoolbar/room-shape-custom.svg' />Tech Support</PS.Support>
    //       </div>
    //       {helpViewNum === 1 ? <div>
    //         <PS.Video>
    //           <div style={{backgroundImage: 'url("/assets/img/png/helper/video_preview_start.png")', backgroundSize:'cover',width: '100%', height: '150px', borderRadius: '10px'}}>
    //             <div style={{backgroundImage:'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))', width: '100%', height: '100%', borderRadius: '10px'}} />
    //           </div>
    //           <PS.VideoText>
    //             <PS.VideoTitle>How to get Started</PS.VideoTitle>
    //             <PS.VideoTag>Posted 2 days ago</PS.VideoTag>
    //           </PS.VideoText>
    //           <PS.VideoButton onClick={()=>{this.setState({videoUrl: "/assets/001.Welcome-To-The-Course.mp4"}); document.getElementById("videoModal").style.display = 'flex';}}><img src='/assets/img/svg/lefttoolbar/room-shape-custom.svg' style={{width: '100%', height: '100%'}} /></PS.VideoButton>
    //         </PS.Video>
    //         <PS.HCCard>
    //           <PS.HCCardTitle>Getting Started Tutorial</PS.HCCardTitle>
    //           <PS.HCLable>The introductory tutorial showing you the bare bones of the DIY Design Software</PS.HCLable>
    //           <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //         </PS.HCCard>
    //         <PS.HCSubTitle>How can we help?</PS.HCSubTitle>
    //         <PS.SearchBox>
    //           <PS.SearchInput
    //             placeholder="Search for help"
    //           />
    //           <PS.SearchIcon><img src='/assets/img/svg/help/search.svg' /></PS.SearchIcon>
    //         </PS.SearchBox>
    //         <PS.HCSubTitle>Tutorials</PS.HCSubTitle>
    //         <PS.HCHr/>
    //         <PS.Video>
    //           <div style={{backgroundImage: 'url("/assets/img/png/helper/video_preview_3d.png")', backgroundSize:'cover',width: '100%', height: '150px', borderRadius: '10px'}}>
    //             <div style={{backgroundImage:'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))', width: '100%', height: '100%', borderRadius: '10px'}} />
    //           </div>
    //           <PS.VideoText>
    //             <PS.VideoTitle>3D Viewport</PS.VideoTitle>
    //             <PS.VideoTag>Posted 2 days ago</PS.VideoTag>
    //           </PS.VideoText>
    //           <PS.VideoButton onClick={()=>{this.setState({videoUrl: "/assets/002.A-Look-At-The-Main-App.mp4"}); document.getElementById("videoModal").style.display = 'flex';}}><img src='/assets/img/svg/lefttoolbar/room-shape-custom.svg' style={{width: '100%', height: '100%'}} /></PS.VideoButton>
    //         </PS.Video>
    //         <PS.HCCard>
    //           <PS.HCCardTitle>3D Tutorial</PS.HCCardTitle>
    //           <PS.HCLable>The tutorial showing you the fundamentals of 3D viewport feature.</PS.HCLable>
    //           <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //         </PS.HCCard>
    //         <PS.HCHr/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Layout Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you how to create layouts, dynamically and with presets.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //         <PS.HCHr/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Island Walls Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you how to create island walls.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //         <PS.HCHr/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Measurements Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you theMeasurementsFeature.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton onClick={()=>{this.setState({helpViewNum: 2})}}><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //         <PS.HCSubTitle>FAQ</PS.HCSubTitle>
    //         <PS.HCCollapse>
    //           <PS.HCHr/>
    //           <button className='collapsible' type="button" onClick={()=>{
    //               let coll = document.getElementsByClassName("collapsible");
    //               coll[0].classList.toggle("active");
    //               let content = coll[0].nextElementSibling;
    //               if (content.style.maxHeight){
    //                 content.style.maxHeight = null;
    //               } else {
    //                 content.style.maxHeight = content.scrollHeight + "px";
    //               }
    //             }}>
    //             <div style={{display: 'flex', justifyContent: 'space-between' }}>
    //               <span>Question here</span>
    //               <img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: SECONDARY_PURPLE_COLOR,  WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}}></img>
    //             </div>
    //           </button>
    //           <div className='content'>
    //             Answer displayed here... the tutorial showing you how to create layouts, dynamically and with presets...
    //           </div>
    //         </PS.HCCollapse>
    //         <PS.HCCollapse>
    //           <PS.HCHr/>
    //           <button className='collapsible' type="button" onClick={()=>{
    //               let coll = document.getElementsByClassName("collapsible");
    //               coll[1].classList.toggle("active");
    //               let content = coll[1].nextElementSibling;
    //               if (content.style.maxHeight){
    //                 content.style.maxHeight = null;
    //               } else {
    //                 content.style.maxHeight = content.scrollHeight + "px";
    //               }
    //             }}>
    //             <div style={{display: 'flex', justifyContent: 'space-between' }}>
    //               <span>Question here</span>
    //               <img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: SECONDARY_PURPLE_COLOR,  WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}}></img>
    //             </div>
    //           </button>
    //           <div className='content'>
    //             Answer displayed here... the tutorial showing you how to create layouts, dynamically and with presets...
    //           </div>
    //         </PS.HCCollapse>
    //         <PS.HCCollapse>
    //           <PS.HCHr/>
    //           <button className='collapsible' type="button" onClick={()=>{
    //               let coll = document.getElementsByClassName("collapsible");
    //               coll[2].classList.toggle("active");
    //               let content = coll[2].nextElementSibling;
    //               if (content.style.maxHeight){
    //                 content.style.maxHeight = null;
    //               } else {
    //                 content.style.maxHeight = content.scrollHeight + "px";
    //               }
    //             }}>
    //             <div style={{display: 'flex', justifyContent: 'space-between' }}>
    //               <span>Question here</span>
    //               <img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: SECONDARY_PURPLE_COLOR,  WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}}></img>
    //             </div>
    //           </button>
    //           <div className='content'>
    //             Answer displayed here... the tutorial showing you how to create layouts, dynamically and with presets...
    //           </div>
    //         </PS.HCCollapse>
    //       </div> : helpViewNum === 2 ? <div>
    //         <PS.TitleBarButton
    //           onClick={() => {
    //             this.setState({ helpViewNum: 1 });
    //           }}
    //         >
    //           <img style={{ paddingRight: 10 }} height="10px" src="/assets/img/svg/toolbar/undo_button.svg"/>
    //           Back
    //         </PS.TitleBarButton>
    //         <PS.HCTitle>Measurements Tutorial</PS.HCTitle>
    //         <PS.HCLable>Here you willfid how to use theMeasurement Tool</PS.HCLable>
    //         <PS.HCSubTitle>1. Defiition</PS.HCSubTitle>
    //         <PS.HCLable>Every object has8 red dots around it.Four of them are located on the edges and other four are between the edges</PS.HCLable>
    //         <PS.HCSubTitle>2. StartMeasuring</PS.HCSubTitle>
    //         <PS.HCLable>To start measuring, click on the starting point. It will turn dark blue.</PS.HCLable>
    //         <PS.HCSubTitle>3. Measure</PS.HCSubTitle>
    //         <PS.HCLable>To measure the distance click on the ending point.</PS.HCLable>
    //         <PS.HCSubTitle>4. Outcome</PS.HCSubTitle>
    //         <PS.HCLable>The measurement tool outputs 2 outcomes.</PS.HCLable>
    //         <PS.HCLable>The first outcome is when the angle between points is not big. You only see the distance between the points.</PS.HCLable>
    //         <img src='/assets/img/png/helper/outcome.png' style={{width: '100%'}}></img>
    //         <PS.HCLable>The second outcome is when the angle between points is bigger. You will see the x and y distance as well as the distance between points.</PS.HCLable>
    //         <PS.HCHr style={{marginTop: '50px'}}/>
    //         <PS.HCSubTitle>Related Tutorials</PS.HCSubTitle>
    //         <PS.HCHr style={{marginTop: '20px'}}/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Layout Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you how to create layouts, dynamically and with presets.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //         <PS.HCHr/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Island Walls Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you how to create island walls.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //         <PS.HCHr/>
    //         <PS.HCCard>
    //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <div>
    //               <PS.HCCardTitle>Measurements Tutorial</PS.HCCardTitle>
    //               <PS.HCLable>The tutorial showing you theMeasurementsFeature.</PS.HCLable>
    //               <PS.HCTag>Posted 2 days ago</PS.HCTag>
    //             </div>
    //           <PS.HCCardButton onClick={()=>{this.setState({helpViewNum: 2})}}><img style={{WebkitMaskImage: 'url(/assets/img/svg/help/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center', width: '20px', height: '20px'}} /></PS.HCCardButton>
    //           </div>
    //         </PS.HCCard>
    //       </div>: <div>
    //         <PS.TitleBarButton
    //           onClick={() => {
    //             this.setState({ helpViewNum: 1 });
    //           }}
    //         >
    //           <img style={{ paddingRight: 10 }} height="10px" src="/assets/img/svg/toolbar/undo_button.svg"/>
    //           Back
    //         </PS.TitleBarButton>
    //         <PS.HCTitle>Tech Support</PS.HCTitle>
    //         <PS.HCLable>Support text here.</PS.HCLable>
    //         <div style={{margin: '0 10px'}}>
    //           <PS.HCSelect>
    //             <div>Select Subject</div>
    //             <select defaultValue = "">
    //               <option key={0} value="" disabled>What issue do you occur</option>
    //               <option key={1} value="1">Item 1</option>
    //               <option key={2} value="2">Item 2</option>
    //               <option key={3} value="3">Item 3</option>
    //             </select>
    //           </PS.HCSelect>
    //           <PS.HCUpload>
    //             <div className='upload'>
    //               <label>
    //                 <p>Drag and drop files or <u>Browse</u></p>
    //                 <input
    //                   type="file"
    //                 />
    //               </label>
    //             </div>
    //             <div className='upload-label'>Please upload files under 5MB, other supporting text.</div>
    //           </PS.HCUpload>
    //           <div style={{ width: '100%', marginTop: 10 }}>
    //             <PS.HCTextArea
    //               value={notes}
    //               maxLength={200}
    //               placeholder="Describe your problem"
    //               onChange={e => this.setState({ notes: e.target.value })}
    //             />
    //             <span style={{ fontSize: 13, color: TEXT_COLOR_NEUTRAL_1, float: 'right', lineHeight: '18px', fontWeight: 400, marginRight: '20px' }} >{notes.length}/200 characters</span>
    //           </div>
    //         </div>
    //         <PS.HCCardButton style={{width: '130px'}}><p style={{margin: 'auto'}}>Submit Ticket</p></PS.HCCardButton>
    //       </div>}
    //     </div>
    //   </PS.HelpDialog>
    // ): '';

    // let tabDialog = (
    //   <PS.Tab>
    //     <PS.TopLine>
    //       <PS.TabLine>
    //         { objectProperty === '' ? null :
    //         <PS.TabLabel onClick={(e)=>{this.openTab('object'); projectActions.setIsHelp(false) }} id = "object_link" className={`tablinks ${helpDialog === ''? 'selected': ''}`}>
    //           <label>Object Properties</label>
    //           <img
    //             style={{
    //               float: 'right',
    //               height: 20,
    //               width: 20,
    //               transform: 'rotate(45deg)',
    //               WebkitMaskImage: 'url(/assets/img/svg/bottombar/arrow-plus.svg)',
    //               WebkitMaskSize: '100%',
    //               WebkitMaskPosition: 'center'
    //             }}
    //             onClick={(e) =>{e.stopPropagation(); projectActions.unselectAll(); helpDialog !== '' && this.openTab('help')}}
    //           />
    //         </PS.TabLabel>}
    //         {helpDialog !== '' ?
    //           <PS.TabLabel onClick={()=>this.openTab('help')} id = "help_link" className="tablinks selected">
    //             <label>Help Center</label>
    //             <img
    //               style={{
    //                 float: 'right',
    //                 height: 20,
    //                 width: 20,
    //                 transform: 'rotate(45deg)',
    //                 WebkitMaskImage: 'url(/assets/img/svg/bottombar/arrow-plus.svg)',
    //                 WebkitMaskSize: '100%',
    //                 WebkitMaskPosition: 'center'
    //               }}
    //               onClick={() => projectActions.setIsHelp(false)}
    //             />
    //           </PS.TabLabel> : null
    //         }
    //       </PS.TabLine>
    //       {helpDialog === '' && objectProperty === '' ? null :
    //         <PS.ToolLine>
    //           <PS.TitleBarIconButton>
    //             <img
    //               style={{
    //                 float: 'left',
    //                 height: 24,
    //                 marginLeft: 10
    //               }}
    //               onClick={() => {this.setState({isShowProperty: false})}}
    //               src="/assets/img/svg/bottombar/collapse.svg"
    //             />
    //           </PS.TitleBarIconButton>
    //           <PS.TitleBarIconButton>
    //             <img
    //               style={{
    //                 float: 'right',
    //                 height: 24,
    //                 transform: 'rotate(45deg)',
    //                 marginRight: 10
    //               }}
    //               onClick={() => {
    //                 if(mode === MODE_DRAWING_LINE){
    //                   projectActions.rollback();
    //                 }
    //                 projectActions.unselectAll();
    //                 projectActions.setIsHelp(false)
    //               }}
    //               src="/assets/img/svg/bottombar/arrow-plus.svg"
    //             />
    //           </PS.TitleBarIconButton>
    //         </PS.ToolLine>
    //       }
    //     </PS.TopLine>
    //     <div id="help" className='tabcontent' style={{display: helpDialog === ''?'none':'flex'}}>
    //       {helpDialog}
    //     </div>
    //     <div id="object" className='tabcontent' style={{display: `${helpDialog === ''? 'flex': 'none'}`}}>
    //       {objectProperty}
    //     </div>
    //   </PS.Tab>
    // )

    let controlView = !ARRAY_3D_MODES.includes(mode)
      ? [
          /**
           *undo/redo button
           */
          <div
            key={'section1'}
            onKeyDown={event => event.stopPropagation()}
            onKeyUp={event => event.stopPropagation()}
            style={{
              display: 'flex',
              position: 'absolute',
              right: 10,
              top: this.props.headerH + 10,
              zIndex: 10,
              maxHeight: 0
            }}
          >
            <div style={{ textAlign: 'right', display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  left: 10,
                  zIndex: 1,
                  flexDirection: 'row'
                }}
              >
                <S.RightButton
                  style={{
                    padding: '0px 18px',
                    marginLeft: 10,
                    marginRight: 'unset'
                  }}
                  onClick={() => projectActions.undo()}
                >
                  <S.RightButtonImage
                    src="/assets/img/svg/toolbar/undo_button.svg"
                    style={{ height: 10, width: 14 }}
                  />
                </S.RightButton>
                <S.RightButton
                  style={{
                    padding: '0px 18px',
                    marginLeft: 10,
                    marginRight: 'unset'
                  }}
                  onClick={() => projectActions.redo()}
                >
                  <S.RightButtonImage
                    src="/assets/img/svg/toolbar/redo_button.svg"
                    style={{ height: 10, width: 14 }}
                  />
                </S.RightButton>
              </div>

              <S.RightButton onClick={topBtnAction}>
                <S.RightButtonImage src="/assets/img/svg/lefttoolbar/room-shape-custom.svg" />
                <span
                  style={{
                    color: SECONDARY_PURPLE_COLOR,
                    fontFamily: DEFAULT_FONT_FAMILY,
                    textAlign: 'left',
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: '18px',
                    paddingLeft: 5
                  }}
                >
                  Add Walls
                </span>
              </S.RightButton>
              <S.RightButton
                onClick={saveSVGScreenshotToFile}
                style={{ padding: '0px 17px' }}
              >
                <S.RightButtonImage
                  src="/assets/img/svg/toolbar/take_picture.svg"
                  style={{ height: 14, width: 16 }}
                />
              </S.RightButton>
              {!isShowProperty ? (
                <S.RightButton
                  onClick={() => {
                    this.setState({ isShowProperty: true });
                  }}
                  style={{ padding: '0px 17px' }}
                >
                  <S.RightButtonImage
                    src="/assets/img/svg/bottombar/expand.svg"
                    style={{ height: 14, width: 16 }}
                  />
                </S.RightButton>
              ) : null}
            </div>
            {/* {isShowProperty ? tabDialog: null} */}
            {isShowProperty ? objectProperty : null}
            {settingDialog}
          </div>
        ]
      : [
          <div
            key={'section1'}
            onKeyDown={event => event.stopPropagation()}
            onKeyUp={event => event.stopPropagation()}
            style={{
              display: 'flex',
              position: 'absolute',
              right: 10,
              top: this.props.headerH + 10,
              zIndex: 10,
              maxHeight: 0
            }}
          >
            <div style={{ textAlign: 'right', display: 'flex' }}>
              <S.RightButton
                onClick={saveSVGScreenshotToFile}
                style={{ padding: '0px 16px' }}
              >
                <S.RightButtonImage src="/assets/img/svg/toolbar/take_picture.svg" />
              </S.RightButton>
              {!isShowProperty ? (
                <S.RightButton
                  onClick={() => {
                    this.setState({ isShowProperty: true });
                  }}
                  style={{ padding: '0px 17px' }}
                >
                  <S.RightButtonImage
                    src="/assets/img/svg/bottombar/expand.svg"
                    style={{ height: 14, width: 16 }}
                  />
                </S.RightButton>
              ) : null}
            </div>
            {/* {isShowProperty ? tabDialog: null} */}
            {isShowProperty ? objectProperty : null}
            {settingDialog}
          </div>
        ];

    let temp = [];

    cabinetCategory.data.forEach(type => {
      (type.type !== 'cabinet_category' ||
        type.name == 'Molding' ||
        type.items.filter(
          item =>
            (currentTexture?.doorStyles?.cds ?? []).filter(
              cd => cd.itemID === item.itemID
            ).length !== 0
        ).length !== 0) &&
        temp.push(
          <S.SubCategoryItem
            style={{
              paddingLeft: 50,
              backgroundColor:
                subCabinetCategory.name === type.name && TEXT_COLOR_NEUTRAL_5
            }}
            onClick={() => setSubData(type.name, type.items)}
          >
            <S.SubCategoryItemLabel
              style={{
                fontWeight: subCabinetCategory.name === type.name && 700
              }}
            >
              {type.name}
            </S.SubCategoryItemLabel>
          </S.SubCategoryItem>
        );
    });

    const finishingData = [
      {
        name: 'Handles',
        thumbnail: '/assets/img/svg/toolbar/handles.svg',
        data: this.props.categoryData.data.doorHandle
      },
      {
        name: 'Countertop',
        thumbnail: '/assets/img/svg/toolbar/countertop.svg',
        data: this.props.categoryData.data.counterTop
      },
      {
        name: 'Backsplash',
        thumbnail: '/assets/img/svg/toolbar/backsplash.svg',
        data: this.props.categoryData.data.backsplash
      },
      {
        name: 'Wall Color',
        thumbnail: '/assets/img/svg/toolbar/wall_color_icon.svg',
        data: this.props.categoryData.data.wallColors
      },
      {
        name: 'Floor Styles',
        thumbnail: '/assets/img/svg/toolbar/floorstyle.svg',
        data: this.props.categoryData.data.floorStyles
      },
      {
        name: 'Lighting',
        thumbnail: '/assets/img/svg/toolbar/lighting.svg',
        data: this.props.categoryData.data.lighting
      },
      {
        name: 'Furnishing',
        thumbnail: '/assets/img/svg/toolbar/furnishings_icon.svg',
        data: this.props.categoryData.data.furnishing
      }
    ];

    let roomShapeData = [
      {
        title: 'Square',
        url: '/assets/img/svg/lefttoolbar/room-shape-square.svg',
        data: rectangleData
      },
      {
        title: 'L-Shaped',
        url: '/assets/img/svg/lefttoolbar/room-shape-L.svg',
        data: lShaped
      },
      {
        title: 'Irregular',
        url: '/assets/img/svg/lefttoolbar/room-shape-irregular.svg',
        data: longNarrow
      },
      {
        title: 'Open',
        url: '/assets/img/svg/lefttoolbar/room-shape-open.svg',
        data: oRectangle
      },
      {
        title: 'Custom',
        url: '/assets/img/svg/lefttoolbar/room-shape-custom.svg'
      }
    ];
    let roomElementsData = [
      {
        title: 'Windows',
        url: '/assets/img/svg/lefttoolbar/window.svg',
        data: [window_clear, window_cross, window_double_hung, window_vertical]
      },
      {
        title: 'Doors',
        url: '/assets/img/svg/lefttoolbar/door.svg',
        data: [
          door_interior,
          door_exterior,
          door_closet,
          door_sliding,
          door_framed,
          door_frameless
        ]
      }
    ];

    const setSubData = (name, data) => {
      if (data.find(item => item.type === 'cabinet'))
        this.setState({
          subCabinetCategory: {
            name: subCabinetCategory.name === name ? '' : name,
            data: subCabinetCategory.data === data ? [] : data
          }
        });
      else
        this.setState({
          cabinetCategory: {
            name: cabinetCategory.name === name ? '' : name,
            data: cabinetCategory.data === data ? [] : data
          },
          subCabinetCategory: { name: '', data: [] }
        });
    };

    const setDoorStyleSubData = (name, data) => {
      if (data.find(item => item.type === 'door')) {
        let tmp = [];
        data.forEach(d => {
          tmp.push({ ...d, isAssembled: this.state.isAssembled });
        });
        this.setState({
          subCabinetStyle: {
            name: subCabinetStyle.name === name ? '' : name,
            data: subCabinetStyle.data === tmp ? [] : tmp
          }
        });
      } else {
        this.setState({
          isAssembled: name.includes('Pre-assembled') ? true : false,
          cabinetStyle: {
            name: cabinetStyle.name === name ? '' : name,
            data: cabinetStyle.data === data ? [] : data
          }
        });
      }
    };

    const setRoomElement = data => {
      this.context.projectActions.setIsCabinetDrawing(true);
      if (selectedRoomElement === 'Doors') {
        this.setState({
          checkedRoomElement: {
            ...checkedRoomElement,
            doors: data.item.default.info.description
          }
        });
      } else {
        this.setState({
          checkedRoomElement: {
            ...checkedRoomElement,
            windows: data.item.default.info.description
          }
        });
      }
    };

    let allCount = 0,
      usedObjects = {},
      allDiscountedPrices = 0,
      allRegularPrices = 0;

    const uniqueData = this.state.elements.items.filter(element => {
      if (element.itemID === undefined) return element;
      let elementSKU = makeSKUForMagento(element);

      if (!elementSKU) return false;

      if (elementSKU.startsWith('#')) return false; //Validate elementSKU
      allCount++;

      if (!usedObjects[elementSKU]) {
        usedObjects[elementSKU] = {
          ...usedObjects[elementSKU],
          count: 1,
          regularPrice: 0,
          disCountPrice: 0,
          sku_r: ''
        };
        if (this.props.products[elementSKU]) {
          usedObjects[elementSKU].regularPrice +=
            this.props.products[elementSKU].regular_price;
          usedObjects[elementSKU].disCountPrice +=
            this.props.products[elementSKU].discounted_price;
          usedObjects[elementSKU].sku_r = this.props.products[elementSKU].sku_r;
          allDiscountedPrices +=
            this.props.products[elementSKU].discounted_price;
          allRegularPrices += this.props.products[elementSKU].regular_price;
        }
        return element;
      } else {
        if (this.props.products[elementSKU]) {
          usedObjects[elementSKU].regularPrice +=
            this.props.products[elementSKU].regular_price;
          usedObjects[elementSKU].disCountPrice +=
            this.props.products[elementSKU].discounted_price;
          allRegularPrices += this.props.products[elementSKU].regular_price;
          allDiscountedPrices +=
            this.props.products[elementSKU].discounted_price;
        }
        usedObjects[elementSKU].count++;
      }
    });

    sessionStorage.setItem('usedObjects', JSON.stringify(usedObjects));
    let testData = [];
    if (process.env.MODE === 'staging') {
      for (let testJson in testJsonData) {
        testData.push(
          <S.testJsonItemWrapper
            onClick={event => {
              if (
                window.confirm(
                  `Do you really load ${testJson}.json for KC test?`
                )
              ) {
                this.context.projectActions.loadProject(
                  JSON.parse(testJsonData[testJson].project_data),
                  this.props.categoryData
                );
                this.setState({ addingJson: false });
                this.props.setToolbar('');
              }
            }}
          >
            {testJson}
          </S.testJsonItemWrapper>
        );
      }
    }

    let categories = [
      {
        toolbar: 'room-shape',
        content: (
          <div style={{ display: 'flex' }}>
            <S.SubToolbarWrapper style={{ alignItems: 'flex-start' }}>
              <S.SubCategoryTitle>Change room shape</S.SubCategoryTitle>
              <S.SubCategoryItemWrapper>
                {roomShapeData.map((item, key) => {
                  return (
                    <S.SubCategoryItem
                      style={{ marginRight: 0 }}
                      onClick={event => {
                        if (item.title === 'Custom') {
                          topBtnAction();
                          this.props.setToolbar('');
                        } else this.loadProjectFromData(item, event);
                      }}
                      key={key}
                    >
                      <S.SubCategoryItemImage
                        style={{ height: 25, width: 25 }}
                        maskImage={item.url}
                      />
                      <S.SubCategoryItemLabel>
                        {item.title}
                      </S.SubCategoryItemLabel>
                    </S.SubCategoryItem>
                  );
                })}
              </S.SubCategoryItemWrapper>
              <S.SubCategoryTitle style={{ marginTop: 15 }}>
                Add room elements
              </S.SubCategoryTitle>
              <S.SubCategoryItemWrapper style={{ marginTop: 10 }}>
                {roomElementsData.map((item, key) => {
                  return (
                    <S.SubCategoryItem
                      style={{ marginRight: 0 }}
                      onClick={() =>
                        this.setState({
                          selectedRoomElement:
                            selectedRoomElement === item.title ? '' : item.title
                        })
                      }
                      key={key}
                    >
                      <S.SubCategoryItemImage
                        style={{ height: 25, width: 25 }}
                        maskImage={item.url}
                      />
                      <S.SubCategoryItemLabel>
                        {item.title}
                      </S.SubCategoryItemLabel>
                    </S.SubCategoryItem>
                  );
                })}
              </S.SubCategoryItemWrapper>
              {process.env.MODE === 'staging' &&
                !!Object.keys(testJsonData).length && (
                  <S.SubCategoryItemWrapper
                    onClick={() =>
                      this.setState({
                        addingJson: !this.state.addingJson,
                        selectedRoomElement: ''
                      })
                    }
                  >
                    <S.SubCategoryItem>
                      <S.SubCategoryItemLabel
                        style={{ marginLeft: 15, color: TEXT_COLOR_NEUTRAL_0 }}
                      >
                        Test JSONs
                      </S.SubCategoryItemLabel>
                    </S.SubCategoryItem>
                  </S.SubCategoryItemWrapper>
                )}
            </S.SubToolbarWrapper>
            {selectedRoomElement && (
              <S.SubToolbarWrapper
                style={{
                  alignItems: 'flex-start',
                  marginLeft: 20,
                  minWidth: 250
                }}
              >
                {roomElementsData
                  .find(item => {
                    return item.title === selectedRoomElement;
                  })
                  .data.map((thing, key) => {
                    return (
                      <S.SubCategoryItemWrapper
                        onClick={() =>
                          setRoomElement({
                            type: selectedRoomElement,
                            item: thing
                          })
                        }
                        key={key}
                      >
                        <S.SubCategoryItem
                          style={{ minWidth: 250 }}
                          onClick={() =>
                            ARRAY_3D_MODES.includes(mode)
                              ? this.props.holesActions.selectToolDrawingHole3D(
                                  thing.default.name
                                )
                              : this.props.holesActions.selectToolDrawingHole(
                                  thing.default.name
                                )
                          }
                        >
                          <S.SubCategoryItemImage
                            style={{ height: 50, width: 50 }}
                            maskImage={thing.default.info.image}
                          />
                          <S.SubCategoryItemLabel style={{ marginLeft: 15 }}>
                            {thing.default.info.title}
                          </S.SubCategoryItemLabel>
                        </S.SubCategoryItem>
                      </S.SubCategoryItemWrapper>
                    );
                  })}
              </S.SubToolbarWrapper>
            )}
            {process.env.MODE === 'staging' &&
              !!Object.keys(testJsonData).length &&
              this.state.addingJson && (
                <S.SubToolbarWrapper
                  id="test_json_datas"
                  style={{
                    alignItems: 'flex-start',
                    marginLeft: 20,
                    minWidth: 250
                  }}
                >
                  {testData}
                </S.SubToolbarWrapper>
              )}
          </div>
        )
      },
      {
        toolbar: 'door-style',
        content: (
          <div style={{ display: 'flex' }}>
            <S.SubToolbarWrapper
              style={{
                justifyContent: cabinetStyleChanging ? 'unset' : 'center'
              }}
            >
              {cabinetStyleChanging ? (
                <div style={{ width: '100%' }}>
                  <S.SubCategoryTitle>Choose Door Style</S.SubCategoryTitle>
                  <div
                    style={{
                      marginTop: 15,
                      width: '90%',
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <input
                      style={{
                        height: 50,
                        width: '100%',
                        paddingLeft: 50,
                        border: '1px solid',
                        borderRadius: 7,
                        outline: 0
                      }}
                      placeholder="Search Cabinets and Doors"
                      defaultValue={searchName}
                      onChange={e => {
                        this.setState({ searchName: e.target.value });
                        const defultValue =
                          this.props.categoryData.data.doorStyles.items[0];
                        if (!subCabinetStyle.name) {
                          setDoorStyleSubData(
                            defultValue.items[0].name,
                            defultValue.items[0].items
                          );
                        }
                        if (!cabinetStyle.name) {
                          this.setState({
                            cabinetStyle: {
                              name: defultValue.name,
                              data: defultValue.items
                            }
                          });
                        }
                      }}
                    />
                    <img
                      src="/assets/img/svg/lefttoolbar/search.svg"
                      style={{
                        position: 'absolute',
                        left: 0,
                        width: 45,
                        height: 45,
                        padding: 10
                      }}
                    />
                  </div>
                  <S.SubCategoryItemWrapper>
                    {this.state.DoorStyles.map((door, key) => {
                      return (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          key={key}
                        >
                          <S.SubCategoryItem
                            onClick={() =>
                              setDoorStyleSubData(door.name, door.items)
                            }
                            style={{
                              backgroundColor:
                                cabinetStyle.name === door.name &&
                                TEXT_COLOR_NEUTRAL_5
                            }}
                          >
                            <S.SubCategoryItemImage
                              maskImage={door.thumbnail}
                            />
                            <S.SubCategoryItemLabel
                              style={{
                                fontWeight:
                                  cabinetStyle.name === door.name && 700
                              }}
                            >
                              {door.name}
                            </S.SubCategoryItemLabel>
                          </S.SubCategoryItem>
                          {cabinetStyle.name === door.name &&
                            cabinetStyle.data.map((type, key) => {
                              return (
                                <S.SubCategoryItem
                                  style={{
                                    paddingLeft: 50,
                                    backgroundColor:
                                      subCabinetStyle.name === type.name &&
                                      TEXT_COLOR_NEUTRAL_5
                                  }}
                                  onClick={() =>
                                    setDoorStyleSubData(type.name, type.items)
                                  }
                                  key={key}
                                >
                                  <S.SubCategoryItemImage
                                    maskImage={type.thumbnail}
                                  />
                                  <S.SubCategoryItemLabel
                                    style={{
                                      fontWeight:
                                        subCabinetStyle.name === type.name &&
                                        700
                                    }}
                                  >
                                    {type.name}
                                  </S.SubCategoryItemLabel>
                                </S.SubCategoryItem>
                              );
                            })}
                        </div>
                      );
                    })}
                  </S.SubCategoryItemWrapper>
                </div>
              ) : (
                <S.SubCategoryItemWrapper
                  style={{ maxWidth: 250, rowGap: 30, margin: 0 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 30
                    }}
                  >
                    <S.SubCategoryTitle
                      style={{
                        fontSize: 26,
                        fontWeight: 700,
                        padding: 'unset',
                        lineHeight: '36px',
                        textAlign: 'center',
                        color: '#000F33'
                      }}
                    >
                      {(currentTexture && currentTexture.name) ||
                        currentTexture.toJS().name}
                    </S.SubCategoryTitle>
                    <img
                      src={
                        currentTexture &&
                        (currentTexture.thumbnail ||
                          currentTexture.toJS().thumbnail)
                      }
                      alt="door"
                      width={150}
                      style={{ margin: 'auto' }}
                    />
                  </div>
                  <div style={{ display: 'block' }}>
                    <div>
                      <div
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 13,
                          fontWeight: 600,
                          padding: '3px 10px',
                          textAlign: 'center',
                          lineHeight: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 10,
                          background: '#FAFAFF',
                          width: 'fit-content',
                          margin: '0 auto 2px'
                        }}
                      >
                        <span style={{ color: '#565F77' }}>Installation:</span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={
                              this.state.DoorStyles &&
                              currentTexture &&
                              this.state.DoorStyles.filter(
                                item =>
                                  item.name ===
                                  (currentTexture.install ||
                                    currentTexture.toJS().install)
                              )[0]?.thumbnail
                            }
                            style={{ height: 14, margin: 3 }}
                          />
                          <b style={{ color: SECONDARY_PURPLE_COLOR }}>
                            {currentTexture &&
                              (currentTexture.install ||
                                currentTexture.toJS().install)}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 13,
                          fontWeight: 600,
                          padding: '3px 10px',
                          textAlign: 'center',
                          lineHeight: '13px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 10,
                          background: '#FAFAFF',
                          width: 'fit-content',
                          margin: '0 auto 0px'
                        }}
                      >
                        <span style={{ color: '#565F77' }}>Door:</span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={
                              this.state.DoorStyles &&
                              currentTexture &&
                              this.state.DoorStyles.filter(
                                item =>
                                  item.name ===
                                  (currentTexture.install ||
                                    currentTexture.toJS().install)
                              )[0]?.items.filter(
                                i =>
                                  i.name ===
                                  (currentTexture.door_style_name ||
                                    currentTexture.toJS().door_style_name)
                              )[0]?.thumbnail
                            }
                            style={{ height: 14, margin: 3 }}
                          />
                          <b style={{ color: SECONDARY_PURPLE_COLOR }}>
                            {currentTexture &&
                              (currentTexture.name ||
                                currentTexture.toJS().name)}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <S.SubCategoryTitle
                    style={{
                      fontSize: 13,
                      fontWeight: 400,
                      padding: 'unset',
                      textAlign: 'center',
                      lineHeight: '18px',
                      color: '#000F33'
                    }}
                  >
                    Door selection for all cabinets. To browse the entire
                    collection of doors see below.
                  </S.SubCategoryTitle>
                  <S.Button
                    style={{ width: '95%', border: '1px solid', fontSize: 14 }}
                    onClick={() =>
                      this.setState({ cabinetStyleChanging: true })
                    }
                  >
                    Change Door
                  </S.Button>
                </S.SubCategoryItemWrapper>
              )}
            </S.SubToolbarWrapper>
            {/* {subCabinetStyle.data.map(item => {
              return (
                <S.SubCategoryItem
                  style={{flexDirection: 'column', width: 'auto'}}
                  onClick={() => {
                    this.setState({ cabinetStyleChanging: false, cabinetStyle: { name: '', data: [] }, subCabinetStyle: { name: '', data: [] } });
                    this.getStyle(item);
                  }}
                >
                  <img style={{ width: 60, height: 120, padding: 5 }} src={item.thumbnail} />
                  <S.ContentCheckImage style={{padding: '45px 15px', width: 60}} id="check" src="/assets/img/svg/wizardstep/check-normal.svg"/>
                  {currentTexture.name === item.name && <S.ContentCheckImage style={{padding: '45px 15px', width: 60}} src="/assets/img/svg/wizardstep/check-active.svg"/>}
                  <S.SubCategoryItemLabel style={{marginRight: 0, marginTop: 15}}>{item.name}<br /></S.SubCategoryItemLabel>
                </S.SubCategoryItem>
              );
            })} */}
            {cabinetStyleChanging && subCabinetStyle.name && (
              <S.DoorStyleSubToolBarWrapper
                style={{ width: viewMoreDoorstyle ? 765 : 425 }}
              >
                {subCabinetStyle.data.map((item, key) => {
                  return (
                    (item.name.search(searchName) !== -1 ||
                      item.name
                        .toLowerCase()
                        .search(searchName.toLowerCase()) !== -1) && (
                      <S.DoorstyleCategoryItem
                        onClick={() => {
                          this.setState({
                            cabinetStyleChanging: false,
                            cabinetStyle: { name: '', data: [] },
                            subCabinetStyle: { name: '', data: [] },
                            searchName: ''
                          });
                          this.getStyle(item);
                        }}
                        key={key}
                      >
                        <img
                          style={{
                            width: viewMoreDoorstyle ? 150 : 60,
                            height: viewMoreDoorstyle ? 300 : 120,
                            padding: 5
                          }}
                          src={item.thumbnail}
                        />
                        <S.ContentCheckImage
                          style={{
                            width: viewMoreDoorstyle ? 150 : 60,
                            height: viewMoreDoorstyle ? 300 : 120,
                            padding: viewMoreDoorstyle ? 57 : 12
                          }}
                          id="check"
                          src="/assets/img/svg/wizardstep/check-normal.svg"
                        />
                        {((!currentTexture.install &&
                          cabinetStyle.name === 'Pre-assembled') ||
                          (currentTexture &&
                            currentTexture.install === cabinetStyle.name)) &&
                          currentTexture.name === item.name && (
                            <S.ContentCheckImage
                              style={{
                                width: viewMoreDoorstyle ? 150 : 60,
                                height: viewMoreDoorstyle ? 300 : 120,
                                padding: viewMoreDoorstyle ? 57 : 12
                              }}
                              src="/assets/img/svg/wizardstep/check-active.svg"
                            />
                          )}
                        <S.SubCategoryItemLabel
                          style={{
                            width: 100,
                            marginLeft: 0,
                            marginTop: 15,
                            marginBottom: 10,
                            fontWeight:
                              ((!currentTexture.install &&
                                cabinetStyle.name === 'Pre-assembled') ||
                                (currentTexture &&
                                  currentTexture.install ===
                                    cabinetStyle.name)) &&
                              currentTexture.name === item.name &&
                              700
                          }}
                        >
                          {item.name}
                        </S.SubCategoryItemLabel>
                      </S.DoorstyleCategoryItem>
                    )
                  );
                })}
              </S.DoorStyleSubToolBarWrapper>
            )}
            {cabinetStyleChanging && subCabinetStyle.name && (
              <S.Button
                style={{
                  padding: 10,
                  borderRadius: '50%',
                  border: `3px solid ${BG_COLOR_1}`,
                  marginTop: 'unset',
                  marginLeft: 10
                }}
              >
                <img
                  src="\assets\img\svg\lefttoolbar\view_more.svg"
                  onClick={() =>
                    this.setState({ viewMoreDoorstyle: !viewMoreDoorstyle })
                  }
                  style={{
                    height: 18,
                    transform: `rotate(${viewMoreDoorstyle ? -90 : 90}deg)`
                  }}
                />
              </S.Button>
            )}
          </div>
        )
      },
      {
        toolbar: 'cabinet',
        content: (
          <div style={{ display: 'flex' }}>
            <S.SubToolbarWrapper style={{ alignItems: 'flex-start' }}>
              <S.SubCategoryTitle>Add Cabinets</S.SubCategoryTitle>
              <S.SubCategoryItemWrapper>
                {this.props.categoryData.data.cabinets.map((cabinet, key) => {
                  return (
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      key={key}
                    >
                      <S.SubCategoryItem
                        onClick={() => setSubData(cabinet.name, cabinet.items)}
                        style={{
                          backgroundColor:
                            cabinetCategory.name === cabinet.name &&
                            TEXT_COLOR_NEUTRAL_5
                        }}
                      >
                        <S.SubCategoryItemImage maskImage={cabinet.thumbnail} />
                        <S.SubCategoryItemLabel
                          style={{
                            fontWeight:
                              cabinetCategory.name === cabinet.name && 700
                          }}
                        >
                          {cabinet.name}
                        </S.SubCategoryItemLabel>
                      </S.SubCategoryItem>
                      {cabinetCategory.name === cabinet.name && temp}
                    </div>
                  );
                })}
              </S.SubCategoryItemWrapper>
            </S.SubToolbarWrapper>
            {subCabinetCategory.name && (
              <S.SubToolbarWrapper
                style={{
                  alignItems: 'flex-start',
                  marginLeft: 20,
                  minWidth: 250
                }}
              >
                <S.SubCategoryItemWrapper>
                  {subCabinetCategory.data.map((item, key) => {
                    let blMolding = item.asset.endsWith('.svg') ? 1 : 0;
                    return blMolding ? (
                      <S.SubCategoryItem
                        id={item.id}
                        style={{ minWidth: 250 }}
                        onClick={() => this.setFinishing(item)}
                        key={key}
                      >
                        <img
                          id={`molding&${item.id}`}
                          style={{ height: 70, width: 70, padding: 5 }}
                          src={item.thumbnail}
                        />
                        <S.ContentCheckImage
                          id="check"
                          src="/assets/img/svg/wizardstep/check-normal.svg"
                        />
                        {item.selected && (
                          <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />
                        )}
                        <S.SubCategoryItemLabel
                          style={{ fontWeight: item.selected && 700 }}
                        >
                          {item.name}
                        </S.SubCategoryItemLabel>
                      </S.SubCategoryItem>
                    ) : (
                      <S.SubCategoryItem
                        style={{
                          display:
                            (currentTexture?.doorStyles?.cds ?? []).filter(
                              cd => cd.itemID === item.itemID
                            ).length === 0 && 'none',
                          minWidth: 400,
                          cursor:
                            (currentTexture?.doorStyles?.cds ?? []).filter(
                              cd => cd.itemID === item.itemID
                            ).length === 0 && 'no-drop',
                          paddingLeft: 0
                        }}
                        onClick={() =>
                          currentTexture.doorStyles.cds.filter(
                            cd => cd.itemID === item.itemID
                          ).length !== 0 && this.cabinetselect(item.name)
                        }
                        key={key}
                      >
                        <img
                          style={{ height: 90, width: 90 }}
                          src={item.thumbnail}
                        />
                        <S.SubCategoryItemLabel style={{ textAlign: 'left' }}>
                          {item.long_name} <br />
                          <span style={{ fontSize: 14 }}>
                            Width:{' '}
                            {Math.round(
                              convert(item.info.width)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                            <br />
                            Depth:{' '}
                            {Math.round(
                              convert(item.info.height)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                            <br />
                            Height:{' '}
                            {Math.round(
                              convert(item.info.tall)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                          </span>
                        </S.SubCategoryItemLabel>
                        {(currentTexture?.doorStyles?.cds ?? []).filter(
                          cd => cd.itemID === item.itemID
                        ).length === 0 && (
                          <StyledTooltip
                            id="warning_unavailable"
                            title="This item is not available in the finish you have selected. Please contact us for alternate options in the finish you want."
                          >
                            <S.SubCategoryItemLabel
                              style={{
                                position: 'absolute',
                                right: 8,
                                bottom: 15,
                                color: 'red',
                                zIndex: 1,
                                opacity: 0.5
                              }}
                            >
                              <u>Unavailable</u>
                            </S.SubCategoryItemLabel>
                          </StyledTooltip>
                        )}
                        {(currentTexture?.doorStyles?.cds ?? []).filter(
                          cd => cd.itemID === item.itemID
                        ).length === 0 && (
                          <ErrorIcon
                            style={{
                              position: 'absolute',
                              right: 100,
                              bottom: 18,
                              color: 'red',
                              fontSize: '1rem'
                            }}
                          ></ErrorIcon>
                        )}
                        {(currentTexture?.doorStyles?.cds ?? []).filter(
                          cd => cd.itemID === item.itemID
                        ).length === 0 && (
                          <div
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)'
                            }}
                          />
                        )}
                      </S.SubCategoryItem>
                    );
                  })}
                </S.SubCategoryItemWrapper>
              </S.SubToolbarWrapper>
            )}
          </div>
        )
      },
      {
        toolbar: 'appliance',
        content: (
          <div style={{ display: 'flex' }}>
            <S.SubToolbarWrapper style={{ alignItems: 'flex-start' }}>
              <S.SubCategoryTitle>Add Appliances</S.SubCategoryTitle>
              <S.SubCategoryItemWrapper>
                {this.props.categoryData.data.appliances.map(
                  (appliance, key) => {
                    return (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        key={key}
                      >
                        <S.SubCategoryItem
                          style={{
                            backgroundColor: appliance.items.find(
                              item => item.type === 'cabinet'
                            )
                              ? subCabinetCategory.name === appliance.name &&
                                TEXT_COLOR_NEUTRAL_5
                              : cabinetCategory.name === appliance.name &&
                                TEXT_COLOR_NEUTRAL_5
                          }}
                          onClick={() =>
                            setSubData(appliance.name, appliance.items)
                          }
                        >
                          <S.SubCategoryItemImage
                            maskImage={appliance.thumbnail}
                          />
                          <S.SubCategoryItemLabel
                            style={{
                              fontWeight: appliance.items.find(
                                item => item.type === 'cabinet'
                              )
                                ? subCabinetCategory.name === appliance.name &&
                                  700
                                : cabinetCategory.name === appliance.name && 700
                            }}
                          >
                            {appliance.name}
                          </S.SubCategoryItemLabel>
                        </S.SubCategoryItem>
                        {cabinetCategory.name === appliance.name && temp}
                      </div>
                    );
                  }
                )}
              </S.SubCategoryItemWrapper>
            </S.SubToolbarWrapper>
            {subCabinetCategory.name && (
              <S.SubToolbarWrapper
                style={{
                  alignItems: 'flex-start',
                  marginLeft: 20,
                  minWidth: 350
                }}
              >
                <S.SubCategoryItemWrapper>
                  {subCabinetCategory.data.map((item, key) => {
                    return (
                      <S.SubCategoryItem
                        onClick={() => this.cabinetselect(item.name)}
                        key={key}
                      >
                        <img
                          style={{ height: 70, width: 70 }}
                          src={item.thumbnail}
                        />
                        <S.SubCategoryItemLabel style={{ textAlign: 'left' }}>
                          {item.name} <br />
                          <span style={{ fontSize: 14 }}>
                            Width:{' '}
                            {Math.round(
                              convert(item.info.width)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                            <br />
                            Depth:{' '}
                            {Math.round(
                              convert(item.info.height)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                            <br />
                            Height:{' '}
                            {Math.round(
                              convert(item.info.tall)
                                .from('in')
                                .to(measurementUnit._unit) * 100
                            ) / 100}
                            {measurementUnit._unit !== 'in'
                              ? measurementUnit._unit
                              : '"'}
                          </span>
                        </S.SubCategoryItemLabel>
                      </S.SubCategoryItem>
                    );
                  })}
                </S.SubCategoryItemWrapper>
              </S.SubToolbarWrapper>
            )}
          </div>
        )
      },
      {
        toolbar: 'finishing',
        content: (
          <div style={{ display: 'flex' }}>
            <S.SubToolbarWrapper style={{ alignItems: 'flex-start' }}>
              <S.SubCategoryTitle>Add Finishings</S.SubCategoryTitle>
              <S.SubCategoryItemWrapper>
                {finishingData.map((finishing, key) => {
                  return (
                    <S.SubCategoryItem
                      style={{
                        backgroundColor:
                          selectedFinishing === finishing.name &&
                          TEXT_COLOR_NEUTRAL_5
                      }}
                      onClick={() =>
                        this.setState({
                          selectedFinishing:
                            selectedFinishing === finishing.name
                              ? ''
                              : finishing.name
                        })
                      }
                      key={key}
                    >
                      <S.SubCategoryItemImage maskImage={finishing.thumbnail} />
                      <S.SubCategoryItemLabel
                        style={{
                          fontWeight:
                            selectedFinishing === finishing.name && 700
                        }}
                      >
                        {finishing.name}
                      </S.SubCategoryItemLabel>
                    </S.SubCategoryItem>
                  );
                })}
              </S.SubCategoryItemWrapper>
            </S.SubToolbarWrapper>
            {selectedFinishing && (
              <S.SubToolbarWrapper
                style={{
                  alignItems: 'flex-start',
                  marginLeft: 20,
                  minWidth: 250
                }}
              >
                <S.SubCategoryItemWrapper>
                  {finishingData
                    .filter(element => element.name === selectedFinishing)[0]
                    .data.map((item, key) => {
                      if (selectedFinishing === 'Wall Color') {
                        return (
                          <S.SubCategoryItem
                            style={{ minWidth: 250 }}
                            onClick={() => this.setFinishing(item)}
                            key={key}
                          >
                            <div
                              style={{
                                height: 70,
                                width: 70,
                                backgroundColor: item.color,
                                padding: 2
                              }}
                            />
                            <S.ContentCheckImage
                              id="check"
                              src="/assets/img/svg/wizardstep/check-normal.svg"
                            />
                            {item.selected && (
                              <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />
                            )}
                            <S.SubCategoryItemLabel
                              style={{ fontWeight: item.selected && 700 }}
                            >
                              {item.name}
                            </S.SubCategoryItemLabel>
                          </S.SubCategoryItem>
                        );
                      } else {
                        return (
                          <S.SubCategoryItem
                            id={item.id}
                            style={{ minWidth: 250 }}
                            onClick={() => this.setFinishing(item)}
                            key={key}
                          >
                            <img
                              id={`finishing&${item.id}`}
                              style={{ height: 70, width: 70, padding: 5 }}
                              src={item.thumbnail}
                            />
                            {selectedFinishing !== 'Lighting' &&
                              selectedFinishing !== 'Furnishing' && (
                                <S.ContentCheckImage
                                  id="check"
                                  src="/assets/img/svg/wizardstep/check-normal.svg"
                                />
                              )}
                            {selectedFinishing !== 'Lighting' &&
                              selectedFinishing !== 'Furnishing' &&
                              item.selected && (
                                <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />
                              )}
                            {selectedFinishing !== 'Lighting' &&
                            selectedFinishing !== 'Furnishing' ? (
                              <S.SubCategoryItemLabel
                                style={{ fontWeight: item.selected && 700 }}
                              >
                                {item.name}
                              </S.SubCategoryItemLabel>
                            ) : (
                              <S.SubCategoryItemLabel
                                style={{ textAlign: 'left' }}
                              >
                                {item.name}
                                <br />
                                <span style={{ fontSize: 14 }}>
                                  Width:{' '}
                                  {Math.round(
                                    convert(item.width)
                                      .from('in')
                                      .to(measurementUnit._unit) * 100
                                  ) / 100}
                                  {measurementUnit._unit !== 'in'
                                    ? measurementUnit._unit
                                    : '"'}
                                  <br />
                                  Depth:{' '}
                                  {Math.round(
                                    convert(item.length)
                                      .from('in')
                                      .to(measurementUnit._unit) * 100
                                  ) / 100}
                                  {measurementUnit._unit !== 'in'
                                    ? measurementUnit._unit
                                    : '"'}
                                  <br />
                                  Height:{' '}
                                  {Math.round(
                                    convert(item.height)
                                      .from('in')
                                      .to(measurementUnit._unit) * 100
                                  ) / 100}
                                  {measurementUnit._unit !== 'in'
                                    ? measurementUnit._unit
                                    : '"'}
                                </span>
                              </S.SubCategoryItemLabel>
                            )}
                          </S.SubCategoryItem>
                        );
                      }
                    })}
                </S.SubCategoryItemWrapper>
              </S.SubToolbarWrapper>
            )}
          </div>
        )
      },
      {
        toolbar: 'reviewforquote',
        content: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <S.SubCategoryTitle style={{ margin: '0 0 10px 15px' }}>
                Review & Quote
              </S.SubCategoryTitle>
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  flexDirection: 'row'
                }}
              >
                {/* {console.log(this.state.doorStyles.filter(item => item.name === currentTexture.install)[0]?.thumbnail)} */}
                <div
                  style={{
                    background: '#FAFAFF',
                    padding: '5px 10px',
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <S.SubCategoryTitle
                    style={{
                      fontSize: 11,
                      fontWeight: 400,
                      padding: 'unset',
                      textAlign: 'center',
                      lineHeight: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    Installation:{' '}
                    <img
                      src={
                        this.state.DoorStyles &&
                        currentTexture &&
                        this.state.DoorStyles.filter(
                          item => item.name === currentTexture.install
                        )[0]?.thumbnail
                      }
                      style={{ height: 15, margin: 3 }}
                    />
                    <b style={{ color: SECONDARY_PURPLE_COLOR }}>
                      {currentTexture && currentTexture.install}
                    </b>
                  </S.SubCategoryTitle>
                </div>
                <div
                  style={{
                    background: '#FAFAFF',
                    padding: '5px 10px',
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <S.SubCategoryTitle
                    style={{
                      fontSize: 11,
                      fontWeight: 400,
                      padding: 'unset',
                      textAlign: 'center',
                      lineHeight: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    Door:{' '}
                    <img
                      src={
                        this.state.DoorStyles &&
                        currentTexture &&
                        this.state.DoorStyles.filter(
                          item => item.name === currentTexture.install
                        )[0]?.items.filter(
                          i => i.name === currentTexture.door_style_name
                        )[0]?.thumbnail
                      }
                      style={{ height: 15, margin: 3 }}
                    />
                    <b style={{ color: SECONDARY_PURPLE_COLOR }}>
                      {currentTexture && currentTexture.name}
                    </b>
                  </S.SubCategoryTitle>
                </div>
              </div>
            </div>
            <S.ReviewQuoteTable>
              <S.ReviewQuoteThead style={{ backgroundColor: '#FAFAFF' }}>
                <S.ReviewQuoteTh
                  style={{
                    width: 300,
                    textAlign: 'left',
                    marginLeft: 15,
                    fontSize: 12,
                    color: '#565F77'
                  }}
                >
                  Product
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 80, fontSize: 12, color: '#565F77' }}
                >
                  Door
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 50, fontSize: 12, color: '#565F77' }}
                >
                  Qty
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 100, fontSize: 12, color: '#565F77' }}
                >
                  Retail Price
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 100, fontSize: 12, color: '#565F77' }}
                >
                  Discounted Price
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{
                    width: 80,
                    marginRight: 15,
                    fontSize: 12,
                    color: '#565F77'
                  }}
                >
                  Subtotal
                </S.ReviewQuoteTh>
              </S.ReviewQuoteThead>
              <div
                style={{
                  overflowY: 'scroll',
                  maxHeight: 'calc(100vh - 420px)',
                  minHeight: 100
                }}
              >
                {uniqueData.count()
                  ? uniqueData.entrySeq().map(([itemID, item]) => {
                      let itemSKU = makeSKUForMagento(item);
                      return (
                        <ReviewItem
                          key={itemID}
                          catalog={this.props.catalog}
                          categoryData={this.props.categoryData}
                          itemSKU={itemSKU}
                          suffix={currentTexture && currentTexture.install}
                          item={item}
                          products={this.props.products}
                          measurementUnit={measurementUnit}
                          count={
                            usedObjects[itemSKU] !== undefined
                              ? usedObjects[itemSKU].count
                              : 1
                          }
                        />
                      );
                    })
                  : null}
                {moldingData.length
                  ? moldingData.map(molding => {
                      const toeKickSKU = ['TKC', 'TK8', 'TK96'];
                      let moldingSKU = null;
                      if (molding.name === 'Toe Kick Molding') {
                        toeKickSKU.forEach(sku => {
                          if (
                            this.props.products[
                              sku + '-' + molding.doorStyle.sku
                            ]
                          ) {
                            moldingSKU = sku + '-' + molding.doorStyle.sku;
                          }
                          if (
                            this.props.products[
                              molding.doorStyle.sku + '-' + sku
                            ]
                          ) {
                            moldingSKU = molding.doorStyle.sku + '-' + sku;
                          }
                        });
                        molding.thumbnail = '/assets/img/TKC_thumbnail.png';
                      } else {
                        moldingSKU = makeSKUForMagento(molding);
                      }

                      if (!moldingSKU) return false;

                      allCount += molding.count;

                      if (!usedObjects[moldingSKU]) {
                        usedObjects[moldingSKU] = {
                          ...usedObjects[moldingSKU],
                          count: 1,
                          regularPrice: 0,
                          disCountPrice: 0,
                          sku_r: ''
                        };
                        if (this.props.products[moldingSKU]) {
                          usedObjects[moldingSKU].regularPrice +=
                            this.props.products[moldingSKU].regular_price;
                          usedObjects[moldingSKU].disCountPrice +=
                            this.props.products[moldingSKU].discounted_price;
                          usedObjects[moldingSKU].sku_r =
                            this.props.products[moldingSKU].sku_r;
                          allDiscountedPrices +=
                            this.props.products[moldingSKU].discounted_price *
                            molding.count;
                          allRegularPrices +=
                            this.props.products[moldingSKU].regular_price *
                            molding.count;
                        }
                      } else {
                        if (this.props.products[moldingSKU]) {
                          usedObjects[moldingSKU].regularPrice +=
                            this.props.products[moldingSKU].regular_price;
                          usedObjects[moldingSKU].disCountPrice +=
                            this.props.products[moldingSKU].discounted_price;
                          allRegularPrices +=
                            this.props.products[moldingSKU].regular_price *
                            molding.count;
                          allDiscountedPrices +=
                            this.props.products[moldingSKU].discounted_price *
                            molding.count;
                        }
                        usedObjects[moldingSKU].count += molding.count;
                      }
                      return (
                        <ReviewMolding
                          key={molding.id}
                          molding={molding}
                          products={this.props.products}
                          moldingSKU={moldingSKU}
                        />
                      );
                    })
                  : null}
              </div>
              <S.ReviewQuoteThead
                style={{ backgroundColor: '#FAFAFF', marginBottom: 15 }}
              >
                <S.ReviewQuoteTh
                  style={{
                    width: 300,
                    textAlign: 'left',
                    marginLeft: 15,
                    color: '#565F77'
                  }}
                >
                  Grand Total
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 80, fontSize: 12, color: '#565F77' }}
                />
                <S.ReviewQuoteTh style={{ width: 50, color: '#565F77' }}>
                  {allCount}
                </S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 100, color: '#565F77' }}
                >{`$${parseFloat(
                  allRegularPrices.toFixed(2)
                )}`}</S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 100, color: STATUS_POSITIVE_COLOR }}
                >{`$${parseFloat(
                  allDiscountedPrices.toFixed(2)
                )}`}</S.ReviewQuoteTh>
                <S.ReviewQuoteTh
                  style={{ width: 80, marginRight: 15, color: '#4C12A1' }}
                >{`$${parseFloat(
                  allDiscountedPrices.toFixed(2)
                )}`}</S.ReviewQuoteTh>
              </S.ReviewQuoteThead>
            </S.ReviewQuoteTable>
            <div style={{ display: 'flex', marginTop: 15 }}>
              <S.Button
                style={{ fontSize: 16 }}
                onClick={() => {
                  if (userInfo.pid === undefined)
                    this.props.handleSubmitPrompt();
                  else this.props.handleSubmitForQuote(true);
                }}
              >
                <img
                  style={{ marginRight: 10, height: 16 }}
                  src="\assets\img\svg\lefttoolbar\reviewforquote.svg"
                />
                Designer Review
              </S.Button>
              {/* <S.Button
                style={{fontSize: 16}}
                onClick={() => {
                  if (userInfo.pid === undefined) this.props.handleSubmitPrompt();
                  else this.props.handleDesignerAssist(true);
                }}
              >
                <img style={{marginRight: 10, height: 16}} src='\assets\img\svg\lefttoolbar\disigner_assistance.svg' />
                Designer Assistance
              </S.Button> */}
              {/* <S.Button style={{fontSize:16}} onClick={() => this.props.handleSaveDesign(true)}>
                <img style={{marginRight: 10, height: 16}} src='\assets\img\svg\toolbar\download.svg' />
                Download Summary
              </S.Button> */}
            </div>
          </div>
        )
      }
    ];

    let toolbarData = [
      {
        key: 'room-shape',
        label: 'Room Shape',
        url: '/assets/img/svg/lefttoolbar/room-shape.svg',
        url_active: '/assets/img/svg/lefttoolbar/room-shape-active.svg'
      },
      {
        key: 'door-style',
        label: 'Door Style',
        url: '/assets/img/svg/lefttoolbar/door-style.svg',
        url_active: '/assets/img/svg/lefttoolbar/door-style-active.svg'
      },
      {
        key: 'cabinet',
        label: 'Cabinets',
        url: '/assets/img/svg/lefttoolbar/cabinet.svg',
        url_active: '/assets/img/svg/lefttoolbar/cabinet-active.svg'
      },
      {
        key: 'appliance',
        label: 'Appliances',
        url: '/assets/img/svg/lefttoolbar/appliance.svg',
        url_active: '/assets/img/svg/lefttoolbar/appliance-active.svg'
      },
      {
        key: 'finishing',
        label: 'Finishing',
        url: '/assets/img/svg/lefttoolbar/finishing.svg',
        url_active: '/assets/img/svg/lefttoolbar/finishing-active.svg'
      },
      {
        key: 'reviewforquote',
        label: 'Review & Quote',
        url: '/assets/img/svg/lefttoolbar/reviewforquote.svg',
        url_active: '/assets/img/svg/lefttoolbar/reviewforquote-active.svg'
      }
    ];

    return (
      <aside
        style={{
          ...ASIDE_STYLE,
          maxWidth: !ARRAY_3D_MODES.includes(mode) ? width : 90,
          maxHeight: height,
          marginTop: this.props.headerH + 10
        }}
      >
        <SnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() =>
            this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
          }
        />
        <ConfirmPopup
          visible={this.state.isPopupOpen}
          handleConfirmLoad={() => this.handleConfirmLoad()}
          submit={() => this._submit()}
        />
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            left: 10,
            zIndex: 1,
            flexDirection: 'row'
          }}
        >
          <S.ToolbarWrapper>
            <S.SubToolbarWrapper style={{ marginLeft: 0, minWidth: 'unset' }}>
              {toolbarData.map((data, key) => {
                return (
                  <S.ToolbarButton
                    onClick={() => {
                      this.props.setToolbar(
                        this.props.toolBar === data.key ? '' : data.key
                      );
                      this.setState({
                        cabinetCategory: { name: '', data: [] },
                        subCabinetCategory: { name: '', data: [] },
                        cabinetStyleChanging: false,
                        searchName: ''
                      });
                      if (
                        this.props.toolBar !== data.key &&
                        data.key === 'reviewforquote'
                      ) {
                        let items = this.state.elements.items;
                        let tmp = [];
                        items.forEach(item => {
                          if (
                            item.category === 'cabinet' &&
                            !item.cabinet_category
                              .toLowerCase()
                              .includes('microwave')
                          ) {
                            tmp.push(item);
                          }
                        });
                        items = [...tmp];
                        const moldings = this.state.elements.molding;
                        let tmpMoldingData = [];
                        let toedoorStyles = [];
                        items.map(item => {
                          if (item.layoutpos === 'Base') {
                            let layer = state.scene.layers.get(
                              state.scene.selectedLayer
                            );
                            let allLines = GeometryUtils.getAllLines(layer);
                            let allLineRects = GeometryUtils.buildRectFromLines(
                              layer,
                              allLines
                            );
                            let x = item.x;
                            let y = item.y;
                            let rotRad = (item.rotation / 180) * Math.PI;
                            let w = item.properties.get('width').get('_length');
                            let wUnit =
                              item.properties.get('width').get('_unit') || 'cm';
                            w = convert(w / 2)
                              .from(wUnit)
                              .to('cm');
                            let h = item.properties.get('depth').get('_length');
                            let hUnit =
                              item.properties.get('depth').get('_unit') || 'cm';
                            h = convert(h / 2)
                              .from(hUnit)
                              .to('cm');
                            let mx = x - w * Math.cos(rotRad);
                            let my = y - w * Math.sin(rotRad);
                            let x0 = mx + h * Math.sin(rotRad);
                            let y0 = my - h * Math.cos(rotRad);
                            let x3 = mx * 2 - x0;
                            let y3 = my * 2 - y0;
                            let x1 = x * 2 - x3;
                            let y1 = y * 2 - y3;
                            let x2 = x * 2 - x0;
                            let y2 = y * 2 - y0;
                            if (
                              !GeometryUtils.isSnappedLine(
                                {
                                  rect: [
                                    { x: x0, y: y0 },
                                    { x: x1, y: y1 },
                                    { x: x2, y: y2 },
                                    { x: x3, y: y3 }
                                  ]
                                },
                                allLineRects
                              )
                            )
                              return false;
                            let outline = null;
                            let element =
                              this.context.catalog.elements[item.get('type')];
                            if (!element)
                              element =
                                this.context.catalog.elements[
                                  returnReplaceableDeepSearchType(
                                    item.get('type')
                                  )
                                ];
                            outline = element.info.outline;
                            let len = 0;
                            if (outline) {
                              // Extract Points from `outline`
                              var outlinePaths = outline.paths;
                              var outlineWidth = outline.svgWidth;
                              var outlineHeight = outline.svgHeight;
                              var outlinePoints = []; // Hold Points Of SVG
                              for (let path of outlinePaths) {
                                for (let subPath of path.subPaths) {
                                  outlinePoints = outlinePoints.concat(
                                    subPath.getPoints()
                                  );
                                }
                              }
                              let maxX = outlinePoints[0].x,
                                minX = outlinePoints[0].x;
                              let maxY = outlinePoints[0].y,
                                minY = outlinePoints[0].y;

                              outlinePoints.forEach(point => {
                                if (point.x > maxX) {
                                  maxX = point.x;
                                }
                                if (point.x < minX) {
                                  minX = point.x;
                                }
                                if (point.y > maxY) {
                                  maxY = point.y;
                                }
                                if (point.y < minY) {
                                  minY = point.y;
                                }
                              });
                              outlinePoints.forEach(point => {
                                if (
                                  GeometryUtils.isPointInRect(
                                    [
                                      { x: minX, y: minY },
                                      { x: minX, y: maxY },
                                      { x: maxX, y: maxY },
                                      { x: maxX, y: minY }
                                    ],
                                    point
                                  )
                                ) {
                                  if (point.x > 10)
                                    len +=
                                      (point.x / outlineWidth - 0.5) * w * 2 +
                                      h * 2 -
                                      (point.y / outlineHeight - 0.5) * h * 2;
                                }
                              });
                              len = convert(len).from('cm').to('in');
                            } else {
                              w = convert(w * 2)
                                .from('cm')
                                .to('in');
                              len += w;
                            }
                            let doorIndex = toedoorStyles.findIndex(
                              a =>
                                a.doorStyle.id ===
                                (item.doorStyle.hasOwnProperty('id')
                                  ? item.doorStyle.id
                                  : item.doorStyle && item.doorStyle.toJS().id)
                            );
                            if (doorIndex > -1) {
                              toedoorStyles[doorIndex].totalLength += len;
                            } else {
                              toedoorStyles.push({
                                doorStyle: item.doorStyle.hasOwnProperty('id')
                                  ? item.doorStyle
                                  : item.doorStyle && item.doorStyle.toJS(),
                                totalLength: len
                              });
                            }
                          }
                        });
                        toedoorStyles.forEach(doorStyle => {
                          doorStyle.totalLength &&
                            tmpMoldingData.push({
                              name: 'Toe Kick Molding',
                              thumbnail: '',
                              category: 'molding',
                              type: 'cabinet',
                              doorStyle: doorStyle.doorStyle,
                              count: Math.ceil(doorStyle.totalLength / 96)
                            });
                        });
                        let tmpMoldings = [...moldings];
                        layer.linears.forEach(linear => {
                          !tmpMoldings.filter(
                            mold => mold.name === linear.molding.name
                          ).length && tmpMoldings.push(linear.molding);
                        });
                        tmpMoldings.forEach(molding => {
                          let MDArray = [];
                          items.map(item => {
                            if (
                              (item.layoutpos === 'Base' &&
                                molding.molding_type.includes('Base')) ||
                              (item.layoutpos === 'Wall' &&
                                molding.molding_type.includes('Wall')) ||
                              (item.layoutpos === 'Tall' &&
                                molding.molding_type.includes('Tall'))
                            ) {
                              let layer = state.scene.layers.get(
                                state.scene.selectedLayer
                              );
                              let allLines = GeometryUtils.getAllLines(layer);
                              let allLineRects =
                                GeometryUtils.buildRectFromLines(
                                  layer,
                                  allLines
                                );
                              let x = item.x;
                              let y = item.y;
                              let rotRad = (item.rotation / 180) * Math.PI;
                              let w = item.properties
                                .get('width')
                                .get('_length');
                              let wUnit =
                                item.properties.get('width').get('_unit') ||
                                'cm';
                              w = convert(w / 2)
                                .from(wUnit)
                                .to('cm');
                              let h = item.properties
                                .get('depth')
                                .get('_length');
                              let hUnit =
                                item.properties.get('depth').get('_unit') ||
                                'cm';
                              h = convert(h / 2)
                                .from(hUnit)
                                .to('cm');
                              let mx = x - w * Math.cos(rotRad);
                              let my = y - w * Math.sin(rotRad);
                              let x0 = mx + h * Math.sin(rotRad);
                              let y0 = my - h * Math.cos(rotRad);
                              let x3 = mx * 2 - x0;
                              let y3 = my * 2 - y0;
                              let x1 = x * 2 - x3;
                              let y1 = y * 2 - y3;
                              let x2 = x * 2 - x0;
                              let y2 = y * 2 - y0;
                              if (
                                item.layoutpos === 'Base' &&
                                GeometryUtils.isSnappedLine(
                                  {
                                    rect: [
                                      { x: x0, y: y0 },
                                      { x: x1, y: y1 },
                                      { x: x2, y: y2 },
                                      { x: x3, y: y3 }
                                    ]
                                  },
                                  allLineRects
                                )
                              )
                                return false;
                              if (
                                (item.layoutpos === 'Wall' ||
                                  item.layoutpos === 'Tall') &&
                                !GeometryUtils.isSnappedLine(
                                  {
                                    rect: [
                                      { x: x0, y: y0 },
                                      { x: x1, y: y1 },
                                      { x: x2, y: y2 },
                                      { x: x3, y: y3 }
                                    ]
                                  },
                                  allLineRects
                                )
                              )
                                return false;
                              let lines = getLinesOfItem(
                                item,
                                allLineRects,
                                this.context.catalog
                              );
                              let newMD = createMDFromItem(
                                item,
                                lines,
                                molding
                              );
                              let extMD = false;
                              let extMDIndex = -1;
                              let tryMergeMD = function () {
                                extMDIndex = MDArray.findIndex(el => {
                                  extMD = tryAdjacentMD(newMD, el, molding);
                                  return extMD != false;
                                });
                                return extMDIndex > -1;
                              };
                              while (tryMergeMD()) {
                                MDArray.splice(extMDIndex, 1);
                                newMD = extMD;
                              }
                              newMD.lines = getLinesOverLap(
                                newMD,
                                items,
                                allLineRects,
                                this.context.catalog
                              );
                              if (newMD.lines.length !== 0) {
                                MDArray.push(newMD);
                              }
                            }
                          });
                          let doorStyles = [];
                          MDArray.forEach(md => {
                            let len = 0;
                            md.lines.forEach(line => {
                              len += Number(
                                convert(
                                  GeometryUtils.verticesDistance(
                                    line[0],
                                    line[1]
                                  )
                                )
                                  .from('cm')
                                  .to('in')
                                  .toFixed(3)
                              );
                            });
                            let maxX = md.lines[0][0].x,
                              minX = md.lines[0][0].x;
                            let maxY = md.lines[0][0].y,
                              minY = md.lines[0][0].y;

                            md.lines.forEach(line => {
                              if (line[0].x > maxX) {
                                maxX = line[0].x;
                              }
                              if (line[0].x < minX) {
                                minX = line[0].x;
                              }
                              if (line[1].x > maxX) {
                                maxX = line[1].x;
                              }
                              if (line[1].x < minX) {
                                minX = line[1].x;
                              }
                              if (line[0].y > maxY) {
                                maxY = line[0].y;
                              }
                              if (line[0].y < minY) {
                                minY = line[0].y;
                              }
                              if (line[1].y > maxY) {
                                maxY = line[1].y;
                              }
                              if (line[1].y < minY) {
                                minY = line[1].y;
                              }
                            });

                            let cPos = {
                              x: (maxX + minX) / 2,
                              y: (maxY + minY) / 2
                            };
                            let MDlines = [...md.lines];
                            let points = [[]];
                            let flag = 1;
                            let i = 0;
                            while (MDlines.length !== 0) {
                              if (points[i].length === 0) {
                                points[i].push(
                                  {
                                    x: MDlines[0][0].x - cPos.x,
                                    y: MDlines[0][0].y - cPos.y
                                  },
                                  {
                                    x: MDlines[0][1].x - cPos.x,
                                    y: MDlines[0][1].y - cPos.y
                                  }
                                );
                                MDlines.splice(0, 1);
                              } else {
                                if (flag) {
                                  let res = MDlines.findIndex(
                                    a =>
                                      GeometryUtils.samePoints(
                                        {
                                          x: a[0].x - cPos.x,
                                          y: a[0].y - cPos.y
                                        },
                                        points[i][points[i].length - 1]
                                      ) ||
                                      GeometryUtils.samePoints(
                                        {
                                          x: a[1].x - cPos.x,
                                          y: a[1].y - cPos.y
                                        },
                                        points[i][points[i].length - 1]
                                      )
                                  );
                                  if (res > -1) {
                                    let newPos = {
                                      x: MDlines[res][0].x - cPos.x,
                                      y: MDlines[res][0].y - cPos.y
                                    };
                                    if (
                                      GeometryUtils.samePoints(
                                        newPos,
                                        points[i][points[i].length - 1]
                                      )
                                    ) {
                                      newPos = {
                                        x: MDlines[res][1].x - cPos.x,
                                        y: MDlines[res][1].y - cPos.y
                                      };
                                    }
                                    points[i].push({
                                      x: newPos.x,
                                      y: newPos.y
                                    });
                                    MDlines.splice(res, 1);
                                  } else {
                                    flag = 0;
                                  }
                                } else {
                                  let res = MDlines.findIndex(
                                    a =>
                                      GeometryUtils.samePoints(
                                        {
                                          x: a[0].x - cPos.x,
                                          y: a[0].y - cPos.y
                                        },
                                        points[i][0]
                                      ) ||
                                      GeometryUtils.samePoints(
                                        {
                                          x: a[1].x - cPos.x,
                                          y: a[1].y - cPos.y
                                        },
                                        points[i][0]
                                      )
                                  );
                                  if (res > -1) {
                                    let newPos = {
                                      x: MDlines[res][0].x - cPos.x,
                                      y: MDlines[res][0].y - cPos.y
                                    };
                                    if (
                                      GeometryUtils.samePoints(
                                        newPos,
                                        points[i][0]
                                      )
                                    ) {
                                      newPos = {
                                        x: MDlines[res][1].x - cPos.x,
                                        y: MDlines[res][1].y - cPos.y
                                      };
                                    }
                                    points[i] = [
                                      { x: newPos.x, y: newPos.y },
                                      ...points[i]
                                    ];
                                    MDlines.splice(res, 1);
                                  } else {
                                    flag = 1;
                                    i++;
                                    if (MDlines.length !== 0) points.push([]);
                                  }
                                }
                              }
                            }
                            points.forEach(point => {
                              if (
                                GeometryUtils.samePoints(
                                  point[0],
                                  point[point.length - 1]
                                )
                              ) {
                                len += 0.75 * 2 * (point.length - 1);
                              } else {
                                len += 0.75 * 2 * (point.length - 2);
                              }
                            });
                            let doorIndex = doorStyles.findIndex(
                              a =>
                                a.doorStyle.id ===
                                (md.items[0].doorStyle.hasOwnProperty('id')
                                  ? md.items[0].doorStyle.id
                                  : md.items[0].doorStyle.toJS().id)
                            );
                            if (doorIndex > -1) {
                              doorStyles[doorIndex].totalLength += len;
                            } else {
                              doorStyles.push({
                                doorStyle: md.items[0].doorStyle.hasOwnProperty(
                                  'id'
                                )
                                  ? md.items[0].doorStyle
                                  : md.items[0].doorStyle.toJS(),
                                totalLength: len
                              });
                            }
                          });
                          doorStyles.forEach(doorStyle => {
                            doorStyle.totalLength &&
                              tmpMoldingData.push({
                                ...molding,
                                doorStyle: doorStyle.doorStyle,
                                count: Math.ceil(doorStyle.totalLength / 96)
                              });
                          });
                        });
                        this.setState({ moldingData: [...tmpMoldingData] });
                      }
                    }}
                    key={key}
                  >
                    <S.ToolbarButtonImageWrapper
                      style={{
                        backgroundColor:
                          this.props.toolBar === data.key &&
                          SECONDARY_PURPLE_COLOR
                      }}
                    >
                      <S.ToolbarButtonImage
                        src={
                          this.props.toolBar === data.key
                            ? data.url_active
                            : data.url
                        }
                      />
                    </S.ToolbarButtonImageWrapper>
                    <S.ToolbarButtonTitle
                      style={{
                        fontWeight: this.props.toolBar === data.key && 700
                      }}
                    >
                      {data.label}
                    </S.ToolbarButtonTitle>
                  </S.ToolbarButton>
                );
              })}
            </S.SubToolbarWrapper>
            {toolbar &&
              !this.props.state.isCabinetDrawing &&
              categories.filter(
                category => category.toolbar === this.props.toolBar
              ).length !== 0 &&
              categories.filter(
                category => category.toolbar === this.props.toolBar
              )[0].content}
          </S.ToolbarWrapper>
        </div>
        {/* {sorter.sort(sortButtonsCb).map(mapButtonsCb)} */}
        {panel}
        {hintText}
        {controlView}
        <canvas
          id="canvas_crop_image"
          style={{ zIndex: -1, position: 'fixed', top: '0px', left: '0px' }}
          onMouseDown={e => this.onMouseDown(e)}
          onMouseMove={e => this.onMouseMove(e)}
          onMouseUp={e => this.onMouseUp(e)}
        >
          {' '}
          The SCREENSHOT is not available in this browser!!!
        </canvas>
        {/* <div style={{ zIndex: 4, position: 'absolute', left: '16px' }} onClick={(event) => {
          this.props.toggleDoorStyle(!this.props.doorStyleOpen);
        }}>
          <img style={{ boxShadow: '3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)' }} width={75} src={ this.state.currentTexture && this.state.currentTexture.thumbnail}/>
        </div> */}
        <SetDoorStyleOptionPopup
          content={'Do you want to change current cabinet or change ALL?'}
          handle={value => this.setDoorStyleOption(value)}
          visible={this.state.setDoorStylePopupVisible}
          setVisible={value =>
            this.setState({ setDoorStylePopupVisible: value })
          }
        />
        {/* {item_custom}
        {item_rotation} */}
        {/* {videoModal} */}
        {item_warning}
        <S.WarningItem id="warning_box_2d" style={{ display: 'none' }}>
          <S.SubCategoryItemImage
            maskImage="/assets/img/svg/3d_item_warning_info.svg"
            style={{
              backgroundColor: BG_COLOR_1,
              marginRight: 10,
              height: 30,
              width: 30
            }}
          />
          This item is not available in the current door style. Replace it with
          another one.
        </S.WarningItem>
      </aside>
    );
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array
};

Toolbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};

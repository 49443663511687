import {
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_5
} from '../../constants';
import styled from 'styled-components';

export const Toggle_2D_3D = styled.div`
  width: 50px;
  margin-left: 10px;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;

export const FooterTitle = styled.span`
  color: ${SECONDARY_PURPLE_COLOR};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  user-select: none;
`;

export const Direction_Up_Down = styled.div`
  width: 50px;
  margin-left: 5px;
  border-radius: 5px;
  height: 25px;
  align-items: center;
  display: flex;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;

export const Direction_Left_Right = styled.div`
  width: 50px;
  margin-left: 5px;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  display: flex;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;

export const ArrowIcon = styled.img`
  height: 10px;
  width: 12px;
  margin: auto;
  user-select: none;
`;
export const Rotation_Button = styled.div`
  width: ${props => (props.isVertical ? '48px' : '56px')};
  height: ${props => (props.isVertical ? '48px' : '32px')};
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;
export const Rotation_Icon = styled.img`
  height: ${props => (props.height ? props.height : '22px')};
  width: ${props => (props.width ? props.width : '22px')};
  user-select: none;
`;

export const ZoomButton = styled.div`
  width: 50px;
  margin-left: 10px;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  display: flex;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;

export const ZoomIcon = styled.img`
  height: 10px;
  width: 12px;
  margin: auto;
  user-select: none;
`;

export const ZoomInput = styled.input`
  color: ${SECONDARY_PURPLE_COLOR};
  width: 50px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  height: 50px;
  align-items: center;
  display: flex;
`;

export const Control = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  background-color: #fff;
  color: white;
  border-radius: 20px;
  margin-right: 15px;
  margin-top: 16px;
  right: 260px;
  bottom: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
export const ControlTitle = styled.span`
  margin: 10px;
  font-size: 12px;
`;
export const ControlIcon = styled.div`
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
      0px 6px 10px 0px rgba(156, 154, 154, 0.39),
      0px 1px 18px 0px rgba(156, 154, 154, 0.12);

    border-radius: 50%;
  }

  :hover {
    img {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.39),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
export const Direction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: white;
  border-radius: 32px;
  margin-right: 15px;
  right: 260px;
  bottom: 25px;
`;
export const DirectionIcon = styled.div`
  position: absolute;
  color: #494949;
  :hover {
    color: #2c38de;
  }
`;
export const DirectionSpinIcon = styled.div`
  position: absolute;
  color: #494949;
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
      0px 6px 10px 0px rgba(156, 154, 154, 0.39),
      0px 1px 18px 0px rgba(156, 154, 154, 0.12);
  }
  :hover {
    height: 40px;
    margin-bottom: 5px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.39), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  margin-left: 20px;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :hover {
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
      0px 6px 10px 0px rgba(156, 154, 154, 0.39),
      0px 1px 18px 0px rgba(156, 154, 154, 0.12);
    border-radius: 18px;
  }
`;
export const ToggleIcon = styled.div`
  ${'' /* transition-duration: .3s; */}
  position: absolute;
  top: 0px;
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    border-radius: 50%;
  }
`;
export const ToggleConvertIcon = styled.div`
  margin: 5px;
  transition-duration: 0.3s;
  :hover {
    img {
      font-size: 1.4rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.39),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
    }
  }
`;

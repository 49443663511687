import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import * as Icon from 'react-icons/fa';
import Popup from 'reactjs-popup';
import {
  REQUEST_ASSISTANCE,
  KEYBOARD_BUTTON_CODE,
  MODE,
  NEW_DESIGN,
  SAVE_DESIGN,
  ASK_QUOTE,
  DEFAULT_FONT_FAMILY,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_3,
  SUBMIT_DESIGN,
  BG_COLOR_1
} from '../../constants';
import { FormTextInput } from '../style/export';
import './header.style.css';
import * as PS from './styles';
import SnackBar from '../atoms/Snackbar/index';

const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

const headerBarStyle = {
  position: 'absolute',
  top: 0,
  // fontSize: '12px',
  backgroundColor: 'rgb(250 252 251)',
  borderBottom: '1px solid #e8e8e8',
  display: 'flex',
  justifyContent: 'space-between',
  // backgroundColor: SharedStyle.SECONDARY_COLOR.alt,
  boxShadow:
    '3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  backgroundColor: '#fff',
  padding: '3px 1em',
  textAlign: 'center',
  border: 0,
  margin: 0,
  boxSizing: 'border-box',
  cursor: 'default',
  userSelect: 'none',
  zIndex: '199'
};
const inputStyle = { textAlign: 'left', height: '32px', border: 'unset' };
const uploadFiles = (data, filePath) => {
  var p1 = new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filePath,
      Body: data,
      ContentType: 'application/json',
      ACL: 'public-read'
    };
    s3.putObject(params, function (err, data) {
      if (err) {
        reject(err);
        return;
      }
      console.log('success');
      resolve([data.Location]);
    });
  });
  return p1;
};
export default class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      HamburgerMenuOpen: false,
      renameForm: false,
      renameTitle: '  ',
      saveAsBtnClicked: false,
      isSnackBarOpen: false,
      snackBarMessage: ''
    };
  }

  canSubmit() {
    return this.state.renameTitle !== '';
  }

  renameFormToggle(data, saveAsBtnClicked = false) {
    if (this.canSubmit()) {
      this.setState({
        renameForm: data,
        saveAsBtnClicked
      });
    } else {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: 'This field is required'
      });
    }
  }

  onRenameTitleChange(ev) {
    this.setState({ renameTitle: ev.target.value });
    sessionStorage.setItem('projectTitle', ev.target.value);
    this.context.projectActions.rename(ev.target.value);
  }

  renameTitleSubmit(ev) {
    ev.preventDefault();
    if (!this.canSubmit()) {
      this.renameFormToggle(false);
      return;
    }
    this.setState({
      renameForm: false,
      renameTitle: this.state.renameTitle
    });
  }
  handlePopupClose(e) {
    const { HamburgerMenuOpen } = this.state;
    this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
  }

  render() {
    let {
      props: {
        state,
        width,
        height,
        saveProject: _saveProject,
        newProject,
        handleSaveDesign,
        handleSubmitForQuote,
        handleDesignerAssist,
        handleNewProject,
        isModalVisible
      },
      context: { projectActions, userActions, translator }
    } = this;

    const { HamburgerMenuOpen, renameTitle, renameForm, saveAsBtnClicked } =
      this.state;
    let userInfo = [];
    if (this.props.match) userInfo = this.props.match.params;
    // let projectTitle = sessionStorage.getItem('projectTitle');
    let projectTitle = state.scene.title;
    let projectId = sessionStorage.getItem('projectId');

    if (projectTitle == null || projectTitle == '') {
      sessionStorage.setItem('projectTitle', 'Untitled');
      this.context.projectActions.rename('Untitled');
      projectTitle = 'Untitled';
    }

    let handleSaveBtn = () => {
      if (
        !sessionStorage.getItem('projectId') || // If it's a new project, Show save dialog
        !sessionStorage.getItem('email') ||
        sessionStorage.getItem('email') === 'temp_xxx@yyy.com' ||
        !sessionStorage.getItem('firstName') ||
        sessionStorage.getItem('firstName') === 'null' ||
        !sessionStorage.getItem('firstName') === 'undefined' ||
        !sessionStorage.getItem('lastName') ||
        sessionStorage.getItem('lastName') === 'null' ||
        !sessionStorage.getItem('lastName') === 'undefined' ||
        !sessionStorage.getItem('phone') ||
        sessionStorage.getItem('phone') === 'null' ||
        !sessionStorage.getItem('phone') === 'undefined'
      ) {
        handleSaveDesign(true);
      } else {
        // It's a saved project, Save the changed project
        this.props.saveProject({
          projectName: state.scene.title,
          projectId: sessionStorage.getItem('projectId'),
          email: sessionStorage.getItem('email'),
          firstName: sessionStorage.getItem('firstName'),
          lastName: sessionStorage.getItem('lastName'),
          phone: sessionStorage.getItem('phone')
        });
      }
    };

    let newProjectBtn = () => {
      if (sessionStorage.getItem('asking')) newProject();
      else handleNewProject(true);
    };

    let saveProject = e => {
      e.preventDefault();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
      _saveProject();
    };

    let saveAsBtnClickEvent = e => {
      e.preventDefault();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
      projectActions.unselectAll();
      this.renameFormToggle(true, true);
    };

    let snaplibrary = e => {
      e.preventDefault();
      projectActions.openCatalog();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
    };

    let objectProperty = '';

    if (HamburgerMenuOpen) {
      objectProperty = (
        <PS.SelectedWrapper>
          <PS.TitleBar>
            <PS.TitleBarIconButton
              onClick={e => {
                this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
              }}
            >
              <Icon.FaTimes />
            </PS.TitleBarIconButton>
          </PS.TitleBar>
          <PS.ContentWrapper>
            <PS.SubTitle
              onClick={e => {
                this.props.history.push('/login');
                userInfo.role !== undefined && userActions.logout();
              }}
            >
              {userInfo.role === undefined ? 'Sign in' : 'Sign out'}
            </PS.SubTitle>
            <PS.SubTitle
              onClick={e => {
                this.props.history.push({
                  pathname: '/register',
                  state: { type: 'register' }
                });
              }}
            >
              {'Register'}
            </PS.SubTitle>
            {userInfo.role === 'admin' ||
              (userInfo.role === 'superadmin' && (
                <PS.SubTitle
                  onClick={e => {
                    this.props.history.push({
                      pathname: '/register',
                      state: { type: 'adduser' }
                    });
                  }}
                >
                  Add user
                </PS.SubTitle>
              ))}
            <PS.Diver></PS.Diver>
            <PS.SubTitle
              onClick={event => {
                this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
                confirm('Would you want to start a new Project?')
                  ? (projectActions.newProject(),
                    this.renameFormToggle(false),
                    sessionStorage.removeItem('projectId'),
                    sessionStorage.setItem('projectTitle', 'Untitled'),
                    projectActions.rename('Untitled'))
                  : null;
              }}
            >
              New Project
            </PS.SubTitle>
            <PS.Diver></PS.Diver>
            {userInfo.role === 'designer' ||
            userInfo.role === 'customer' ||
            userInfo.role === 'superdesigner' ||
            userInfo.role === 'superadmin' ? (
              <PS.SubTitle onClick={saveProject}>Save Project</PS.SubTitle>
            ) : (
              <PS.SubTitle
                onClick={event => {
                  this.setState({
                    HamburgerMenuOpen: !HamburgerMenuOpen,
                    isSnackBarOpen: true,
                    snackBarMessage: 'You should Login.'
                  });
                }}
              >
                Save Project
              </PS.SubTitle>
            )}
            <PS.Diver></PS.Diver>
            {userInfo.role === 'designer' ||
            userInfo.role === 'customer' ||
            userInfo.role === 'superdesigner' ||
            userInfo.role === 'superadmin' ? (
              <PS.SubTitle onClick={saveAsBtnClickEvent}>
                Save As...
              </PS.SubTitle>
            ) : (
              <PS.SubTitle
                onClick={event => {
                  this.setState({
                    HamburgerMenuOpen: !HamburgerMenuOpen,
                    isSnackBarOpen: true,
                    snackBarMessage: 'You should Login.'
                  });
                }}
              >
                Save As...
              </PS.SubTitle>
            )}

            <PS.Diver></PS.Diver>
            {userInfo.role === 'designer' ||
            userInfo.role === 'customer' ||
            userInfo.role === 'superdesigner' ||
            userInfo.role === 'superadmin' ? (
              <PS.SubTitle onClick={snaplibrary}>Open Project</PS.SubTitle>
            ) : null}
            {/* <PS.PositionValue>Rotate</PS.PositionValue>
          <PS.PositionValue>Resize</PS.PositionValue> */}
          </PS.ContentWrapper>
        </PS.SelectedWrapper>
      );
    }
    return (
      <div style={{ ...headerBarStyle, width, height }}>
        <SnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() =>
            this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
          }
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PS.LogoBox style={{ padding: 'unset' }}>
            <a href="https://www.thertastore.com" target="_blank">
              <img width="40px" src="/assets/img/rta/rta_logo_box_blue.jpg" />
            </a>
          </PS.LogoBox>
          <Popup
            trigger={objectProperty}
            open={this.state.HamburgerMenuOpen}
            arrow={false}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: DEFAULT_FONT_FAMILY,
              fontSize: 13,
              fontWeight: 600,
              lineHeight: '18px',
              textAlign: 'center'
            }}
          >
            <p
              style={{
                color: TEXT_COLOR_NEUTRAL_3,
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '22px',
                textAlign: 'center'
              }}
            >
              Drafts
            </p>
            <p style={{ color: TEXT_COLOR_NEUTRAL_0, margin: '0px 3px' }}>/</p>
            {renameForm ? (
              <div className="header-projectname-wrapper">
                <PS.InputStyle>
                  <FormTextInput
                    value={projectTitle ? projectTitle : renameTitle}
                    onChange={e => {
                      this.onRenameTitleChange(e);
                    }}
                    style={inputStyle}
                    onKeyDown={e => {
                      var keyCode = e.keyCode || e.which;
                      if (keyCode == KEYBOARD_BUTTON_CODE.ENTER) {
                        if (saveAsBtnClicked) {
                          saveProject(e);
                        }
                        this.renameFormToggle(false, false);
                      }
                    }}
                  />
                </PS.InputStyle>
              </div>
            ) : (
              <div className="header-projectname-wrapper">
                <p
                  style={{ color: TEXT_COLOR_NEUTRAL_0 }}
                  onClick={
                    isModalVisible
                      ? null
                      : () => this.renameFormToggle(true, saveAsBtnClicked)
                  }
                >
                  {projectTitle ? projectTitle : renameTitle}
                </p>
                {/* <PS.EditBox >
                      <IconButton className='padding-5'><EditOutlined /></IconButton>
                    </PS.EditBox> */}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!this.props.wizardStepOpend && (
            <PS.SelectedBox onClick={isModalVisible ? null : newProjectBtn}>
              <img height="18px" src="/assets/img/svg/headerbar/plus.svg" />
              <PS.SelectTitle style={{ marginRight: 10 }}>
                {NEW_DESIGN}
              </PS.SelectTitle>
            </PS.SelectedBox>
          )}
          {!this.props.wizardStepOpend && (
            <PS.SelectedBox
              onClick={
                isModalVisible
                  ? null
                  : () => {
                      if (
                        userInfo.pid === undefined ||
                        (sessionStorage.getItem('email') ===
                          'temp_xxx@yyy.com' &&
                          sessionStorage.getItem('firstName') === 'temp_xxx' &&
                          sessionStorage.getItem('lastName') === 'temp_yyy')
                      )
                        this.props.handleSubmitPrompt();
                      else handleSubmitForQuote(true);
                    }
              }
            >
              <img
                height="18px"
                src="\assets\img\svg\lefttoolbar\reviewforquote.svg"
              />
              <PS.SelectTitle>{SUBMIT_DESIGN}</PS.SelectTitle>
            </PS.SelectedBox>
          )}
          {/* {!this.props.wizardStepOpend && <PS.SelectedBox onClick={isModalVisible ? null : () => handleDesignerAssist(true)}>
            <img style={{marginRight: 10, height: 16}} src='\assets\img\svg\lefttoolbar\disigner_assistance.svg' />
            <PS.SelectTitle>
              Design Assistance
            </PS.SelectTitle>
          </PS.SelectedBox>} */}
          {/* {!this.props.wizardStepOpend && <PS.SelectedBox onClick={isModalVisible ? null : () => {projectActions.setIsHelp(!state.isHelp);}}>
            <img height="18px" src="/assets/img/svg/bottombar/help.svg" />
            <PS.SelectTitle>
              Help
            </PS.SelectTitle>
          </PS.SelectedBox>} */}
          {!this.props.wizardStepOpend && (
            <PS.SaveBox onClick={isModalVisible ? null : handleSaveBtn}>
              <img
                style={{
                  height: 18,
                  width: 18,
                  WebkitMaskImage: 'url(/assets/img/svg/headerbar/check.svg)',
                  backgroundColor: BG_COLOR_1,
                  WebkitMaskSize: '100%',
                  WebkitMaskPosition: 'center'
                }}
              />
              <PS.SelectTitle style={{ color: BG_COLOR_1 }}>
                {SAVE_DESIGN}
              </PS.SelectTitle>
            </PS.SaveBox>
          )}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

Header.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import convert from 'convert-units';
import {
  BG_COLOR_1,
  DEFAULT_FONT_FAMILY,
  STATUS_NEGATIVE_COLOR,
  STATUS_POSITIVE_COLOR,
  STATUS_WARNING_COLOR,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_2
} from '../../../../constants';
import * as S from '../../main/styles';
import { returnReplaceableDeepSearchType } from '../../../../components/viewer2d/utils';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: TEXT_COLOR_NEUTRAL_0,
    color: BG_COLOR_1,
    maxWidth: 180,
    fontSize: 9,
    fontFamily: DEFAULT_FONT_FAMILY,
    boxShadow:
      '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)',
    padding: '2px 15px'
  }
}))(Tooltip);

export default function ReviewItem({
  catalog,
  itemSKU,
  suffix,
  categoryData,
  products,
  item,
  count,
  measurementUnit
}) {
  let review;
  let doorColor = item.doorStyle;
  if (doorColor.size) {
    doorColor = doorColor.toJS();
  }
  let doorStyleName = doorColor?.name;
  if (catalog !== undefined) {
    if (!doorColor?.install) {
      categoryData.data.doorStyles.items.forEach(category => {
        category.items.forEach(element => {
          if (element.items.filter(it => it.id === doorColor?.id)) {
            doorColor = { ...doorColor, install: category.name };
          }
        });
      });
    }
    const installation = categoryData.data.doorStyles.items.filter(
      it => it.name === doorColor?.install
    )[0];
    const doorCategory = installation.items.filter(
      item => (item.name = doorColor.door_style_name)
    )[0];
    review = catalog.elements[item.type];
    if (!review)
      review = catalog.elements[returnReplaceableDeepSearchType(item.type)];
    let product = products[itemSKU];
    if (suffix.includes('-assemble')) {
      if (!itemSKU.includes('Assembled')) {
        itemSKU += '-Assembled';
      }
    } else {
      itemSKU += '';
    }

    return (
      <div
        style={{
          height: 120,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <S.ReviewQuoteItem
          style={{ justifyContent: 'unset', width: 300, marginLeft: 15 }}
        >
          <img style={{ width: 99 }} src={review.img} />
          <S.SubCategoryItemLabel style={{ textAlign: 'left', fontSize: 12 }}>
            SKU: {itemSKU} <br />
            Width:{' '}
            {Math.round(
              convert(review.info.sizeinfo.width)
                .from('in')
                .to(measurementUnit._unit) * 100
            ) / 100}
            {measurementUnit._unit !== 'in' ? measurementUnit._unit : '"' + ' '}
            Depth:{' '}
            {Math.round(
              convert(review.info.sizeinfo.depth)
                .from('in')
                .to(measurementUnit._unit) * 100
            ) / 100}
            {measurementUnit._unit !== 'in' ? measurementUnit._unit : '"' + ' '}
            Height:{' '}
            {Math.round(
              convert(review.info.sizeinfo.height)
                .from('in')
                .to(measurementUnit._unit) * 100
            ) / 100}
            {measurementUnit._unit !== 'in' ? measurementUnit._unit : '"'}
            {/* <br/>
            <span>
              { doorStyleName && (
                <>Door Style: {doorStyleName}<br/></>
              )}
              { suffix && (
                <>Installation: {suffix}</>
              )}
            </span> */}
          </S.SubCategoryItemLabel>
        </S.ReviewQuoteItem>
        <S.ReviewQuoteItem style={{ width: 80 }}>
          <StyledTooltip
            style={{ backgroundColor: TEXT_COLOR_NEUTRAL_0, fontSize: 18 }}
            title={
              <span>
                Door Style: <strong>{doorStyleName}</strong>
              </span>
            }
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly'
              }}
            >
              <img
                style={{ width: 30, height: 30, padding: 3 }}
                src={installation?.thumbnail}
              />
              <img
                style={{ width: 30, height: 30 }}
                src={doorCategory?.thumbnail}
              />
            </div>
          </StyledTooltip>
        </S.ReviewQuoteItem>
        <S.ReviewQuoteItem style={{ width: 50 }}>
          <S.SubCategoryItemLabel style={{ margin: 'unset', fontSize: 14 }}>
            {count || 1}
          </S.SubCategoryItemLabel>
        </S.ReviewQuoteItem>
        <S.ReviewQuoteItem style={{ width: 100 }}>
          <S.SubCategoryItemLabel
            style={{
              margin: 'unset',
              color: TEXT_COLOR_NEUTRAL_2,
              fontSize: 14
            }}
          >
            {product && product.regular_price
              ? `$${product && product.regular_price}`
              : '$0.00'}
          </S.SubCategoryItemLabel>
        </S.ReviewQuoteItem>
        <S.ReviewQuoteItem style={{ width: 100 }}>
          <S.DistcountedPriceWrapper
            colorInfo={
              product && product.regular_price !== product.discounted_price
                ? 2
                : product && product.regular_price
                ? 1
                : 0
            }
          >
            <S.SubCategoryItemLabel
              style={{
                margin: 'unset',
                fontSize: 14,
                color:
                  product && product.regular_price !== product.discounted_price
                    ? STATUS_POSITIVE_COLOR
                    : product && product.regular_price
                    ? STATUS_WARNING_COLOR
                    : STATUS_NEGATIVE_COLOR
              }}
            >
              {product && product.discounted_price
                ? `$${product && product.discounted_price}`
                : '$0.00'}
            </S.SubCategoryItemLabel>
            {product &&
              product.regular_price !== 0 &&
              product.discounted_price === product.regular_price && (
                <StyledTooltip
                  style={{ backgroundColor: TEXT_COLOR_NEUTRAL_0 }}
                  title="Please contact us for Sale price"
                >
                  <img
                    style={{ height: 12, marginLeft: 7 }}
                    src="\assets\img\svg\lefttoolbar\warning_icon.svg"
                  />
                </StyledTooltip>
              )}
            {(!!!product ||
              (product &&
                product.regular_price === 0 &&
                product.regular_price === product.discounted_price)) && (
              <StyledTooltip title="This item is not available in the finish you have selected. Please contact us for alternate options in the finish you want.">
                <img
                  style={{ height: 12, marginLeft: 7 }}
                  src="\assets\img\svg\lefttoolbar\error_icon.svg"
                />
              </StyledTooltip>
            )}
          </S.DistcountedPriceWrapper>
        </S.ReviewQuoteItem>
        <S.ReviewQuoteItem style={{ width: 80, marginRight: 13 }}>
          <S.SubCategoryItemLabel
            style={{
              fontSize: 14,
              margin: 'unset',
              color:
                product &&
                product.discounted_price === 0 &&
                STATUS_NEGATIVE_COLOR
            }}
          >
            {product && product.discounted_price
              ? `$${
                  product &&
                  Number(product.discounted_price * (count || 1)).toFixed(3)
                }`
              : '$0.00'}
          </S.SubCategoryItemLabel>
        </S.ReviewQuoteItem>
      </div>
    );
  } else {
    null;
  }
}
